import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import GroupIcon from '@material-ui/icons/Group'

import { openDeleteModal } from './../../common/actions'

import { deleteClass } from '../actions'
import { colors } from './../../consts'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class DivisionsTable extends React.Component {
  render() {
    const { classes, divisions, deleteClass } = this.props
    return (
      <Paper elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ textAlign: 'center', width: '50%' }}
                className="table-header"
              >
                Name
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '25%' }}
                className="table-header"
              >
                Attendees
              </TableCell>
              <TableCell
                style={{ textAlign: 'center', width: '25%' }}
                className="table-header"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {divisions.map(division => (
              <TableRow key={division.ID} style={{ cursor: 'pointer' }}>
                <TableCell
                  style={{ textAlign: 'center', width: '25% !important' }}
                >
                  {division.name}
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center', width: '20% !important' }}
                >
                  {division?.attendees?.length}
                </TableCell>
                <TableCell style={{ textAlign: 'center', width: '20%' }}>
                  <Link href={`/classes/${division.ID}`} target="_blank">
                    <IconButton className={classes.root}>
                      <GroupIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Link>
                  {!division?.attendees?.length && (
                    <IconButton
                      className={classes.root}
                      onClick={() => deleteClass(division.ID, false)}
                    >
                      <DeleteIcon
                        color="secondary"
                        fontSize="small"
                        style={{ color: colors.red_strong }}
                      />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const filters = state.classes.filters,
    query = filters.query && filters.query.trim().toLowerCase()
  return {
    divisions:
      state.classes.classInfo?.divisions.filter(c => {
        if (query) return c.name.toLowerCase().indexOf(query) !== -1
        return true
      }) || [],
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  gotoTeam: id => dispatch(push('/team/' + id)),
  deleteClass: id =>
    dispatch(openDeleteModal('class', () => deleteClass(id, true))),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DivisionsTable))

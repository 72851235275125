import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import ClassSummary from './ClassSummary'
import NewDivisionDialog from './forms/NewDivisionDialog'
import DivisionTable from './DivisionsTable'
import { TextField } from '@material-ui/core'
import { actions } from 'classes/redux'

class DivisionList extends React.Component {
  render() {
    const { openAddDivisionModal } = this.props
    return (
      <div>
        <NewDivisionDialog />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={8} style={{ textAlign: 'center', width: '100%' }}>
            <Grid item xs={12} style={{ width: '100%', padding: 15 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Filter classes..."
                value={this.props.query}
                alignItems="flex-start"
                onChange={e =>
                  this.props.changeFilterDetails('query', e.target.value)
                }
              />
            </Grid>
            <DivisionTable />
            <Button
              style={{ margin: 10, textAlign: 'right' }}
              onClick={openAddDivisionModal}
              className="save-btn"
            >
              Add Division
            </Button>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center', width: '100%' }}>
            <ClassSummary hide_broadcast />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const filters = state.classes.filters,
    query = filters.query && filters.query.trim().toLowerCase()
  return {
    classes: state.classes.classes.filter(c => {
      if (query) return c.name.toLowerCase().indexOf(query) !== -1
      return true
    }),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  openAddDivisionModal: () =>
    dispatch(actions.updateNewDialog({ key: 'open', value: true })),
  changeFilterDetails: (k, v) =>
    dispatch(actions.changeFilterDetails({ key: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(DivisionList)

import moment from 'moment'

import { unixToDateTime } from './../helper'

export const buildInvoiceData = invoice_data => {
  return invoice_data.map(invoice => {
    const parsed_date = moment(invoice.CreatedAt),
      year = parseInt(parsed_date.format('YY'))
    return {
      invoice_id: invoice.ID,
      invoiced: invoice.invoiced - invoice.discounted,
      paid: invoice.paid,
      balance: invoice.balance,
      day: parsed_date.format('MM/DD/YYYY'),
      product: invoice.product,
      gl: invoice.gl,
      year: `20${year}-${year + 1}`,
      customer: `${invoice.customer.first_name} ${invoice.customer.last_name}`,
      email: invoice.customer.email,
      location: invoice.facility,
    }
  })
}

export const buildPaymentData = payment_data => {
  return payment_data.map(payment => {
    const parsed_date = moment(payment.CreatedAt).subtract(5, 'h'),
      _year = parseInt(parsed_date.format('YY')),
      year = `20${_year}-${_year + 1}`,
      date = parsed_date.format('MM/DD/YYYY'),
      hour = parsed_date.format('HH:mm:ss'),
      gross_sale = payment.amount > 0 ? payment.amount : 0,
      refund = payment.amount > 0 ? 0 : -payment.amount,
      net_sale = gross_sale - refund,
      tax = gross_sale * payment.facility.tax_rate,
      total_cashed_in = net_sale - tax,
      payment_type = payment.type,
      cash = payment.type === 'cash' ? total_cashed_in : 0,
      card = payment.type === 'card' ? total_cashed_in : 0,
      check = payment.type === 'check' ? total_cashed_in : 0,
      wire = payment.type === 'wire' ? total_cashed_in : 0,
      payment_id = payment.ID,
      invoice_id = payment.invoice_id,
      product = payment.product_type.name,
      gl = payment.product_type.gl,
      facility = payment.facility.name,
      customer = `${payment.customer.first_name} ${payment.customer.last_name}`,
      email = payment.customer.email

    let square_id = ""
    try {
      const d = JSON.parse(payment.details)
      if (d.square_id) square_id = d.square_id
      if (d.square_transaction_id) square_id = d.square_transaction_id
    } catch (error) {}

    return {
      year,
      date,
      hour,
      gross_sale,
      refund,
      net_sale,
      tax,
      total_cashed_in,
      payment_type,
      cash,
      card,
      check,
      wire,
      payment_id,
      invoice_id,
      product,
      gl,
      facility,
      customer,
      email,
      square_id,
    }
  })
}

export const buildEventData = event_data => {
  return event_data.map(event => {
    const parsed_date = unixToDateTime(event.start_date)
    return {
      id: event.ID,
      day: parsed_date.format('MM/DD/YYYY'),
      start_time: parsed_date.format('h:mmA'),
      end_time: unixToDateTime(event.end_date).format('h:mmA'),
      duration: (event.end_date - event.start_date) / 60,
      yields:
        event.invoice.amount / ((event.end_date - event.start_date) / 3600),
      event_type: event.product_type.name,
      facility: event.field.facility.name,
      resource: `${event.field.number} - ${event.field.name}`,
      resource_type: `${event.field.exterior ? 'Outdoor' : 'Indoor'} ${
        event.field.field_type
      }`,
      customer: `${event.customer.first_name} ${event.customer.last_name}`,
      email: event.customer.email,
      amount: event.invoice.amount,
      paid: event.invoice.paid,
      balance: event.invoice.paid,
    }
  })
}

export const buildHealthData = health_data => {
  return health_data.map(health_check => {
    const parsed_date = moment(health_check.date)
    return {
      day: parsed_date.format('MM/DD/YYYY'),
      time: parsed_date.format('h:mm A'),
      first_name: health_check.first_name,
      last_name: health_check.last_name,
    }
  })
}

import { ERP_BACKEND_URL } from './../consts'
import axios from 'axios'
import moment from 'moment'
import { changeLoading, dispatchError } from './../common/actions'
import { actions } from './redux'
import { actions as commonActions } from 'common/redux'

const _ = require('lodash')

export const searchCustomers = q => (dispatch, getState) => {
  dispatch(changeLoading(true))
  let query = getState().customer.search_query,
    { facility_id, user_type } = getState().user
  if (q) {
    query = q
  }
  if (user_type !== 'admin') {
    query = query + '?facility_id=' + facility_id
  }
  return axios
    .get(ERP_BACKEND_URL + `/customer/search/` + query)
    .then(resp => {
      dispatch(
        actions.seatchCustomer({
          customers: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getCustomerEPlayer = customer_id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/eplayer/cust/` + customer_id)
    .then(resp => {
      dispatch(
        actions.getEPlayerDetails({
          eplayer: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(console.log)
}

const getCustomerFamily = customer_id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/family/` + customer_id)
    .then(resp => {
      dispatch(
        actions.getFamily({
          family: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getCustomerContracts = customer_id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/contract/cust/` + customer_id)
    .then(resp => {
      dispatch(
        actions.getContracts({
          contracts: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getCustomerInvoices = customer_id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/invoice/customer/${customer_id}`)
    .then(resp => {
      dispatch(
        actions.getInvoices({
          invoices: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getCustomerTrials = customer_id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/class/trial/customer/${customer_id}`)
    .then(resp => {
      dispatch(
        actions.getTrials({
          trials: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const _getCustomer = customer_id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/customer/` + customer_id)
    .then(resp => {
      dispatch(
        actions.getCustomer({
          customer: resp.data,
        })
      )
      dispatch(getCustomerEPlayer(customer_id))
      dispatch(getCustomerFamily(customer_id))
      dispatch(getCustomerInvoices(customer_id))
      dispatch(getCustomerTrials(customer_id))
      dispatch(getCustomerContracts(customer_id))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getCustomer = customer_id => (dispatch, getState) => {
  dispatch(_getCustomer(customer_id))
}

export const updateSearchQuery = search_query => (dispatch, getState) => {
  dispatch(
    actions.editSearchQuery({
      search_query,
    })
  )
}

export const updateCustomerDetails =
  (key, val, hubspot) => (dispatch, getState) => {
    dispatch(
      actions.updateCustomerDetails({
        key,
        val,
        hubspot,
      })
    )
  }

export const saveCustomer = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  let customer = _.cloneDeep(getState().customer.customer),
    dob_moment = moment.utc(customer.hubspot.dob, 'MM/DD/YYYY')
  if (!dob_moment.isValid()) {
    delete customer.hubspot['dob']
  } else {
    customer.hubspot.dob = dob_moment.format('x')
  }
  customer.facility_id = parseInt(customer.facility_id)
  delete customer.facility
  return axios
    .put(ERP_BACKEND_URL + `/customer/` + customer.ID, customer)
    .then(resp => {
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const changeTab = tab_name => (dispatch, getState) => {
  dispatch(
    actions.changeTab({
      tab: tab_name,
    })
  )
}

export const selectFamilyMember = member => (dispatch, getState) => {
  dispatch(
    actions.selectFamilyMember({
      member,
    })
  )
}

export const addFamilyMember = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const customer = getState().customer.customer,
    family_member = getState().customer.family_member
  let parent_id = 0,
    child_id = 0
  if (customer.is_kid) {
    child_id = customer.ID
    parent_id = family_member.ID
  } else {
    parent_id = customer.ID
    child_id = family_member.ID
  }
  return axios
    .post(ERP_BACKEND_URL + `/family`, {
      parent_id,
      child_id,
    })
    .then(resp => {
      dispatch(getCustomerFamily(customer.ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const removeFamilyMember = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const customer = getState().customer.customer
  return axios
    .delete(ERP_BACKEND_URL + `/family/` + id.toString())
    .then(resp => {
      dispatch(getCustomerFamily(customer.ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const toggleWaiver = on => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const customer = getState().customer.customer
  return axios
    .put(ERP_BACKEND_URL + `/customer/` + customer.ID, {
      email: customer.email,
      hubspot: {
        waiver_signed: on ? '1' : '',
      },
    })
    .then(resp => {
      dispatch(
        actions.toggleWaiver({
          on,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const updateCustomer = (key, value) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const customer = getState().customer.customer
  return axios
    .put(ERP_BACKEND_URL + `/customer/details/` + customer.ID, {
      [key]: value,
    })
    .then(resp => {
      dispatch(
        actions.updateCustomerDetails({
          key,
          val: value,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const updateMembership = (key, value) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const membership = getState().customer.membership_dialog.membership
  console.log(membership)
  return axios
    .put(ERP_BACKEND_URL + `/membership/` + membership.ID, membership)
    .then(resp => {
      dispatch(
        actions.updateCustomerDetails({
          key,
          val: value,
        })
      )
      dispatch(changeLoading(false))
      dispatch(actions.updateMembershipDialog({ key: 'open', value: false }))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const createOrUpdateCustomer = return_id => (dispatch, getState) => {
  const { facility_id } = getState().user,
    customer_details = getState().customer.creation
  let customer_body = {
    first_name: customer_details.first_name,
    last_name: customer_details.last_name,
    email: customer_details.email,
    facility_id,
    customer_type_id: 1,
  }
  if (customer_details.phone) {
    customer_body.hubspot = {
      phone: customer_details.phone,
    }
  }
  return axios
    .post(ERP_BACKEND_URL + `/customer`, customer_body)
    .then(resp => {
      if (return_id) return Promise.resolve(resp.data.ID)
      window.location = `/customer/${resp.data.ID}`
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const bulkCreateCustomers =
  (facility_id, customers) => (dispatch, getState) => {
    dispatch(changeLoading(true))
    let p = []
    customers.forEach(customer => {
      let body = { ...customer, facility_id, customer_type_id: 1 }
      if (customer.phone) body.hubspot = { phone: customer.phone }
      p.push(axios.post(ERP_BACKEND_URL + `/customer`, body))
    })
    return Promise.all(p)
      .then(resp => {
        dispatch(
          commonActions.openInfoModal({
            message: 'Customers imported successfully!',
          })
        )
        dispatch(changeLoading(false))
        dispatch(actions.toggleCustomerCreationModal({ open: false }))
      })
      .catch(e => dispatch(dispatchError(e)))
  }

export const createOrUpdateCustomerReturn = customer_body =>
  axios
    .post(ERP_BACKEND_URL + `/customer`, customer_body)
    .then(resp => resp.data.ID)
    .catch(e => e)

export const updateCustomerCredits = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const customer = getState().customer.customer,
    amount = getState().customer.add_credit_amount,
    new_credits = parseFloat(customer.credits) + parseFloat(amount)
  return axios
    .put(ERP_BACKEND_URL + `/customer/${customer.ID}`, {
      credits: new_credits,
    })
    .then(resp => {
      dispatch(actions.updateCustomerCredits({ credits: new_credits }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const searchFacilityCustomers =
  (query, facility_id) => (dispatch, getState) => {
    if (query.trim() === '') return
    dispatch(changeLoading(true))
    const query_with_facility = `${query}?facility=${facility_id}`
    return axios
      .get(ERP_BACKEND_URL + `/customer/search/` + query_with_facility)
      .then(resp => {
        dispatch(
          actions.seatchCustomer({
            customers: resp.data,
          })
        )
        dispatch(changeLoading(false))
      })
      .catch(e => dispatch(dispatchError(e)))
  }

const validateEmail = email => {
  const re = /^\S+@\S+$/
  return re.test(String(email).toLowerCase())
}

export const updateCustomerEmail = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { email } = getState().customer.creation,
    { ID } = getState().customer.customer
  if (email.trim() === '')
    return dispatch(dispatchError({ message: 'Invalid email' }))
  if (!validateEmail(email)) {
    return dispatch(dispatchError({ message: 'Invalid email' }))
  }
  return axios
    .put(ERP_BACKEND_URL + `/customer/email/${ID}?email=${email}`)
    .then(resp => {
      dispatch(actions.toggleEditEmailModal({ open: false }))
      dispatch(_getCustomer(ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const updateCustomerNote = note => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().customer.customer
  return axios
    .put(ERP_BACKEND_URL + `/customer/note/${ID}`, { note })
    .then(resp => {
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const mergeCustomers = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().customer.customer,
    { customers } = getState().customer.merge
  return axios
    .post(ERP_BACKEND_URL + `/customer/merge/${ID}`, {
      customer_ids: customers.map(c => c.ID),
    })
    .then(resp => {
      dispatch(changeLoading(false))
      dispatch(actions.updateMerge({ key: 'open', value: false }))
      dispatch(commonActions.openInfoModal({ message: 'Customers merged!' }))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getNewProductPricesByProductType =
  (productType, facilityID) => (dispatch, getState) => {
    dispatch(changeLoading(true))
    return axios
      .get(
        ERP_BACKEND_URL +
          `/newproduct/prices/${productType}?facility_id=${facilityID}`
      )
      .then(resp => {
        dispatch(actions.getMembershipPrices(resp.data))
        dispatch(changeLoading(false))
      })
      .catch(e => dispatch(dispatchError(e)))
  }

export const updateCustomerPhoto = file => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().customer.customer
  var formData = new FormData()
  formData.append('file', file)

  return axios
    .post(ERP_BACKEND_URL + `/customer/${ID}/profile-picture`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(resp => {
      dispatch(changeLoading(false))
      dispatch(actions.updateProfilePhoto({ key: 'open', value: false }))
      dispatch(commonActions.openInfoModal({ message: 'Photo updated!' }))
      dispatch(_getCustomer(ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const createSuspension = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const suspension = getState().customer.suspension
  console.log(suspension)
  const { ID } = getState().customer.customer
  return axios
    .post(ERP_BACKEND_URL + `/customer/suspension`, {
      ...suspension,
      customer_id: ID,
    })
    .then(resp => {
      dispatch(changeLoading(false))
      dispatch(actions.updateSuspension({ key: 'open', value: false }))
      dispatch(getCustomer(ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const deleteSuspension = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const customer = getState().customer.customer
  return axios
    .delete(ERP_BACKEND_URL + `/suspension/` + id.toString())
    .then(resp => {
      dispatch(getCustomer(customer.ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

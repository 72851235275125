import React from 'react'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TeamIcon from '@material-ui/icons/PersonPin'
import CalendarIcon from '@material-ui/icons/DateRange'
import SettingsIcon from '@material-ui/icons/Tune'
import StandingsIcon from '@material-ui/icons/AmpStories'

import { changeLeagueTab } from './../actions'

class Details extends React.Component {
  render() {
    const { league_tab, tournament,league } = this.props
    let isContainer = league.tournament_id == 0 
      && league.is_container
    return (
      <Paper square elevation={0}>
        <Tabs
          value={league_tab}
          onChange={(a, b) => this.props.changeLeagueTab(b)}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
          centered
        >
          {isContainer && (
            <Tab icon={<TeamIcon />} label="Age groups" value="teams" />
          )}
          {!isContainer && (
            <Tab icon={<TeamIcon />} label="Teams" value="teams" />
          )}
          {!isContainer && (
            <Tab icon={<CalendarIcon />} label="Schedule" value="schedule" />
          )}
          {!isContainer && !tournament && (
            <Tab icon={<CalendarIcon />} label="Play-offs" value="playoffs" />
          )}
          {!isContainer && (
            <Tab icon={<StandingsIcon />} label="Standings" value="standings" />
          )}
          <Tab icon={<SettingsIcon />} label="Settings" value="settings" />
        </Tabs>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  league_tab: state.league.league_tab,
  tournament: state.league.league.is_tournament,
  league:state.league.league,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeLeagueTab: league_tab => dispatch(changeLeagueTab(league_tab)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Details)

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { BlockPicker } from 'react-color'

import { saveProductType } from 'resources/actions'
import { actions } from 'resources/redux'

const NewProductColorDialog = () => {
  const { open, color } = useSelector(
    state => state.resources.product_type
  ),
  dispatch = useDispatch(),
  updateProductType = (k, v) => dispatch(actions.updateProductType({ key: k, value: v })),
  handleClose = () => updateProductType('open', false),
  _saveProductType = () => dispatch(saveProductType())
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        Update product color
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{margin:'auto'}}>
          <BlockPicker 
                color={color}
                onChangeComplete={e =>
                  updateProductType('color', e.hex)
                }
              />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => _saveProductType()} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewProductColorDialog

import React from 'react'
import { connect } from 'react-redux'

import moment from 'moment'
import memoize from 'memoize-one'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import DataTable from 'react-data-table-component'
import IconButton from '@material-ui/core/IconButton'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import DeleteIcon from '@material-ui/icons/Delete'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import Tooltip from '@material-ui/core/Tooltip'

import { deleteWaitlist, convertWaitlistToAttendee } from '../actions'
import { openDeleteModal, openConfirmModal } from 'common/actions'
import { colors } from 'consts'
import { getHubspot } from 'helper'

const sortIcon = <ArrowDownward />

const columns = memoize((deleteWaitList, convertWaitlistToAttendee) => [
  {
    name: 'Firstname',
    selector: 'Firstname',
    center: true,
    grow: 2,
    cell: row => (
      <div style={{ textAlign: 'center' }}>{row.customer.first_name}</div>
    ),
  },
  {
    name: 'Lastname',
    selector: 'Lastname',
    center: true,
    grow: 2,
    cell: row => (
      <div style={{ textAlign: 'center' }}>{row.customer.last_name}</div>
    ),
  },
  {
    name: 'Uniform Size',
    selector: 'Uniformsize',
    center: true,
    grow: 2,
    cell: row => <div style={{ textAlign: 'center' }}>{row.uniform_size}</div>,
  },
  {
    name: 'Contact',
    selector: 'Contact',
    center: true,
    grow: 5,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        <img
          src="https://ps.w.org/leadin/assets/icon-256x256.png?rev=2785153"
          onClick={() => getHubspot(row.parent?.ID || row.customer?.ID)}
          width="15"
          style={{ marginLeft: 10, cursor: 'pointer' }}
        />
      </div>
    ),
  },
  {
    name: 'Membership exp',
    selector: 'MembershipExp',
    center: true,
    grow: 3,
    cell: row => (
      <div>
        {row.parent?.member?.memberships.length > 0
          ? moment
              .unix(row.parent?.member?.memberships[0].valid_until)
              .format('M/D/YYYY')
          : 'no membership'}
      </div>
    ),
  },
  {
    name: 'Action',
    selector: 'Action',
    center: true,
    grow: 2,
    cell: row => (
      <div style={{ textAlign: 'center' }}>
        {(!row.invoice || row.invoice.paid === 0) && (
          <IconButton onClick={() => deleteWaitList(row.ID)}>
            <DeleteIcon fontSize="small" style={{ color: colors.red_strong }} />
          </IconButton>
        )}
        <Tooltip title="Convert to attendee">
          <IconButton
            style={{ padding: 5 }}
            onClick={() => convertWaitlistToAttendee(row.ID)}
          >
            <ImportExportIcon
              fontSize="small"
              style={{
                color: colors.yellow_strong,
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
    ),
  },
])

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

const WaitlistTable = ({
  waitlists,
  deleteWaitList,
  convertWaitlistToAttendee,
}) => (
  <>
    <Paper elevation={0}>
      <DataTable
        columns={columns(deleteWaitList, convertWaitlistToAttendee)}
        data={waitlists}
        highlightOnHover
        defaultSortField="date"
        defaultSortAsc={false}
        sortIcon={sortIcon}
        pagination
        striped
        fixedHeader
        paginationPerPage={50}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Paper>
  </>
)

const mapStateToProps = (state, ownProps) => ({
  waitlists: state.classes.classInfo.waitlists,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteWaitList: id =>
    dispatch(openDeleteModal('waitlist', () => deleteWaitlist(id))),
  convertWaitlistToAttendee: id => {
    dispatch(
      openConfirmModal('Waitlist', 'Convert customer to attendee', () =>
        convertWaitlistToAttendee(id)
      )
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WaitlistTable))

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { jsDateToUnix, unixToJsDate } from 'helper'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'

import { actions } from '../redux'
import { createSuspension } from '../actions'

const SuspensionDialog = () => {
  const dispatch = useDispatch(),
    { open, foul, notes, from, until } = useSelector(
      state => state.customer.suspension
    )

  function onClose() {
    dispatch(actions.updateSuspension({ key: 'open', value: false }))
  }
  function onSubmit() {
    dispatch(createSuspension())
  }

  function updateForm(key, value) {
    dispatch(actions.updateSuspension({ key, value }))
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      disableBackdropClick={true}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Suspend player
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{ textAlign: 'center', paddingTop: 20 }}
          spacing={4}
        >
          <Grid item xs={6}>
            From
            <DatePickerComponent
              id="suspension_from"
              placeholder="From"
              value={unixToJsDate(from)}
              onChange={e => updateForm('from', jsDateToUnix(e.value))}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            Until
            <DatePickerComponent
              id="suspension_until"
              placeholder="Until"
              value={unixToJsDate(until)}
              onChange={e => updateForm('until', jsDateToUnix(e.value))}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{ textAlign: 'center', paddingTop: 20 }}
          spacing={4}
        >
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Foul"
              margin="dense"
              onChange={e => updateForm('foul', e.target.value)}
              required
              value={foul}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{ textAlign: 'center', paddingTop: 20 }}
          spacing={4}
        >
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Notes"
              margin="dense"
              onChange={e => updateForm('notes', e.target.value)}
              required
              value={notes}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              multiline
              minRows={3}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SuspensionDialog

import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import TextField from '@material-ui/core/TextField'
import Select from 'react-select'
import Button from '@material-ui/core/Button'

import { applyFilter, exportToCSV } from 'helper'
import { getPickupPlayers, checkIn } from 'pickup/actions'
import { actions } from 'pickup/redux'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class PresenceTable extends React.Component {
  componentDidMount() {
    this.props.getPickupPlayers(this.props.start_date)
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        {this.props.is_admin && (
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={this.props.facilities}
              placeholder="Facility"
              value={
                this.props.facility_name &&
                this.props.facilities.find(
                  p => p.value === this.props.facility_name
                )
              }
              onChange={v =>
                this.props.changeFilterDetails('facility_name', v && v.name)
              }
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              isClearable
            />
          </Grid>
        )}
        <Grid item xs={8} style={{ textAlign: 'center' }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Filter customer..."
            onChange={e => this.props.changeQuery(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          Displaying <b>{this.props.players.length}</b> players.
          {this.props.is_admin && !!this.props.players.length && (
            <Button
              className="save-btn"
              style={{ marginLeft: 10 }}
              onClick={() =>
                exportToCSV(
                  this.props.players.map(player => {
                    let participant = { ...player }
                    delete participant.customer
                    delete participant.CreatedAt
                    delete participant.UpdatedAt
                    delete participant.DeletedAt
                    return {
                      first_name: player.customer.first_name,
                      last_name: player.customer.last_name,
                      email: player.customer.email,
                      ...participant,
                    }
                  }),
                  'Players'
                )
              }
            >
              Export
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }}>
                    Facility
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>Date</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>Slot</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Customer
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Contact
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Checked in
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.players.map((participant, i) => (
                  <TableRow key={`${participant.ID}`}>
                    <TableCell style={{ textAlign: 'center' }}>
                      {participant.facility}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {participant.date}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {participant.day}
                      <br />
                      {participant.start_time} - {participant.end_time}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: 'center' }}
                    >
                      {participant.customer.first_name}{' '}
                      {participant.customer.last_name}
                      <Link
                        href={`/customer/${participant.customer_id}`}
                        target="_blank"
                      >
                        <IconButton aria-label="delete">
                          <ExitToAppIcon fontSize="small" />
                        </IconButton>
                      </Link>
                      </TableCell>
                      <TableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: 'center' }}
                      >
                      <small>{participant.customer.email}<br />{participant.customer.phone}</small>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      <Checkbox
                        checked={participant.checked_in}
                        onChange={e =>
                          this.props.checkIn(participant.ID, e.target.checked)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  players: state.pickup.players
    .filter(s => {
      if (state.pickup.filter.facility_name) {
        return s.facility === state.pickup.filter.facility_name
      }
      return true
    })
    .filter(p =>
      applyFilter(
        {
          first_name: p.customer.first_name,
          last_name: p.customer.last_name,
          email: p.customer.email,
        },
        state.pickup.filter.query
      )
    ),
  start_date: state.pickup.filter.start_date,
  facilities: state.common.filter_facilities,
  is_admin: state.user.user_type === 'admin',
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPickupPlayers: d => dispatch(getPickupPlayers(d)),
  checkIn: (id, checked_in) => dispatch(checkIn(id, checked_in)),
  changeQuery: v =>
    dispatch(actions.changeFilterDetails({ key: 'query', value: v })),
  changeFilterDetails: (k, v) =>
    dispatch(actions.changeFilterDetails({ key: k, value: v })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PresenceTable))

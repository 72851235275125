import { ERP_BACKEND_URL } from './../consts'
import axios from 'axios'
import moment from 'moment'
import { actions } from './redux'
import { parseEventLogs, parseInvoiceLogs } from './helper'

var _ = require('lodash')

export const getFacilities = () => (dispatch, getState) => {
  return axios
    .get(ERP_BACKEND_URL + `/facility`)
    .then(resp => {
      dispatch(
        actions.getFacilities({
          facilities: _.sortBy(resp.data, ['name']),
          is_admin: getState().user.is_admin,
          user_facility_id: getState().user.facility_id,
        })
      )
    })
    .catch(console.error)
}

export const getProductTypes = () => (dispatch, getState) => {
  return axios
    .get(ERP_BACKEND_URL + `/product/types`)
    .then(resp => {
      dispatch(
        actions.getProductTypes({
          product_types: _.sortBy(resp.data, ['ID']),
        })
      )
    })
    .catch(console.error)
}

export const openDeleteModal = (name, handleDelete) => (dispatch, getState) => {
  dispatch(
    actions.openDeleteModal({
      name,
      handleDelete,
    })
  )
}

export const closeDeleteModal = () => (dispatch, getState) => {
  dispatch(actions.closeDeleteModal())
}

export const openConfirmModal =
  (subject, message, handleConfirm) => (dispatch, getState) => {
    dispatch(
      actions.openConfirmModal({
        subject,
        message,
        handleConfirm,
      })
    )
  }

export const closeConfirmModal = () => (dispatch, getState) => {
  dispatch(actions.closeConfirmModal())
}

export const changeLoading = loading => (dispatch, getState) => {
  dispatch(actions.changeLoading({ loading }))
}

export const dispatchError = err => (dispatch, getState) => {
  console.error(err)
  if (err.response) {
    return dispatch(
      actions.dispatchError({ open: true, err: err.response.data })
    )
  }
  if (err.message) {
    return dispatch(actions.dispatchError({ open: true, err: err.message }))
  }
  dispatch(actions.dispatchError({ open: true, err: JSON.stringify(err) }))
}

export const closeError = () => (dispatch, getState) => {
  dispatch(actions.dispatchError({ open: false }))
}

export const updateFacilityID = id => (dispatch, getState) => {
  localStorage.setItem('facility_id', id)
  window.location.reload(false)
}

export const getEventLogs = (log_type, id) => (dispatch, getState) => {
  if (!id) return
  return axios
    .get(ERP_BACKEND_URL + `/changelog?object=${log_type}&id=${id}`)
    .then(resp => {
      const logs = resp.data.sort((a, b) => {
        if (a.ID > b.ID) return 1
        if (a.ID < b.ID) return -1
        return 0
      })
      let parsed_logs = []
      logs.forEach(log => {
        let parsed_log = null
        try {
          parsed_log = JSON.parse(log.new_value)
        } catch (e) {
          console.error(e)
        }
        if (parsed_log) {
          parsed_log.log = {
            ID: log.ID,
            action: log.action,
            user: log.user,
            date: moment(log.CreatedAt).utc().format('DD/MM/YYYY h:mmA'),
          }
          parsed_logs.push(parsed_log)
        }
      })
      if (log_type === 'event') {
        parsed_logs = parseEventLogs(parsed_logs)
      }
      if (log_type === 'invoice') {
        parsed_logs = parseInvoiceLogs(parsed_logs)
      }
      dispatch(
        actions.addLogs({
          log_type,
          logs: parsed_logs,
        })
      )
    })
}

export const openHubspotList = payload => (dispatch, getState) => {
  dispatch(actions.updateHubspot({ key: 'open', value: true }))
  dispatch(actions.updateHubspot({ key: 'payload', value: payload }))
  dispatch(actions.updateHubspot({ key: 'list_id', value: null }))
}

export const generateHubspotList = () => (dispatch, getState) => {
  const { payload } = getState().common.hubspot
  let body = {}
  if (payload.league_ids)
    body.league_ids = payload.league_ids.map(id => parseInt(id))
  if (payload.team_ids)
    body.team_ids = payload.team_ids.map(id => parseInt(id))
  if (payload.class_ids)
    body.class_ids = payload.class_ids.map(id => parseInt(id))
  dispatch(changeLoading(true))
  return axios
    .post(ERP_BACKEND_URL + `/customer/hubspot`, body)
    .then(resp => {
      dispatch(actions.updateHubspot({ key: 'list_id', value: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(console.error)
}

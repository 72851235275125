import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import ClassDetails from 'classes/components/ClassDetails'
import Menu from 'classes/components/Menu'
import EventsTable from 'classes/components/EventsTable'
import WrappedParticipantTable from 'classes/components/WrappedTable'
import FeeUpdateDialog from 'classes/components/FeeUpdateDialog'
import UniformSizeUpdateDialog from 'classes/components/UniformSizeUpdateDialog'
import ManualClassEvent from 'classes/components/ManualClassDialog'
import BroadcastMessage from './../components/BroadcastMessage'
import EditEventDialog from '../components/EditEventDialog'
import ChangeClassDialog from '../components/ChangeClassDialog'
import AddParticipantsDialog from '../components/AddParticipantsDialog'
import ClassDetailsForm from 'classes/components/forms/ClassDetails'
import CloneClassDialog from 'classes/components/CloneClassDialog'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import HubspotListDialog from 'common/components/HubspotListDialog'
import { useReactToPrint } from 'react-to-print'

import { getClassDetails } from 'classes/actions'
import { getNewProducts, getGLs } from './../../product/actions'
import { actions } from 'classes/redux'
import DivisionsList from 'classes/components/DivisionsList'
import WaitlistTable from 'classes/components/WaitlistTable'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Manage = ({
  alert_open,
  alert_message,
  closeAlert,
  classInfo,
  getClassDetails,
  getNewProducts,
  getGLS,
  match,
  tab,
  openAddClassEventModal,
  openAddParticipantModal,
}) => {
  useEffect(() => {
    const { id } = match.params
    getClassDetails(id)
  }, [getClassDetails, match.params])
  useEffect(() => {
    getNewProducts()
  }, [getNewProducts])
  useEffect(() => {
    getGLS()
  }, [getGLS])

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  return (
    <div>
      <Snackbar
        open={alert_open}
        autoHideDuration={3000}
        onClose={() => closeAlert()}
      >
        <Alert severity="success" onClose={() => closeAlert()}>
          {alert_message}
        </Alert>
      </Snackbar>
      {classInfo && (
        <>
          <FeeUpdateDialog />
          <UniformSizeUpdateDialog />
          <BroadcastMessage />
          <EditEventDialog />
          <ManualClassEvent />
          <AddParticipantsDialog />
          <ChangeClassDialog />
          <HubspotListDialog />
          <CloneClassDialog />
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            style={{ paddingTop: '2%' }}
            spacing={4}
          >
            <Grid item xs={classInfo.is_container ? 12 : 4}>
              <ClassDetails />
            </Grid>
            <Grid item xs={classInfo.is_container ? 12 : 8}>
              <Menu is_container={classInfo.is_container} />
              <Grid justify="center" container>
                {!['settings', 'divisions', 'waitlist'].includes(tab) ? (
                  <Button
                    className="save-btn"
                    style={{ marginBottom: 20 }}
                    onClick={
                      tab === 'events'
                        ? openAddClassEventModal
                        : openAddParticipantModal
                    }
                  >
                    Add new
                  </Button>
                ) : null}
                {['participants'].includes(tab) ? (
                  <Button
                    className="save-btn"
                    style={{ marginBottom: 20, marginLeft: 10 }}
                    onClick={handlePrint}
                  >
                    Print
                  </Button>
                ) : null}
              </Grid>
              {tab === 'events' && <EventsTable />}
              {tab === 'participants' && (
                <WrappedParticipantTable ref={componentRef} />
              )}
              {tab === 'waitlist' && <WaitlistTable />}
              {tab === 'settings' && <ClassDetailsForm edit />}
              {tab === 'divisions' && <DivisionsList edit />}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  classInfo: state.classes.classInfo,
  tab: state.classes.tab,
  alert_open: state.classes.alert_message_open,
  alert_message: state.classes.alert_message,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getClassDetails: id => dispatch(getClassDetails(id)),
  getNewProducts: () => dispatch(getNewProducts()),
  getGLS: () => dispatch(getGLs()),
  closeAlert: () => dispatch(actions.closeAlert()),
  changeTab: tab => dispatch(actions.changeTab({ tab })),
  openAddClassEventModal: () => dispatch(actions.toggleClassEventModal(true)),
  openAddParticipantModal: () =>
    dispatch(actions.toggleAddParticipantModal(true)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)

import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { colors } from './../../consts'

const styles = theme => ({
    card: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }),
  menu = [
    {
      subtitle: 'Resources',
      title: 'Customers',
      description: 'Manage your customers and their hubspot attributes',
      url: '/customer',
      only_admin: false,
    },
    {
      subtitle: 'Resources',
      title: 'Discounts',
      description: 'Manage your discounts and dynamic rules',
      url: '/discount',
      only_admin: false,
    },
    {
      subtitle: 'Programs',
      title: 'Leagues',
      description: 'Manage all your leagues and price mapping',
      url: '/leagues',
      only_admin: false,
    },
    {
      subtitle: 'Programs',
      title: 'Teams',
      description: 'Manage all your teams and player rosters',
      url: '/teams',
      only_admin: false,
    },
    {
      subtitle: 'Resources',
      title: 'E-Players',
      description: 'Manage all your eplayers data and matches',
      url: '/eplayer',
      only_admin: false,
    },
    {
      subtitle: 'Resources',
      title: 'Invoices',
      description: 'Manage and export all of your invoices',
      url: '/invoice',
      only_admin: true,
    },
    {
      subtitle: 'Resources',
      title: 'Staff',
      description: 'Manage all your staff and coaches',
      url: '/staff',
      only_admin: true,
      only_manager: true,
    },
    {
      subtitle: 'Resources',
      title: 'Scoreboards',
      description: 'Manage all your scoreboards',
      url: '/scoreboards',
      only_admin: true,
      only_manager: true,
    },
    {
      subtitle: 'Resources',
      title: 'Resources',
      description: 'Manage all your resources',
      url: '/resources',
      only_admin: true,
      only_manager: true,
    },
  ]

class AdminPanel extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ textAlign: 'center', paddingTop: '2%' }}
        spacing={8}
      >
        {menu
          .filter(m => {
            if (this.props.is_admin) return true
            if (this.props.is_manager) {
              return m.only_manager || !m.only_admin
            }
            return !m.only_admin
          })
          .map(m => (
            <Grid item xs={12} md={4} xl={3} key={m.title}>
              <Card className={classes.card} elevation={0}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {m.subtitle}
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {m.title}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {m.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    style={{
                      left: '50%',
                      transform: 'translate(-50%)',
                      color: colors.blue_strong,
                      backgroundColor: colors.blue_mild,
                    }}
                    onClick={() => (window.location = m.url)}
                  >
                    <b>Manage</b>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  is_admin: state.user.user_type === 'admin',
  is_manager: state.user.user_type === 'manager',
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdminPanel))

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import Select from 'react-select'
import { actions } from '../../redux'
import { updateMembership, getCustomer } from '../../actions'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import { unixToDateTime, jsDateToUnix, unixToJsDate } from 'helper'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Button, Typography } from '@material-ui/core'
// TODO: Change customer here
class UpdateMembership extends React.Component {
  getOptions() {
    if (!this.props.customer) return []
    let options = [
      {
        label: `${this.props.customer.first_name} ${this.props.customer.last_name}`,
        value: this.props.customer.ID,
        ID: this.props.customer.ID,
        facility_id: this.props.customer.facility_id,
      },
    ]

    for (const m of this.props.family) {
      options.push({
        label: `${m.child.first_name} ${m.child.last_name} (${m.relation})`,
        value: m.child.ID,
        ID: m.child.ID,
        facility_id: m.child.facility_id,
      })
    }
    return options
  }
  render() {
    let membership = this.props.membership
    let options = this.getOptions()
    return (
      <Dialog
        open={this.props.open_update}
        onClose={() => this.props.handleClose()}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        disableBackdropClick={true}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Edit membership
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={() => this.props.handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <Grid item xs={12}>
              <Typography>Current Holder</Typography>
              <Select
                options={options}
                placeholder="Select Family member..."
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                onChange={e => {
                  membership = {
                    ...membership,
                    sub_customer_id: e.value,
                  }
                  this.props.updateMembershipData(membership)
                }}
                value={options.find(o => o.ID == membership?.sub_customer_id)}
                menuPortalTarget={document.body}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Expiration date</Typography>
              <DatePickerComponent
                id="start_date"
                placeholder="Start date"
                value={unixToJsDate(membership?.valid_until)}
                step={15}
                onChange={e => {
                  membership = {
                    ...membership,
                    valid_until: jsDateToUnix(e.value),
                  }
                  this.props.updateMembershipData(membership)
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props
                .updateMembership()
                .then(resp => window.location.reload())
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.customer.membership_dialog,
  customer: state.customer.customer,
  family: state.customer.family,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () =>
    dispatch(
      actions.updateMembershipDialog({ key: 'open_update', value: false })
    ),
  updateMembershipData: membership => {
    dispatch(
      actions.updateMembershipDialog({ key: 'membership', value: membership })
    )
  },
  getCustomer: customer_id => dispatch(getCustomer(customer_id)),
  updateMembership: () => {
    dispatch(updateMembership())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMembership)

import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { Typography } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Avatar from 'react-avatar-edit'
import { updateCustomerPhoto } from './../actions'
import Webcam from "react-webcam";

import { actions } from '../redux'

const ProfilePhotoDialog = () => {

  const [takePicture, setTakePicture] = useState(false)
  const [devices, setDevices] = useState([]);
  const [selectDevice, setSelectDevice] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const webcamRef = useRef(null);

  useEffect(() => {
    const getDevices = async () => {
      try {
        const devicesList = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devicesList.filter(device => device.kind === 'videoinput');
        setDevices(videoDevices);
      } catch (error) {
        alert("Coudn't find any devices!")
      }
    };
    getDevices();
  }, []);

  const dispatch = useDispatch(),
    { open, preview } = useSelector(state => state.customer.profile_photo)

  function onClose() {
    dispatch(actions.updateProfilePhoto({ key: 'open', value: false }))
  }
  function onCrop(pv) {
    dispatch(actions.updateProfilePhoto({ key: 'preview', value: pv }))
  }
  function onBeforeFileLoad(elem) {
    if (elem.target.files[0].size > 716800) {
      alert('File is too big!')
      elem.target.value = ''
    }
  }
  function updateProfilePicture() {
    dispatch(updateCustomerPhoto(preview))
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      disableBackdropClick={true}
      fullWidth
    >
      <DialogTitle id="form-dialog-title" style={{textAlign:'center'}}>
        Upload profile picture
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container direction='row' justifyContent="space-around" alignItems="center" >

          <Grid>
            <Avatar
              width={200}
              height={200}
              exportSize={300}
              onCrop={onCrop}
              cropRadius={150}
              onClose={onClose}
              exportAsSquare={true}
              onBeforeFileLoad={onBeforeFileLoad}
              src={null}
            />
          </Grid>
          <Grid>
            <span>Or</span>
          </Grid>

          <Grid  
              container
              xs={3}
              direction="column"
              justifyContent="center"
              alignItems="center"
              >
            <Button onClick={() => {setTakePicture(!takePicture)}} style={{borderWidth:1, borderColor: 'black', borderStyle: 'solid', borderRadius:6}}> 
              {takePicture ? 'Close Webcam' : 'Open Webcam'}
            </Button>

            {takePicture && 
            <>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                screenshotQuality={1.0}
                width={200}
                height={200}
                imageSmoothing={true}
                videoConstraints={{ 
                  deviceId: selectedDevice,
                  facingMode: "environment" }}
              />
              {selectDevice &&
              <>
                <h2>Available Devices:</h2>
                <ul>
                  {devices.map((device, index) => (
                  <li key={index}>
                    <Button onClick={() => setSelectedDevice(device.deviceId)}>
                      {device.label || `Device ${index + 1}`}
                    </Button>
                  </li>
                  ))}
                </ul>
              </>
              }
              <Button variant="contained" size='small' style={{marginBottom:10}} onClick={() => setSelectDevice(!selectDevice)}>
                {!selectDevice ? "Select another camera" : "Hide devices"}
              </Button>

              <Button variant="contained" size='small' onClick={() => {onCrop(webcamRef.current.getScreenshot())}}>
                Take Picture
              </Button>
            </>}
          </Grid>
        </Grid>

          <Grid container direction="column" justifyContent="center" alignItems="center" >
            {preview && 
            <><img src={preview} alt="Preview" />
            <Typography variant="h4">Preview</Typography>
            </>}
          </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={updateProfilePicture}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProfilePhotoDialog

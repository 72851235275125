import React from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import { getUpcomingClassesByFacility, changeClass } from 'classes/actions'
import { openConfirmModal } from 'common/actions'
import { actions } from 'classes/redux'
import { colors } from 'consts'
import Select from 'react-select'

const ChangeClassDialog = ({
  open,
  handleClose,
  getUpcomingClasses,
  formData,
  facilities,
  changeformData,
  classes,
  saveNewClass,
}) => {
  const divisionOptions = formData?.season?.divisions?.map(d => ({
    value: d.ID,
    label: d.name,
  }))
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Change class
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Select
              options={facilities}
              placeholder="Select facility..."
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              value={
                formData.facility_id &&
                facilities.find(f => f.value === formData.facility_id)
              }
              onChange={async e => {
                await changeformData('facility_id', e.value)
                getUpcomingClasses(e.value)
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Select
              options={classes}
              placeholder={'Select Season'}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              value={classes?.find(f => f.value === formData.season_id)}
              onChange={e => {
                changeformData('season_id', e.value)
                let foundClass = classes?.find(f => f.value === e.value)
                changeformData('season', foundClass.obj)
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Select
              options={divisionOptions}
              placeholder="Select Division"
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              value={divisionOptions?.find(
                f => f.value === formData.new_class_id
              )}
              onChange={e => {
                console.log(e.value)
                changeformData('new_class_id', e.value)
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          {formData.new_class_id && (
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <h3>Check class</h3>
              <Link href={`/classes/${formData.new_class_id}`} target="_blank">
                <IconButton>
                  <OpenInNewIcon
                    fontSize="small"
                    className="save-btn"
                    style={{
                      backgroundColor: 'transparent',
                      color: colors.blue_strong,
                    }}
                  />
                </IconButton>
              </Link>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveNewClass} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.classes.change_class,
  classes: state.classes.classes
    ?.filter(c => c.is_container)
    .map(c => ({
      value: c.ID,
      label: c.name,
      obj: c,
    })),
  formData: state.classes.change_class,
  facilities: state.common.filter_facilities,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () =>
    dispatch(actions.editChangeClass({ key: 'open', value: false })),
  getUpcomingClasses: facilityID =>
    dispatch(getUpcomingClassesByFacility(facilityID)),
  changeformData: (key, value) =>
    dispatch(actions.editChangeClass({ key, value })),
  saveNewClass: () => {
    dispatch(
      openConfirmModal('Player class change', `change the player's class`, () =>
        changeClass()
      )
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeClassDialog)

import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { checkAttendance } from 'calendar/actions'

const styles = theme => ({
  maxheight: {
    maxHeight: 650,
    overflow: 'scroll',
  },
  root: {
    padding: 2,
  },
})

class PresenceTable extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    console.log('this.props.players', this.props.players)
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          Displaying <b>{this.props.players.length}</b> players.
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }}>
                    Is free trial
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Customer
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Checked in
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.players.map((participant, i) => (
                  <TableRow key={`${participant.ID}`}>
                    <TableCell style={{ textAlign: 'center' }}>
                      {participant.temporary ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ textAlign: 'center' }}
                    >
                      {participant.customer?.first_name}{' '}
                      {participant.customer?.last_name}
                      <Link
                        href={`/customer/${participant.customer_id}`}
                        target="_blank"
                      >
                        <IconButton aria-label="delete">
                          <ExitToAppIcon fontSize="small" />
                        </IconButton>
                      </Link>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      <Checkbox
                        checked={participant.attended}
                        onChange={e => {
                          console.log('participant', participant)
                          const attendance = {
                            ...participant,
                            attended: !participant.attended,
                          }
                          console.log('attendance', attendance)
                          this.props.checkIn(attendance)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  players: state.calendar.event_details.attendances,
  start_date: state.pickup.filter.start_date,
  facilities: state.common.filter_facilities,
  is_admin: state.user.user_type === 'admin',
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  checkIn: attendance => dispatch(checkAttendance(attendance)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PresenceTable))

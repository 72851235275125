import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Select from 'react-select'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import CreateTeamForm from 'teams/components/forms/CreateTeam'

import {
  changeLeagueDetails,
  toggleAddTeamModal,
  addTeamSignup,
  toggleNewTeam,
} from './../../actions'
import { leagueSlice } from 'league/redux'
import { searchTeams } from 'teams/actions'

class TeamDialog extends React.Component {
  render() {
    const { open, handleClose, teams, addTeamSignup } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
          Add team to league
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <Grid item xs={12}>
              <Tabs
                value={this.props.tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, val) => this.props.changeTab(val)}
                centered
              >
                <Tab value="existing" label="Existing team" />
                {!this.props.no_create && <Tab value="new" label="New team" />}
              </Tabs>
            </Grid>
            {this.props.tab === 'existing' && (
              <Grid item xs={12}>
                <Autocomplete
                  options={teams}
                  getOptionLabel={team => team.name}
                  renderOption={team => (
                    <p>
                      {team.name}{' '}
                      {team.captain && (
                        <span>
                          <br />
                          Captain = {team.captain.customer.first_name}{' '}
                          {team.captain.customer.last_name}
                        </span>
                      )}
                    </p>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Team"
                      variant="outlined"
                      fullWidth
                      onChange={e =>
                        this.props.searchTeams(
                          this.props.facility_id,
                          e.target.value
                        )
                      }
                    />
                  )}
                  value={this.props.new_team}
                  onChange={(e, t) =>
                    this.props.changeLeagueDetails('new_team', t)
                  }
                  fullWidth
                />
              </Grid>
            )}
            {this.props.tab === 'new' && (
              <Grid item xs={12}>
                <CreateTeamForm hide_facility />
              </Grid>
            )}
            <Grid item xs={12}>
              <Select
                options={this.props.fees}
                placeholder="Select fee *"
                onChange={e => {
                  this.props.changeLeagueDetails('fee_amt', e.value)
                  this.props.changeLeagueDetails('discount_amt', e.discount)
                }}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              addTeamSignup()
              handleClose()
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const league = state.league.league
  let new_product = league?.new_product
  new_product?.variations?.forEach((variation)=>{
    if (variation.facility_id == league.facility_id){
      new_product = variation
    }
  })
  let fees = [{ label: `League has not product`, value: 0 }]
  if (league && new_product) {
    fees = [
      {
        label: `Full price ($${new_product.team_price})`,
        value: new_product.team_price,
        discount: 0,
      },
    ]
    if (league.early_bird_end_date > moment().unix()){
      fees.push(
        {
          label: `Early bird ($${new_product.early_bird_price})`,
          value: new_product.early_bird_price,
          discount: new_product.team_price - new_product.early_bird_price,
        },
      )
    }
  }
  let teams = []
  if (league) {
    const existingTeams = state.league.league.teams.map(t => t.team.ID)
    teams = state.teams.teams
      .filter(t => existingTeams.indexOf(t.ID) === -1)
      .map(t => {
        if (!t.captain_id) return t
        return {
          ...t,
          captain: t.roster.find(r => r.ID === t.captain_id),
        }
      })
  }
  return {
    open: state.league.add_team_dialog_open,
    teams,
    new_team: state.league.create_league.new_team,
    fee_amt: state.league.create_league.fee_amt,
    create_team: state.league.create_league.create_team,
    fees,
    facility_id: league && league.facility_id,
    tab: state.league.add_team_tab,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(toggleAddTeamModal(false)),
  addTeamSignup: () => dispatch(addTeamSignup()),
  changeLeagueDetails: (k, v) => dispatch(changeLeagueDetails(k, v)),
  searchTeams: (id, query) => dispatch(searchTeams(id, query)),
  toggleNewTeam: create_team => dispatch(toggleNewTeam(create_team)),
  changeTab: tab => dispatch(leagueSlice.actions.changeAddTeamTab({ tab })),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamDialog)

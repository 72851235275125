import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import queryString from 'query-string'
import { momentDateTimeToUnix, unixToDateTime, DATE_TIME_FORMAT } from './../../helper'
import { isEmpty, isEqual, xorWith } from 'lodash'

import {
  Day,
  Week,
  Month,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  DragAndDrop,
} from '@syncfusion/ej2-react-schedule'

import '@syncfusion/ej2-base/styles/material.css'
import '@syncfusion/ej2-buttons/styles/material.css'
import '@syncfusion/ej2-calendars/styles/material.css'
import '@syncfusion/ej2-dropdowns/styles/material.css'
import '@syncfusion/ej2-inputs/styles/material.css'
import '@syncfusion/ej2-lists/styles/material.css'
import '@syncfusion/ej2-navigations/styles/material.css'
import '@syncfusion/ej2-popups/styles/material.css'
import '@syncfusion/ej2-react-schedule/styles/material.css'
import './../calendar.css'

import { openConfirmModal } from 'common/actions'
import { getEvents, updateEventTimes } from './../actions'
import { actions } from './../redux'
const { changeEventDetails, toggleEventModal, selectEvent, openNewEvent, toggleTooltip } =
  actions
  
let hoverTimer;
let initialized = false
const hoverThreshold = 4000; // 1 second in milliseconds
let isDragging = false
const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual))
const pickTextColorBasedOnBg = bgColor => {
  if (!bgColor || bgColor === '') {
    return '#000000'
  }
  var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
  var r = parseInt(color.substring(0, 2), 16) // hexToR
  var g = parseInt(color.substring(2, 4), 16) // hexToG
  var b = parseInt(color.substring(4, 6), 16) // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF'
}
class Calendar extends React.Component {

  onEventRendered(args) {
    this.applyCategoryColor(args)
  }

  applyCategoryColor(args) {
    try {
      let categoryColor = args.data.color
      args.element.style.cursor = 'pointer'
      let bg_color = categoryColor
      if ([3, 4, 8, 9].includes(args.data.product_type_id)) {
        if (categoryColor) {
          args.element
            .querySelector('.e-appointment-details')
            .style.setProperty('--color', categoryColor)
        }
        bg_color = '#03A9F4'
      }
      if (args.data.product_type.color && args.data.product_type.color != '') {
        bg_color = args.data.product_type.color
      }

      args.element.style.backgroundColor = bg_color
      args.element.style.color = pickTextColorBasedOnBg(bg_color)
      if (args.data.product_type_id !== 5) {
        let img = `<img src="https://a.slack-edge.com/production-standard-emoji-assets/10.2/apple-medium/2714-fe0f@2x.png" aria-label="Émoji coche trait plein" alt=":coche_trait_plein:" data-stringify-type="emoji" data-stringify-emoji=":heavy_check_mark:" style="
        width: 20px;">`
        if (!args.data.invoice) {
          img = `<img src="https://a.slack-edge.com/production-standard-emoji-assets/10.2/apple-medium/274c@2x.png" aria-label="Émoji coche trait plein" alt=":coche_trait_plein:" data-stringify-type="emoji" data-stringify-emoji=":heavy_check_mark:" style="
        width: 20px;">`
        }
        if (args.data.invoice && args.data.balance > 0) {
          img = `<img src="https://a.slack-edge.com/production-standard-emoji-assets/10.2/apple-medium/2757@2x.png" aria-label="Émoji coche trait plein" alt=":coche_trait_plein:" data-stringify-type="emoji" data-stringify-emoji=":heavy_check_mark:" style="
        width: 20px;">`
        }
        if (
          args.data.product_type_id === 3 ||
          args.data.product_type_id === 8
        ) {
          if (
            args.data.match?.home_score !== '' &&
            args.data.match?.away_score !== ''
          ) {
            img = `<img src="https://a.slack-edge.com/production-standard-emoji-assets/10.2/apple-medium/2714-fe0f@2x.png" aria-label="Émoji coche trait plein" alt=":coche_trait_plein:" data-stringify-type="emoji" data-stringify-emoji=":heavy_check_mark:" style="
              width: 20px;">`
          } else {
            img = null
          }
        }
        if (img) {
          args.element.querySelector('.e-appointment-details').innerHTML += img
        }
        // If product_type_id == 7 young contract show the contract name on the calendar
        if (args.data.product_type_id == 7) {
          args.element.querySelector(
            '.e-appointment-details'
          ).innerHTML += `<span style="font-size: 10px">${[
            `${args.data.contract.name}`,
            `Contract #${args.data.contract_id}`,
          ].join('<br/>')}</span><br/>`
        } else {
          args.element.querySelector(
            '.e-appointment-details'
          ).innerHTML += `<br/><span style="font-size: 10px">${args.data.Description.split(
            '-'
          ).join('<br/>')}</span><br/>`
        }
        if (args.data.balance > 0) {
          args.element.querySelector(
            '.e-appointment-details'
          ).innerHTML += `<span style="color: rgb(179, 43, 0); font-size: 13px">Balance: $${args.data.balance}</span>`
        }
      }
      if (args.data.small) {
        args.element.style.width = '50%'
      } else {
        //args.element.style.minWidth = '100%'
        //args.element.style.left = 0
      }
    } catch (e) {
      console.error(e)
    }
  }

  emptyCellClick(args) {
    args.cancel = true
    this.props.openNewEvent({
      start_time: momentDateTimeToUnix(moment(args.startTime)),
      field_id: this.props.fields[args.groupIndex].ID,
      end_time: momentDateTimeToUnix(moment(args.endTime).add(30, 'm')),
    })
    this.props.openEventModal()
  }

  eventClick(args) {
    const win = window.open(`/calendar/event/${args.event.ID}`, '_blank')
    if (win != null) {
      win.focus()
    }
  }

  onDataBound(args) {
    const currentHour = moment().format('HH') + ':00'
    if (!initialized){
      this.scheduleObj.scrollTo(currentHour)
      initialized = true
    }
  }

  onRenderCell(args) {
    if (args.elementType === 'resourceHeader') {
      const spl = args.element.innerText.split('|')
      args.element.innerHTML = `<div class="e-text-ellipsis">${spl[0]}<br/><small>${spl[1]}</small></div>`
    }
  }

  onDragStart(args) {
    isDragging = true
    args.interval = 5
  }

  onDragStop(args) {
    let cellData = this.scheduleObj.getCellDetails(args.event.target)
    const field = this.props.fields[cellData.groupIndex],
      field_id = field.ID,
      start = cellData.startTime,
      durationUnix = args.data.end_date - args.data.start_date,
      prev_field = `${args.data.field.number} - ${args.data.field.name}`,
      new_field = field.name.split('|')[0]
    let changes = []
    isDragging = false
    if (prev_field !== new_field) {
      changes.push(`field ${prev_field} -> ${new_field}`)
    }
    const start_date = unixToDateTime(args.data.start_date).format(
        'MM/DD h:mmA'
      ),
      new_start_date = moment(start).format('MM/DD h:mmA')
    if (start_date !== new_start_date) {
      changes.push(`time ${start_date} -> ${new_start_date}`)
    }
    if (!changes.length) return
    this.props.updateEventTimes(
      changes,
      args.data.ID,
      field_id,
      start,
      durationUnix
    )
  }
  template(props) {
    return (
      <div className="tooltip-wrap">
        <div className="content-area">
          <div className="event-name">{'⚽ '+props?.product_type?.name}</div>
          <div className="event-name"><strong>{props.comment}</strong></div>
          {props.class_id > 0 && props.class &&
          <>
            <div className="time">
              Attendees: <strong>{ props.class.attendees.length}</strong>
            </div>
            <div className="time">
              Free Trials: <strong>{ props.assistents.length}</strong>
            </div>
            </>
          }
          <div className="time">
            From&nbsp;:&nbsp;{moment.unix(props.start_date).utc().format('M/D/YYYY HH:mm')}
          </div>
          <div className="time">
            To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
            {moment.unix(props.end_date).utc().format('M/D/YYYY HH:mm')}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const height = window.innerHeight
    const { fields, events, search } = this.props
    return (
      <div>
        <ScheduleComponent
          ref={schedule => (this.scheduleObj = schedule)}
          group={{ resources: ['Fields'] }}
          currentView="Day"
          eventSettings={{
            dataSource: events,
            enableTooltip: !isDragging,
            tooltipTemplate: this.template.bind(this),
          }}
          renderCell={this.onRenderCell.bind(this)}
          eventRendered={this.onEventRendered.bind(this)}
          showQuickInfo={false}
          eventClick={this.eventClick.bind(this)}
          cellClick={this.emptyCellClick.bind(this)}
          cssClass="schedule-cell-dimension"
          navigating={d => {
            if (d.currentView === 'Week') {
              const start = moment(d.currentDate).startOf('week'),
                end = start.clone().add(7, 'd')
              return this.props.getEvents(start, end)
            }
            if (d.currentView === 'Month') {
              const start = moment(d.currentDate).startOf('month'),
                end = start.clone().add(30, 'd')
              return this.props.getEvents(start, end)
            }
            d.cancel = true
            if (d.action === 'date') {
              this.scheduleObj.selectedDate = d.currentDate
            }
            this.props.getEvents(moment(d.currentDate).startOf('day'))
          }}
          created={() => {
            if (search) {
              const query = queryString.parse(search)
              if (query.date) {
                const parsed = moment(query.date, 'M/D/YYYY')
                this.scheduleObj.selectedDate = parsed.toDate()
                this.props.getEvents(parsed.startOf('day'))
              }
            }
          }}
          startHour="00:00"
          endHour="24:00"
          width="100%"
          height={height}
          timeScale={{ enable: true, interval: 60, slotCount: 4 }}
          dataBound={this.onDataBound.bind(this)}
          dragStart={this.onDragStart.bind(this)}
          dragStop={this.onDragStop.bind(this)}
        >
          <ViewsDirective>
            <ViewDirective option="Day" />
            <ViewDirective option="Week" />
            <ViewDirective option="Month" />
          </ViewsDirective>
          <ResourcesDirective>
            <ResourceDirective
              field="field_id"
              title="Field"
              name="Fields"
              allowMultiple={false}
              dataSource={fields}
              textField="name"
              idField="ID"
            />
          </ResourcesDirective>
          <Inject services={[Day, Week, Month, DragAndDrop]} />
        </ScheduleComponent>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  fields: state.calendar.fields.map(f => ({
    ...f,
    name: `${f.number} - ${f.name}|${f.new_field_type.name}`,
  })),
  events: state.calendar.events.map(e => ({
    ...e,
    StartTime: unixToDateTime(e.start_date).toDate(),
    EndTime: unixToDateTime(e.end_date).toDate(),
  })),
  tooltip: state.calendar.tooltip,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  openEventModal: () => dispatch(toggleEventModal(true)),
  toggleTooltip: (value) => dispatch(toggleTooltip(value)),
  changeEventDetails: (k, v) =>
    dispatch(changeEventDetails({ field: k, value: v })),
  getEvents: (start, end) => dispatch(getEvents(start, end)),
  selectEvent: event => dispatch(selectEvent({ event })),
  openNewEvent: event => dispatch(openNewEvent({ event })),
  updateEventTimes: (changes, ev, field, start, duration) => {
    dispatch(
      openConfirmModal(
        'Event update',
        `update this event, changes: ${changes.join(' / ')}`,
        () => updateEventTimes(ev, field, start, duration)
      )
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)

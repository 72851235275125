import React from 'react'
import { connect } from 'react-redux'

import Select from 'react-select'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { changeProductDetails } from './../../actions'

class LeagueForm extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ textAlign: 'center' }}
        spacing={4}
      >
        <Grid item xs={6}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
            spacing={4}
          >
            <Grid item xs={12}>
              <TextField
                label="Name *"
                fullWidth
                variant="outlined"
                value={this.props.name}
                onChange={e =>
                  this.props.changeProductDetails('name', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                options={this.props.facilities}
                placeholder="Select facility *"
                value={
                  this.props.facility_id &&
                  this.props.facilities.find(
                    f => f.value === this.props.facility_id
                  )
                }
                onChange={e =>
                  this.props.changeProductDetails('facility_id', e.value)
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                options={this.props.gls}
                placeholder="Select GL *"
                value={
                  this.props.gl_id &&
                  this.props.gls.find(f => f.value === this.props.gl_id)
                }
                onChange={e => {
                  this.props.changeProductDetails('gl_id', e.value)
                  this.props.changeProductDetails('gl_code', e.code)
                }}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.props.apply_tax}
                        onChange={e =>
                          this.props.changeProductDetails(
                            'apply_tax',
                            e.target.checked
                          )
                        }
                        name="apply_tax"
                      />
                    }
                    label="Apply tax on product (if applicable)"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ textAlign: 'center' }}
              spacing={4}
            >
              {this.props.gl_code &&
                (this.props.gl_code.slice(0, 2) === '01' ||
                  this.props.gl_code.slice(0, 2) === '11' ||
                  this.props.gl_code.slice(0, 2) === '02' ||
                  this.props.gl_code.slice(0, 2) === '03' ||
                  this.props.gl_code.slice(0, 2) === '12' ||
                  this.props.gl_code.slice(0, 2) === '13' ||
                  this.props.gl_code.slice(0, 2) === '15') && (
                  <Grid item xs={12}>
                    <TextField
                      label="Deposit price *"
                      fullWidth
                      variant="outlined"
                      value={this.props.deposit_price}
                      onChange={e =>
                        this.props.changeProductDetails(
                          'deposit_price',
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                )}
              {this.props.gl_code &&
                (this.props.gl_code.slice(0, 2) === '02' ||
                  this.props.gl_code.slice(0, 2) === '12' ||
                  this.props.gl_code.slice(0, 2) === '03' ||
                  this.props.gl_code.slice(0, 2) === '13') && (
                  <Grid item xs={12}>
                    <TextField
                      label="Early bird price *"
                      fullWidth
                      variant="outlined"
                      value={this.props.early_bird_price}
                      onChange={e =>
                        this.props.changeProductDetails(
                          'early_bird_price',
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                )}
              {this.props.gl_code &&
                (this.props.gl_code.slice(0, 2) === '02' ||
                  this.props.gl_code.slice(0, 2) === '03' ||
                  this.props.gl_code.slice(0, 2) === '12' ||
                  this.props.gl_code.slice(0, 2) === '13' ||
                  this.props.gl_code.slice(0, 2) === '14') && (
                  <Grid item xs={12}>
                    <TextField
                      label="Individual price *"
                      fullWidth
                      variant="outlined"
                      value={this.props.individual_price}
                      onChange={e =>
                        this.props.changeProductDetails(
                          'individual_price',
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                )}
              {this.props.gl_code &&
                (this.props.gl_code.slice(0, 2) === '01' ||
                  this.props.gl_code.slice(0, 2) === '11' ||
                  this.props.gl_code.slice(0, 2) === '02' ||
                  this.props.gl_code.slice(0, 2) === '03' ||
                  this.props.gl_code.slice(0, 2) === '12' ||
                  this.props.gl_code.slice(0, 2) === '13' ||
                  this.props.gl_code.slice(0, 2) === '15' ||
                  this.props.gl_code.slice(0, 2) === '04') && (
                  <Grid item xs={12}>
                    <TextField
                      label="Full price *"
                      fullWidth
                      variant="outlined"
                      value={this.props.full_price}
                      onChange={e =>
                        this.props.changeProductDetails(
                          'full_price',
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        }
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.product.new_product,
  facilities: state.common.filter_facilities.map(f => ({
    label: f.name,
    value: f.ID,
  })),
  gls: state.product.gls.map(f => ({
    label: `${f.code} (${f.product_type.name} - ${f.season.name})`,
    code: f.code,
    value: f.ID,
  })),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeProductDetails: (k, v) => dispatch(changeProductDetails(k, v)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeagueForm)

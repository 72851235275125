import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Select from 'react-select'

import { actions } from 'classes/redux'

const FeeForm = ({ changeContractDetails, products, new_product_id }) => {
  return (
    <>
      <Grid item xs={12}>
       <Select
        label="New product"
        variant="outlined"
        fullWidth
        options={products}
        placeholder="Select Product"
        value={new_product_id && products.find(p => p.value === new_product_id)}
        onChange={e => {
          changeContractDetails('new_product_id', e.value)
        }}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />
    </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  ...state.classes.form,
  fees: state.classes.fees.map(fee => ({
    label: `${fee.product.name} - ${fee.text.split('(')[0]} (${
      fee.product.individual_price
    })`,
    value: fee.id,
  })),
  products: state.product.newProducts.filter(p => {
    if (state.classes.form.newProduct){
      let gl = state.product.gls.find(f => f.ID === state.classes.form.newProduct.gl_id)
      if (gl)
        return p.gl.code.slice(0, 3) === gl.code.slice(0, 3)
    }
    return p.gl.code.slice(0, 2) === '14'
}).map(p => ({
    label: p.name,
    value: p.ID,
  })),
})

const mapDispatchToProps = dispatch => ({
  changeContractDetails: (k, v) =>
    dispatch(actions.updateForm({ key: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(FeeForm)

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Select from 'react-select'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'

import FeeTaxDialog from './FeeTaxDialog'

import { getFacilities } from './../../common/actions'
import {
  getGLs,
  createNewProduct as _createNewProduct,
  getFees,
  getTaxes,
} from './../actions'
import { getFieldTypes } from 'resources/actions'
import { Button } from '@material-ui/core'
import { actions } from 'product/redux'

const ProductForm = ({
  disabled,
  show_facilities,
  no_gl,
  no_duration,
  no_name,
  is_edit,
  no_exclude_facility,
}) => {
  const dispatch = useDispatch(),
    facilities = useSelector(state =>
      state.common.filter_facilities.map(f => ({
        label: f.name,
        value: f.ID,
      }))
    ),
    gls = useSelector(state =>
      state.product.gls.map(f => ({
        label: `${f.code} (${f.product_type.name} - ${f.season.name})`,
        code: f.code,
        value: f.ID,
      }))
    ),
    taxes = useSelector(state =>
      state.product.taxes.map(f => ({
        label: `${f.name} - ${f.value}%`,
        value: f.ID,
      }))
    ),
    fees = useSelector(state =>
      state.product.fees.map(f => ({
        label: `${f.name} - ${f.value}%`,
        value: f.ID,
      }))
    ),
    product = useSelector(state => state.product.new_product_details),
    field_types = useSelector(state =>
      state.resources.field_types.map(f => ({
        label: f.name,
        value: f.ID,
      }))
    ),
    gl_code = product?.gl_code?.slice(1, 3),
    updateProduct = (k, v) =>
      dispatch(actions.updateProduct({ key: k, value: v })),
    createNewProduct = b => dispatch(_createNewProduct(b, is_edit)),
    openFeeTax = type => {
      dispatch(actions.updateFeeTax({ key: 'type', value: type }))
      dispatch(actions.updateFeeTax({ key: 'open', value: true }))
    }
  useEffect(() => {
    dispatch(getFacilities())
    dispatch(getGLs())
    dispatch(getFees())
    dispatch(getTaxes())
    dispatch(getFieldTypes())
  }, [])
  return (
    <>
      <FeeTaxDialog />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        style={{ paddingTop: '2%', marginTop: 20 }}
        spacing={2}
      >
        <Grid item xs={7}>
          <TextField
            label="Name *"
            variant="outlined"
            value={product?.name}
            onChange={e => updateProduct('name', e.target.value)}
            fullWidth
            disabled={disabled}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        {show_facilities && (
          <Grid item xs={7}>
            <Select
              options={facilities}
              placeholder="Select facility *"
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              onChange={e => updateProduct('facility_id', e.value)}
            />
          </Grid>
        )}
        <Grid item xs={7}>
          <Select
            options={gls}
            placeholder="Select GL *"
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            onChange={e => {
              updateProduct('gl_id', e.value)
              updateProduct('gl_code', e.code)
            }}
            value={product?.gl_id && gls.find(g => g.value === product?.gl_id)}
            isDisabled={no_gl}
          />
        </Grid>
        {gl_code === '10' && (
          <Grid item xs={7}>
            <Select
              options={field_types}
              placeholder="Select field types *"
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              onChange={e =>
                updateProduct('field_type_ids', e ? e.map(v => v.value) : [])
              }
              value={
                product.field_type_ids?.length &&
                field_types.filter(f =>
                  product.field_type_ids.find(
                    field_type => field_type === f.value
                  )
                )
              }
              isMulti
            />
          </Grid>
        )}
        {!no_duration && gl_code === '10' && (
          <Grid item xs={7}>
            <TextField
              label="Duration in minutes *"
              fullWidth
              variant="outlined"
              onChange={e =>
                updateProduct('duration', parseInt(e.target.value))
              }
              value={product?.duration}
            />
          </Grid>
        )}
        {!no_duration && gl_code === '60' && (
          <Grid item xs={7}>
            <TextField
              label="Duration in days *"
              fullWidth
              variant="outlined"
              onChange={e =>
                updateProduct('duration', parseInt(e.target.value) * 86400)
              }
              value={product?.duration / 86400}
            />
          </Grid>
        )}
        {gl_code === '10' && (
          <Grid item xs={7}>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={product?.peak_off_peak}
                      onChange={e =>
                        updateProduct('peak_off_peak', e.target.checked)
                      }
                      name="peak_off_peak"
                    />
                  }
                  label="Use peak / off-peak pricing"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        )}
        {(gl_code === '10' ||
          gl_code === '40' ||
          gl_code === '41' ||
          gl_code === '42' ||
          gl_code === '14' ||
          gl_code === '60') &&
          !product.peak_off_peak && (
            <Grid item xs={7}>
              <TextField
                label="Base price *"
                fullWidth
                variant="outlined"
                onChange={e =>
                  updateProduct('base_price', parseFloat(e.target.value))
                }
                value={product?.base_price}
                type="number"
              />
            </Grid>
          )}
        {(gl_code === '40' || gl_code === '41' || gl_code === '42') && (
          <Grid item xs={7}>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={product?.apply_prorate}
                      onChange={e =>
                        updateProduct('apply_prorate', e.target.checked)
                      }
                      name="apply_prorate"
                    />
                  }
                  label="Apply prorate"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        )}
        {gl_code === '10' && product.peak_off_peak && (
          <Grid item xs={7}>
            <TextField
              label="Peak price *"
              fullWidth
              variant="outlined"
              onChange={e =>
                updateProduct('peak_price', parseFloat(e.target.value))
              }
              value={product?.peak_price}
              type="number"
            />
          </Grid>
        )}
        {gl_code === '10' && product.peak_off_peak && (
          <Grid item xs={7}>
            <TextField
              label="Off-peak price *"
              fullWidth
              variant="outlined"
              onChange={e =>
                updateProduct('off_peak_price', parseFloat(e.target.value))
              }
              value={product?.off_peak_price}
              type="number"
            />
          </Grid>
        )}
        {(gl_code === '20' || gl_code === '30') && (
          <Grid item xs={7}>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={product?.early_bird}
                      onChange={e =>
                        updateProduct('early_bird', e.target.checked)
                      }
                      name="apply_tax"
                    />
                  }
                  label="Use early bird pricing"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        )}
        {(gl_code === '20' || gl_code === '30') && product?.early_bird && (
          <Grid item xs={7}>
            <TextField
              label="Early bird price *"
              fullWidth
              variant="outlined"
              onChange={e =>
                updateProduct('early_bird_price', parseFloat(e.target.value))
              }
              value={product?.early_bird_price}
              type="number"
            />
          </Grid>
        )}
        {(gl_code === '20' || gl_code === '30') && (
          <Grid item xs={7}>
            <TextField
              label="Team price *"
              fullWidth
              variant="outlined"
              onChange={e =>
                updateProduct('team_price', parseFloat(e.target.value))
              }
              value={product?.team_price}
              type="number"
            />
          </Grid>
        )}
        {(gl_code === '20' || gl_code === '30') && (
          <Grid item xs={7}>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={product?.individual}
                      onChange={e =>
                        updateProduct('individual', e.target.checked)
                      }
                      name="apply_tax"
                    />
                  }
                  label="Allow individual registration"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        )}
        {(gl_code === '20' || gl_code === '30') && product?.individual && (
          <Grid item xs={7}>
            <TextField
              label="Individual price *"
              fullWidth
              variant="outlined"
              onChange={e =>
                updateProduct('individual_price', parseFloat(e.target.value))
              }
              value={product?.individual_price}
              type="number"
            />
          </Grid>
        )}
        {(gl_code === '20' || gl_code === '30') && (
          <Grid item xs={7}>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={product?.deposit}
                      onChange={e => updateProduct('deposit', e.target.checked)}
                      name="deposit"
                    />
                  }
                  label="Allow deposit"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        )}
        {(gl_code === '20' || gl_code === '30') && product?.deposit && (
          <Grid item xs={7}>
            <TextField
              label="Deposit price *"
              fullWidth
              variant="outlined"
              onChange={e =>
                updateProduct('deposit_price', parseFloat(e.target.value))
              }
              value={product?.deposit_price}
              type="number"
            />
          </Grid>
        )}
        <Grid item xs={7}>
          <Select
            options={fees}
            placeholder="Select fees *"
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            onChange={e =>
              updateProduct('fee_ids', e ? e.map(v => v.value) : [])
            }
            value={
              product.fee_ids?.length &&
              fees.filter(f => product.fee_ids.find(fee => fee === f.value))
            }
            isMulti
          />
        </Grid>
        <Grid item xs={1}>
          <Button onClick={() => openFeeTax('fee')}>Add</Button>
        </Grid>
        <Grid item xs={7}>
          <Select
            options={taxes}
            placeholder="Select taxes *"
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            onChange={e =>
              updateProduct('tax_ids', e ? e.map(v => v.value) : [])
            }
            value={
              product.tax_ids?.length &&
              taxes.filter(f => product.tax_ids.find(tax => tax === f.value))
            }
            isMulti
          />
        </Grid>
        {!no_exclude_facility && (
          <Grid item xs={7}>
            <p>Not active In:</p>
            <Select
              options={facilities}
              placeholder="Excluded facilities..."
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              onChange={e =>
                updateProduct(
                  'excluded_facilities',
                  e ? e.map(v => v.value) : []
                )
              }
              value={
                product.excluded_facilities?.length &&
                facilities.filter(f =>
                  product.excluded_facilities.find(tax => tax === f.value)
                )
              }
              isMulti
            />
          </Grid>
        )}
        <Grid item xs={7}>
          <Button
            variant="outlined"
            color="primary"
            component="span"
            onClick={() => createNewProduct(product)}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ProductForm

import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import EditIcon from '@material-ui/icons/Edit'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'

import { Button } from '@material-ui/core'

import NewFieldTypeDialog from 'resources/components/NewFieldTypeDialog'
import { actions } from 'resources/redux'
import { getFieldTypes } from 'resources/actions'

const sortIcon = <ArrowDownward />

const columns = setFieldType =>
  memoize(() => [
    {
      name: 'ID',
      selector: 'id',
      center: true,
      cell: field_type => field_type.ID,
    },
    {
      name: 'Name',
      selector: 'name',
      center: true,
      cell: field_type => field_type.name,
    },
    {
      name: 'Capacity',
      selector: 'capacity',
      center: true,
      cell: field_type => field_type.capacity,
    },
    {
      name: 'Location',
      selector: 'is_outdoors',
      center: true,
      cell: field_type => (field_type.is_outdoors ? 'Outdoors' : 'Indoors'),
    },
    {
      name: 'Available Online',
      selector: 'available_online',
      center: true,
      cell: field_type => (field_type.available_online ? 'Yes' : 'No'),
    },
    {
      name: 'Actions',
      selector: 'actions',
      center: true,
      cell: field_type => (
        <>
          <IconButton onClick={() => setFieldType(field_type)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </>
      ),
    },
  ])

const ResourceManager = ({ resource_type, getFieldTypes }) => {
  const field_types = useSelector(state => state.resources.field_types),
    dispatch = useDispatch(),
    openDialog = () =>
      dispatch(
        actions.updateFieldType({ key: 'open', value: true, reset: true })
      ),
    setFieldType = f => {
      dispatch(actions.updateFieldType({ key: 'id', value: f.ID }))
      dispatch(actions.updateFieldType({ key: 'name', value: f.name }))
      dispatch(actions.updateFieldType({ key: 'capacity', value: f.capacity }))
      dispatch(
        actions.updateFieldType({ key: 'is_outdoors', value: f.is_outdoors })
      )
      dispatch(
        actions.updateFieldType({ key: 'available_online', value: f.available_online })
      )
      dispatch(actions.updateFieldType({ key: 'open', value: true }))
    }
  useEffect(() => {
    getFieldTypes()
  }, [resource_type])
  return (
    <div>
      <NewFieldTypeDialog />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Button onClick={openDialog} variant="outlined">
            Add new
          </Button>
          <Paper elevation={0}>
            <DataTable
              columns={columns(setFieldType)()}
              data={field_types}
              highlightOnHover
              defaultSortField="ID"
              defaultSortAsc={true}
              sortIcon={sortIcon}
              fixedHeader
              customStyles={{
                rows: {
                  style: {
                    textAlign: 'center',
                  },
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = state => ({
  ...state.resources,
})

const mapDispatchToProps = dispatch => ({
  getFieldTypes: () => dispatch(getFieldTypes()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceManager)

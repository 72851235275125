import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'
import Button from '@material-ui/core/Button'

import { DateRangePicker } from 'react-dates'

import {
  getInvoiceReport,
  getSalesReport,
  getEventsReport,
  getHealthReport,
  getOutstandingInvoiceReport,
  getReconciliations,
  getTeamsReport,
  getDailyReport,
  getTrials,
} from './../actions'
import { payment_options } from './../../consts'
import { actions } from './../redux'
import { unixToDateTime, momentDateTimeSecondsToUnix } from '../../helper'

var _ = require('lodash')

const styles = theme => ({
    paper: {
      maxHeight: 650,
      overflow: 'scroll',
    },
  }),
  reporting_types = [
    { label: 'Cash basis', value: 'cash' },
    { label: 'Activity basis', value: 'invoice' },
  ],
  yes_no = [
    { label: 'Active: Yes', value: 1 },
    { label: 'Active: No', value: 0 },
  ]

class Filters extends React.Component {
  constructor(props) {
    super(props)
    this.state = { focusedInput: null }
  }

  componentDidMount() {
    if (this.props.run && this.props.user_facility_id?.length) {
      this.props.updateFilter('facility_id', this.props.user_facility_id[0])
    } else {
      this.props.updateFilter('facility_id', this.props.user_facility_id)
    }
    if (this.props.league_only) {
      this.props.updateFilter('start_date', 0)
      this.props.updateFilter('end_date', 0)
    }
    if (this.props.run) {
      const today4am = moment().hours(4).minutes(0).seconds(0)
      this.props.updateFilter(
        'start_date',
        momentDateTimeSecondsToUnix(today4am.clone().subtract(1, 'd'))
      )
      this.props.updateFilter('end_date', momentDateTimeSecondsToUnix(today4am))
    }
  }

  render() {
    let search = null
    if (this.props.report_type === 'cash') {
      search = this.props.getSalesReport
    }
    if (this.props.report_type === 'invoice') {
      search = this.props.getInvoiceReport
    }
    if (this.props.report_type === 'events') {
      search = this.props.getEventsReport
    }
    if (this.props.report_type === 'health') {
      search = this.props.getHealthReport
    }
    if (this.props.report_type === 'outstanding') {
      search = this.props.getOutstandingInvoiceReport
    }
    if (this.props.report_type === 'teams') {
      search = this.props.getTeamsReport
    }
    if (this.props.report_type === 'reconciliation') {
      search = this.props.getReconciliations
    }
    if (this.props.report_type === 'daily') {
      search = this.props.getDailyReport
    }
    if (this.props.report_type === 'trials') {
      search = this.props.getTrials
    }
    return (
      <Grid container spacing={2} alignItems="center" justify="flex-start">
        {this.props.report_dropdown && (
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={reporting_types}
              placeholder="Reporting type"
              value={
                this.props.report_type &&
                reporting_types.find(p => p.value === this.props.report_type)
              }
              onChange={v => this.props.changeReportType(v.value)}
            />
          </Grid>
        )}
        {!this.props.hide_date && (
          <Grid item xs={3}>
            <DateRangePicker
              small
              isOutsideRange={date => {
                if (this.props.is_mod && this.props.report_type !== 'trials') {
                  return moment().subtract(30, 'd').diff(date) > 0
                }
                return false
              }}
              startDate={
                this.props.start_date
                  ? unixToDateTime(this.props.start_date)
                  : null
              }
              startDateId="your_unique_start_date_id"
              endDate={
                this.props.end_date ? unixToDateTime(this.props.end_date) : null
              }
              endDateId="your_unique_end_date_id"
              onDatesChange={({ startDate, endDate }) => {
                if (!startDate && !endDate) {
                  // dates cleared
                  this.props.updateFilter('start_date', null)
                  this.props.updateFilter('end_date', null)
                }
                if (startDate) {
                  this.props.updateFilter(
                    'start_date',
                    momentDateTimeSecondsToUnix(
                      startDate.hour(0).minute(0).second(0)
                    )
                  )
                }
                if (endDate) {
                  this.props.updateFilter(
                    'end_date',
                    momentDateTimeSecondsToUnix(
                      endDate.hours(23).minutes(59).seconds(59)
                    )
                  )
                }
              }}
              focusedInput={this.state.focusedInput}
              onFocusChange={focusedInput => this.setState({ focusedInput })}
              minimumNights={0}
              showClearDates={true}
              disabled={
                this.props.is_mod && this.props.report_type !== 'health'
                  && this.props.report_type !== 'trials'
              }
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
          </Grid>
        )}
        {!this.props.hide_facility && (
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={_.sortBy(this.props.facilities, ['id'])}
              placeholder="Facility"
              isClearable={!this.props.run}
              isDisabled={!this.props.is_admin}
              isMulti={!this.props.run}
              value={
                this.props.run
                  ? this.props.facilities.find(
                      p => this.props.facility_id === p.value
                    )
                  : this.props.facility_id.length &&
                    this.props.facilities.filter(
                      p => this.props.facility_id.indexOf(p.value) !== -1
                    )
              }
              onChange={v => {
                if (this.props.run) {
                  this.props.updateFilter('facility_id', v.value)
                } else {
                  this.props.updateFilter(
                    'facility_id',
                    v ? v.map(f => f.value) : []
                  )
                }
              }}
            />
          </Grid>
        )}
        {!this.props.hide_event && (
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={_.sortBy(
                this.props.product_types.filter(p => {
                  if (this.props.league_only) {
                    return p.label.toLowerCase().indexOf('league') !== -1
                  }
                  if (this.props.class_only) {
                    return [10, 17].includes(p.ID);
                  }
                  return true
                }),
                'ranking'
              )}
              placeholder="Event type"
              isClearable={true}
              isMulti
              value={
                this.props.product_type_id.length &&
                this.props.product_types.filter(
                  p => this.props.product_type_id.indexOf(p.ID) !== -1
                )
              }
              onChange={v =>
                this.props.updateFilter(
                  'product_type_id',
                  v ? v.map(f => f.ID) : []
                  )
              }
            />
          </Grid>
        )}
        {!this.props.hide_payment && (
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={payment_options}
              placeholder="Payment type"
              isMulti
              value={
                this.props.payment_type.length &&
                payment_options.filter(
                  p => this.props.payment_type.indexOf(p.value) !== -1
                )
              }
              onChange={v =>
                this.props.updateFilter(
                  'payment_type',
                  v ? v.map(f => f.value) : []
                )
              }
              isClearable={true}
            />
          </Grid>
        )}
        {this.props.show_active && (
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={yes_no}
              placeholder="Active"
              value={yes_no.find(p => p.value === this.props.active)}
              onChange={v => this.props.updateFilter('active', v && v.value)}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
        )}
        {this.props.league_only && (
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <Select
              options={this.props.leagues}
              placeholder="League"
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              isMulti
              isClearable={true}
              value={
                this.props.league_id.length &&
                this.props.leagues.filter(
                  p => this.props.league_id.indexOf(p.value) !== -1
                )
              }
              onChange={v =>
                this.props.updateFilter(
                  'league_id',
                  v ? v.map(f => f.value) : []
                )
              }
            />
          </Grid>
        )}
        <Grid item xs={1}>
          <Button
            className="save-btn"
            style={{ width: '100%' }}
            onClick={() => search()}
          >
            {this.props.run ? 'Run' : 'Search'}
          </Button>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.reporting.filter,
  facilities: state.common.filter_facilities,
  product_types: state.common.product_types.map(p => ({
    label: p.name,
    value: p.ID,
    ID: p.ID,
    ranking: p.ranking,
  })),
  report_type: state.reporting.report_type,
  user_facility_id: ownProps.run
    ? [state.user.facility_id]
    : state.user.user_type === 'admin'
    ? []
    : [state.user.facility_id],
  is_admin: state.user.user_type === 'admin',
  is_mod: state.user.user_type === 'mod',
  leagues: state.reporting.teams_leagues,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateFilter: (k, v) =>
    dispatch(actions.updateFilter({ field: k, value: v })),
  changeReportType: report => dispatch(actions.changeReportType(report)),
  getInvoiceReport: () => dispatch(getInvoiceReport()),
  getSalesReport: () => dispatch(getSalesReport()),
  getEventsReport: () => dispatch(getEventsReport()),
  getHealthReport: () => dispatch(getHealthReport()),
  getOutstandingInvoiceReport: () => dispatch(getOutstandingInvoiceReport()),
  getReconciliations: () => dispatch(getReconciliations()),
  getTeamsReport: () => dispatch(getTeamsReport()),
  getDailyReport: () => dispatch(getDailyReport()),
  getDailyReport: () => dispatch(getDailyReport()),
  getTrials: () => dispatch(getTrials()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Filters))

import { ERP_BACKEND_URL } from './../consts'
import axios from 'axios'
import { changeLoading, dispatchError } from './../common/actions'
import { productSlice } from './redux'

var _ = require('lodash')

const _getProducts = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/product`)
    .then(resp => {
      dispatch(
        productSlice.actions.getAllProducts({
          products: _.sortBy(resp.data, 'name'),
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getProducts = () => (dispatch, getState) => {
  dispatch(_getProducts())
}

export const getNewProducts = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/newproduct`)
    .then(resp => {
      dispatch(
        productSlice.actions.getAllNewProducts({
          products: resp.data.reverse(),
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getNewProductByID = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/newproduct/${id}`)
    .then(resp => {
      dispatch(
        productSlice.actions.setProduct({
          product: {
            ...resp.data,
            gl_code: resp.data?.gl?.code,
            fee_ids: resp.data?.fees?.map(f => f.product_fee_id),
            tax_ids: resp.data?.taxes?.map(f => f.product_tax_id),
            peak_off_peak: resp.data?.peak_price > 0,
            early_bird: resp.data?.early_bird_price > 0,
            individual: resp.data?.individual_price > 0,
            deposit: resp.data?.deposit_price > 0,
            field_type_ids: resp.data?.field_types?.map(f => f.field_type_id),
          },
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getGLs = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/gl`)
    .then(resp => {
      dispatch(
        productSlice.actions.getAllGLs({
          gls: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const editQuery = query => (dispatch, getState) =>
  dispatch(productSlice.actions.editSearchQuery({ query }))

export const changeProductDetails = (field, value) => (dispatch, getState) => {
  dispatch(
    productSlice.actions.changeProductDetails({
      field,
      value,
    })
  )
}

export const setEditProductDetails = () => (dispatch, getState) => {
  dispatch(productSlice.actions.openProductEditModal())
}

export const resetProductDetails = () => (dispatch, getState) => {
  dispatch(productSlice.actions.resetProductDetails())
}

export const setProductDetails = details => (dispatch, getState) => {
  dispatch(productSlice.actions.setProductDetails({ details }))
}

export const toggleModal = open => (dispatch, getState) => {
  dispatch(productSlice.actions.toggleModal({ open }))
}

export const createProducts = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const new_product = getState().product.new_product
  let body = {
    name: new_product.name,
    facility_id: new_product.facility_id,
    gl_id: new_product.gl_id,
    apply_tax: new_product.apply_tax,
  }
  if (
    new_product.gl_code.slice(0, 2) === '01' ||
    new_product.gl_code.slice(0, 2) === '11' ||
    new_product.gl_code.slice(0, 2) === '15' ||
    new_product.gl_code.slice(0, 2) === '04'
  ) {
    // Rental
    body.deposit_price = parseFloat(new_product.deposit_price)
    body.full_price = parseFloat(new_product.full_price)
  }
  if (
    new_product.gl_code.slice(0, 2) === '02' ||
    new_product.gl_code.slice(0, 2) === '03' ||
    new_product.gl_code.slice(0, 2) === '12' ||
    new_product.gl_code.slice(0, 2) === '13'
  ) {
    // League
    body.deposit_price = parseFloat(new_product.deposit_price)
    body.individual_price = parseFloat(new_product.individual_price)
    body.early_bird_price = parseFloat(new_product.early_bird_price)
    body.full_price = parseFloat(new_product.full_price)
  }
  if (new_product.gl_code.slice(0, 2) === '14') {
    // Class
    body.individual_price = parseFloat(new_product.individual_price)
  }
  return axios
    .post(ERP_BACKEND_URL + `/product`, body)
    .then(resp => {
      dispatch(_getProducts())
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const createNewProduct = (_body, is_edit) => (dispatch, getState) => {
  const current_product_id = getState().product.new_product_details?.ID,
    facility_setting = getState().product.new_product_details?.facility_id,
    is_product_settings = current_product_id && facility_setting && !is_edit
  let url = '/newproduct',
    body = { ..._body }
  if (is_product_settings) {
    url = '/newproduct/settings'
    body.new_product_id = current_product_id
    body.facility_id = parseInt(facility_setting)
  }
  delete body.fees
  delete body.taxes
  delete body.gl
  delete body.variations
  delete body.ID
  delete body.field_types
  let p = null
  if (is_edit) {
    p = axios.put(ERP_BACKEND_URL + `/newproduct/${current_product_id}`, body)
  } else {
    p = axios.post(ERP_BACKEND_URL + url, body)
  }
  return p
    .then(resp => {
      let promises = []
      body.tax_ids.forEach(t =>
        promises.push(
          axios.post(ERP_BACKEND_URL + `/newproduct/tax`, {
            [is_product_settings ? 'product_settings_id' : 'new_product_id']:
              resp.data.ID || current_product_id,
            product_tax_id: t,
          })
        )
      )
      body.fee_ids.forEach(t =>
        promises.push(
          axios.post(ERP_BACKEND_URL + `/newproduct/fee`, {
            [is_product_settings ? 'product_settings_id' : 'new_product_id']:
              resp.data.ID || current_product_id,
            product_fee_id: t,
          })
        )
      )
      body.field_type_ids.forEach(f =>
        promises.push(
          axios.post(ERP_BACKEND_URL + `/newproduct/fieldtype`, {
            new_product_id: resp.data.ID || current_product_id,
            field_type_id: f,
          })
        )
      )
      return Promise.all(promises)
    })
    .then(() => {
      dispatch(
        productSlice.actions.updateProduct({ key: 'dialog_open', value: false })
      )
      if (!is_product_settings && !is_edit) return dispatch(getNewProducts())
      window.location.reload()
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const updateProducts = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const new_product = getState().product.new_product
  let body = {
    name: new_product.name,
    facility_id: new_product.facility_id,
    product_type_id: new_product.gl.product_type.ID,
    season_id: new_product.season_id,
    apply_tax: new_product.apply_tax,
  }
  if (
    new_product.gl_code.slice(0, 2) === '01' ||
    new_product.gl_code.slice(0, 2) === '11' ||
    new_product.gl_code.slice(0, 2) === '15' ||
    new_product.gl_code.slice(0, 2) === '04'
  ) {
    body.deposit_price = parseFloat(new_product.deposit_price)
    body.full_price = parseFloat(new_product.full_price)
  }
  if (
    new_product.gl_code.slice(0, 2) === '02' ||
    new_product.gl_code.slice(0, 2) === '03' ||
    new_product.gl_code.slice(0, 2) === '12' ||
    new_product.gl_code.slice(0, 2) === '13'
  ) {
    body.deposit_price = parseFloat(new_product.deposit_price)
    body.individual_price = parseFloat(new_product.individual_price)
    body.early_bird_price = parseFloat(new_product.early_bird_price)
    body.full_price = parseFloat(new_product.full_price)
  }
  if (new_product.gl_code.slice(0, 2) === '14') {
    // Class
    body.individual_price = parseFloat(new_product.individual_price)
  }
  return axios
    .put(ERP_BACKEND_URL + `/product/` + new_product.ID, body)
    .then(resp => {
      dispatch(_getProducts())
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const deleteProduct = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .delete(ERP_BACKEND_URL + `/product/` + id)
    .then(resp => {
      dispatch(_getProducts())
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getFees = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/fees`)
    .then(resp => {
      dispatch(
        productSlice.actions.updateState({ key: 'fees', value: resp.data })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getTaxes = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/taxes`)
    .then(resp => {
      dispatch(
        productSlice.actions.updateState({ key: 'taxes', value: resp.data })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const saveFeeTax = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { type, name, value } = getState().product.fee_tax_modal,
    is_tax = type === 'tax',
    url = is_tax ? '/tax' : '/fee'
  return axios
    .post(ERP_BACKEND_URL + url, { name, value: parseFloat(value) })
    .then(resp => {
      if (is_tax) {
        dispatch(getTaxes())
      } else {
        dispatch(getFees())
      }
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const deleteNewProduct = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .delete(ERP_BACKEND_URL + `/newproduct/${id}`)
    .then(() => {
      window.location.reload()
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const deleteProductVariation = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .delete(ERP_BACKEND_URL + `/newproduct/settings/${id}`)
    .then(() => {
      window.location.reload()
    })
    .catch(e => dispatch(dispatchError(e)))
}

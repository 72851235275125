import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { applyFilter } from './../helper'

import Grid from '@material-ui/core/Grid'

import AddProductDialog from 'product/components/AddProductDialog'
import ProductTable from 'product/components/NewProductTable'
import Filters from 'product/components/NewFilters'

import {
  getNewProducts,
  createNewProduct as _createNewProduct,
} from './../actions'

const Manager = () => {
  const dispatch = useDispatch(),
    products = useSelector(state =>
      applyFilter(state.product.newProducts, state.product.filter)
    ),
    facilities = useSelector(state =>
      state.common.facilities.map(f => ({
        label: f.name,
        value: f.ID,
        nickname: f.nickname,
      }))
    )
  useEffect(() => {
    dispatch(getNewProducts())
  }, [])
  return (
    <div>
      <AddProductDialog />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12}>
          <Filters />
        </Grid>
        <Grid item xs={12}>
          <ProductTable products={products} facilities={facilities} />
        </Grid>
      </Grid>
    </div>
  )
}

export default Manager

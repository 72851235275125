import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import NewFacilityDialog from 'resources/components/NewFacilityDialog'
import { Button } from '@material-ui/core'

import { actions } from 'resources/redux'
import { getIntegrations } from 'resources/actions'
import NewIntegrationDialog from 'resources/components/NewIntegrationDialog'

const sortIcon = <ArrowDownward />

const columns = setIntegration =>
  memoize(() => [
    {
      name: 'ID',
      selector: 'id',
      center: true,
      cell: integration => integration.ID,
    },
    {
      name: 'Name',
      selector: 'name',
      center: true,
      cell: integration => integration.name,
    },
    {
      name: 'Facility',
      selector: 'facility',
      center: true,
      cell: integration =>
        integration.facility_id > 0 ? integration.facility.nickname : '',
    },
    {
      name: 'Data',
      selector: 'data',
      center: true,
      cell: integration => (<p>${JSON.stringify(integration.data, null, 2)}</p>),
    },
    {
      name: 'Actions',
      selector: 'actions',
      center: true,
      cell: integration => (
        <>
          <IconButton onClick={() => setIntegration(integration)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </>
      ),
    },
  ])

const ResourceManager = () => {
  const integrations = useSelector(state => state.resources.integrations),
    dispatch = useDispatch(),
    openDialog = () =>
      dispatch(
        actions.updateIntegration({ key: 'open', value: true, reset: true })
      ),
    setIntegration = f => {
      dispatch(actions.updateIntegration({ key: 'name', value: f.name }))
      dispatch(actions.updateIntegration({ key: 'data', value: f.data }))
      dispatch(actions.updateIntegration({ key: 'type', value: f.type }))
      dispatch(actions.updateIntegration({ key: 'id', value: f.ID }))
      dispatch(actions.updateIntegration({ key: 'facility_id', value: f.facility_id }))
      dispatch(actions.updateIntegration({ key: 'open', value: true }))
    }
  useEffect(() => {
    dispatch(getIntegrations())
  }, [])
  return (
    <div>
      <NewIntegrationDialog />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Button onClick={openDialog} variant="outlined">
            Add new
          </Button>
          <Paper elevation={0}>
            <DataTable
              columns={columns(setIntegration)()}
              data={integrations}
              highlightOnHover
              defaultSortField="ID"
              defaultSortAsc={true}
              sortIcon={sortIcon}
              fixedHeader
              customStyles={{
                rows: {
                  style: {
                    textAlign: 'center',
                  },
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.resources,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceManager)

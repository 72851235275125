import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Select from 'react-select'

import { actions } from 'classes/redux'
const size_options = [
  {label:'XXS'},
  {label:'XS'},
  {label:'S'},
  {label:'M'},
  {label:'L'},
  {label:'XL'},
]
const UniformSizeForm = ({ updateAttendee, uniform_size }) => {
  console.log("UniformSizeForm", uniform_size)
  return (
    <>
      <Grid item xs={12}>
       <Select
        label="Uniform Size"
        variant="outlined"
        fullWidth
        options={size_options}
        placeholder="Set uniform size"
        value={uniform_size &&
            size_options.find(f => f.label === uniform_size)}
        onChange={e => {
          updateAttendee('uniform_size', e.label)
        }}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />
    </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  ...state.classes.attendee,
})

const mapDispatchToProps = dispatch => ({
  updateAttendee: (k, v) =>
    dispatch(actions.updateAttendee({ key: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(UniformSizeForm)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { routes } from './consts'
import axios from 'axios'

import api from 'api'

import AppWrapper from './common/components/AppWrapper'
import AdminRoute from './common/components/AdminRoute'
import Loading from 'react-fullscreen-loading'

// import Home from './home/screens/Home'
import AdminPanel from './home/screens/Admin'
import Login from './user/screens/Login'
import Signup from './user/screens/Signup'
import Forgot from './user/screens/Forgot'
import Scoreboards from './scoreboard/screens/Scoreboards'
import Resources from './resources/screens/MainManage'
import Calendar from './calendar/screens/Calendar'
import Event from './calendar/screens/Details'
import CustomerSearch from './customer/screens/Search'
import CustomerProfile from './customer/screens/Profile'
import Products from './product/screens/Manage'
import Leagues from './league/screens/Manage'
import League from './league/screens/Details'
import Tournament from './league/screens/TournamentDetails'
import Teams from './teams/screens/Manage'
import Team from './teams/screens/Details'
import Invoices from './invoice/screens/Manage'
import InvoiceDetails from './invoice/screens/Details'
import ReportingDisplay from './reporting/screens/Display'
import SalesReport from './reporting/screens/Report'
import EventsReport from './reporting/screens/Events'
import HealthReport from './reporting/screens/Health'
import DailyReport from './reporting/screens/Daily'
import OutstandingReport from './reporting/screens/Outstanding'
import TeamsReport from './reporting/screens/Teams'
import ReconciliationReport from './reporting/screens/Reconciliation'
import CamerasReport from './reporting/reports/Cameras'
import TrialsReport from './reporting/screens/Trials'
import ContractManager from './contract/screens/Manage'
import ContractDetails from './contract/screens/Details'
import Discount from './discount/screens/Manage'
import Soccernaut from './soccernaut/screens/Manage'
import Staff from './staff/screens/Manage'
import PickupManager from './pickup/screens/Manage'
import Referee from './referee/screens/Manage'
import Classes from './classes/screens/Search'
import ClassDetails from './classes/screens/Details'
import Medias from './media/screens/Manage'
import DownloadRefApp from './common/components/DownloadRefApp'
import NewProductManager from 'product/screens/NewManage'
import ProductDetails from 'product/screens/ProductDetails'

import './css/index.css'
import './css/react_dates_overrides.css'

import { checkLogin } from 'user/actions'
import { actions as userActions } from 'user/redux'
import { colors } from 'consts'

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
  'auth_token'
)}`
api.setToken(localStorage.getItem('auth_token'))

console.log('ENV', process.env.REACT_APP_ENV)

class App extends Component {
  // eslint-disable-next-line react/no-deprecated -- TODO: refactor this logic with react-router
  componentWillMount() {
    if (this.props.pathname === '/login') return this.props.appReady()
    if (this.props.pathname === '/signup') return this.props.appReady()
    if (this.props.pathname === '/forgot') return this.props.appReady()
    this.props.checkLogin()
  }

  render() {
    if (this.props.is_not_hydra) {
      return (
        <React.Fragment>
          <AppWrapper>
            <div className="wrapper">
              <div className="container">
                <Switch>
                  <Route {...routes.HOME}>
                    <Redirect to={routes.CALENDAR.CAL.path} />
                  </Route>
                  {this.props.is_referee && (
                    <Route
                      {...routes.CALENDAR.CAL}
                      component={DownloadRefApp}
                    />
                  )}
                  <Route {...routes.LOGIN} component={Login} />
                  <Route {...routes.SIGNUP} component={Signup} />
                  <Route {...routes.FORGOT} component={Forgot} />
                </Switch>
              </div>
            </div>
          </AppWrapper>
        </React.Fragment>
      )
    }

    if (!this.props.app_ready) {
      return (
        <React.Fragment>
          <AppWrapper>
            <div className="wrapper">
              <div className="container">
                <Loading
                  loading
                  background="#ececec"
                  loaderColor={colors.green_strong}
                />
              </div>
            </div>
          </AppWrapper>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <AppWrapper>
          <div className="wrapper">
            <div className="container">
              {!this.props.app_ready ? (
                <Loading
                  loading
                  background="#ececec"
                  loaderColor={colors.green_strong}
                />
              ) : (
                <Switch>
                  <Route {...routes.HOME}>
                    <Redirect to={routes.CALENDAR.CAL.path} />
                  </Route>
                  <Route {...routes.ADMIN} component={AdminPanel} />
                  <Route {...routes.LOGIN} component={Login} />
                  <Route {...routes.SIGNUP} component={Signup} />
                  <Route {...routes.FORGOT} component={Forgot} />
                  <Route {...routes.SCOREBOARDS} component={Scoreboards} />
                  <Route {...routes.RESOURCES} component={Resources} />
                  <Route {...routes.CALENDAR.CAL} component={Calendar} />
                  <Route {...routes.CALENDAR.EVENT} component={Event} />
                  <Route
                    {...routes.CUSTOMER.SEARCH}
                    component={CustomerSearch}
                  />
                  <Route
                    {...routes.CUSTOMER.PROFILE}
                    component={CustomerProfile}
                  />
                  <AdminRoute {...routes.PRODUCTS} component={Products} />
                  <AdminRoute
                    path="/newproduct"
                    exact
                    component={NewProductManager}
                  />
                  <AdminRoute
                    path="/newproduct/:id"
                    exact
                    component={ProductDetails}
                  />
                  <Route {...routes.DISCOUNTS} component={Discount} />
                  <Route {...routes.LEAGUES.LIST} component={Leagues} />
                  <Route {...routes.LEAGUES.DETAILS} component={League} />
                  <Route
                    {...routes.LEAGUES.TOURNAMENT_DETAILS}
                    component={Tournament}
                  />
                  <Route {...routes.TEAMS.LIST} component={Teams} />
                  <Route {...routes.TEAMS.DETAILS} component={Team} />
                  <AdminRoute {...routes.INVOICE.LIST} component={Invoices} />
                  <Route
                    {...routes.INVOICE.DETAILS}
                    component={InvoiceDetails}
                  />
                  <Route
                    {...routes.REPORTING.HOME}
                    component={ReportingDisplay}
                  />
                  <Route {...routes.REPORTING.SALES} component={SalesReport} />
                  <Route
                    {...routes.REPORTING.EVENTS}
                    component={EventsReport}
                  />
                  <Route
                    {...routes.REPORTING.PAYMENTS}
                    component={ReconciliationReport}
                  />
                  <Route
                    {...routes.REPORTING.HEALTH}
                    component={HealthReport}
                  />
                  <Route {...routes.REPORTING.DAILY} component={DailyReport} />
                  <Route
                    {...routes.REPORTING.OUTSTANDING}
                    component={OutstandingReport}
                  />
                  <Route {...routes.REPORTING.TEAMS} component={TeamsReport} />
                  <Route
                    {...routes.REPORTING.CAMERAS}
                    component={CamerasReport}
                  />
                   <Route
                    {...routes.REPORTING.TRIALS}
                    component={TrialsReport}
                  />
                  <Route
                    {...routes.CONTRACT.LIST}
                    component={ContractManager}
                  />
                  <Route
                    {...routes.CONTRACT.DETAILS}
                    component={ContractDetails}
                  />
                  <Route {...routes.SOCCERNAUT} component={Soccernaut} />
                  <AdminRoute
                    allow_managers
                    {...routes.STAFF}
                    component={Staff}
                  />
                  <Route {...routes.PICKUP.LIST} component={PickupManager} />
                  <Route {...routes.REFEREE.HOME} component={Referee} />
                  <Route {...routes.CLASSES.LIST} component={Classes} />
                  <Route {...routes.CLASSES.DETAILS} component={ClassDetails} />
                  <Route {...routes.MEDIA.LIST} component={Medias} />
                </Switch>
              )}
            </div>
          </div>
        </AppWrapper>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  is_not_hydra:
    state.user.user_type === 'coach' || state.user.user_type === 'referee',
  is_referee: state.user.user_type === 'referee',
  app_ready: state.user.app_ready,
})

const mapDispatchToProps = dispatch => ({
  updateFacilityID: facility_id =>
    dispatch(userActions.changeFacility({ facility_id })),
  setUserType: user_type => dispatch(userActions.changeUserType({ user_type })),
  checkLogin: () => dispatch(checkLogin()),
  appReady: () => dispatch(userActions.changeAppState({ ready: true })),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))


import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import ClassDetails from 'classes/components/forms/ClassDetails'

import { cloneClass } from 'classes/actions'
import { getNewProducts } from '../../product/actions'
import { actions } from 'classes/redux'

const CloneClassDialog = ({ cloneClass, handleClose, open, getNewProducts }) => {
  useEffect(() => {
    getNewProducts()
  }, [getNewProducts])
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      disableBackdropClick
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Clone class
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ClassDetails />
      </DialogContent>
      <DialogActions>
        <Button onClick={cloneClass}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => ({
  open: state.classes.clone_open,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.updateClone({ open: false })),
  cloneClass: () => dispatch(cloneClass()),
  getNewProducts: () => dispatch(getNewProducts()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CloneClassDialog)

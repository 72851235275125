import { ERP_BACKEND_URL } from './../consts'
import axios from 'axios'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { changeLoading, dispatchError } from './../common/actions'

import { leagueSlice } from './redux'
import { calendarSlice } from 'calendar/redux'
import { getFields } from 'calendar/actions'

import {
  momentDateToUnix,
  momentDateTimeToUnix,
  shuffleArray,
} from './../helper'

const buildLeaguesURL = filters => {
  let url = `/league?active=${filters.active ? filters.active : 0}`
  if (filters.facility_id && filters.is_admin) {
    if (typeof filters.facility_id === 'number') {
      url += `&facility_ids=${filters.facility_id}`
    } else {
      url += `&facility_ids=${filters.facility_id.map(f => f.value).join(',')}`
    }
  }
  if (filters.facility_id && !filters.is_admin) {
    url += `&facility_ids=${filters.facility_id}`
  }
  if (filters.product_type_id) {
    url += `&product_type_id=${filters.product_type_id}`
  }
  if (filters.start_date) {
    url += `&start=${filters.start_date}`
  }
  if (filters.end_date) {
    url += `&end=${filters.end_date}`
  }
  if (filters.hide_paid) {
    url += `&hide_paid=1`
  }
  if (filters.is_tournament) {
    url += `&is_tournament=1`
  }
  return url
}

const getLeaguesByFacility =
  (facility_id, is_tournament) => (dispatch, getState) => {
    dispatch(changeLoading(true))
    const filters = getState().league.filter,
      is_admin = getState().user.is_admin,
      url = buildLeaguesURL({
        ...filters,
        facility_id,
        is_admin,
        is_tournament,
      })
    return axios
      .get(ERP_BACKEND_URL + url)
      .then(resp => {
        dispatch(
          leagueSlice.actions.getAllLeagues({
            leagues: resp.data,
          })
        )
        dispatch(changeLoading(false))
      })
      .catch(e => dispatch(dispatchError(e)))
  }

const getLeagues = _filters => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { facility_id, user_type } = getState().user
  dispatch(getTournaments(_filters))
  if (user_type !== 'admin') {
    return dispatch(getLeaguesByFacility(facility_id))
  }
  let filters = {
    ...getState().league.filter,
    is_admin: getState().user.is_admin,
  }
  _filters &&
    Object.keys(_filters).map(key => {
      dispatch(
        leagueSlice.actions.changeFilterDetails({
          key,
          value: _filters[key],
        })
      )
      filters[key] = _filters[key]
      return key
    })
  const url = buildLeaguesURL(filters)
  return axios
    .get(ERP_BACKEND_URL + url)
    .then(resp => {
      dispatch(
        leagueSlice.actions.getAllLeagues({
          leagues: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getTournaments = _filters => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { facility_id, is_admin } = getState().user,
    filters = { ...getState().league.filter, ..._filters }
  if (!is_admin) {
    filters.facility_id = facility_id
  }
  let url = `/tournament?active=${filters.active ? filters.active : 0}`
  if (filters.facility_id && is_admin) {
    url += `&facility_ids=${filters.facility_id.map(f => f.value).join(',')}`
  }
  if (filters.facility_id && !is_admin) {
    url += `&facility_ids=${filters.facility_id}`
  }
  if (filters.product_type_id) {
    url += `&product_type_id=${filters.product_type_id}`
  }
  if (filters.start_date) {
    url += `&start=${filters.start_date}`
  }
  if (filters.end_date) {
    url += `&end=${filters.end_date}`
  }
  if (filters.hide_paid) {
    url += `&hide_paid=1`
  }
  return axios
    .get(ERP_BACKEND_URL + url)
    .then(resp => {
      dispatch(
        leagueSlice.actions.getAllTournaments({
          tournaments: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}
export const getTournamentsByFacility = facilityID => (dispatch, getState) => {
  dispatch(changeLoading(true))
  let url = `/tournament?active=1&facility_ids=${facilityID}&hide_paid=1`
  return axios
    .get(ERP_BACKEND_URL + url)
    .then(resp => {
      dispatch(
        leagueSlice.actions.getAllTournaments({
          tournaments: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getLeagueDetails = (id, is_tournament) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const url = is_tournament ? '/tournament/' : '/league/'
  return axios
    .get(ERP_BACKEND_URL + url + id)
    .then(resp => {
      if (is_tournament) {
        dispatch(changeLoading(false))
        return dispatch(
          leagueSlice.actions.getLeagueDetails({
            league: {
              ...resp.data,
              teams: resp.data.leagues.map(l => l.teams).flat(),
            },
          })
        )
      }
      let teams = resp.data.teams.map((t, i) => ({ ...t, order: i }))
      if (resp.data.divisions.length > 0) {
        teams = resp.data.divisions.map(l => l.teams).flat()
      }
      const d = {
        ...resp.data,
        teams: teams,
      }
      dispatch(
        leagueSlice.actions.getLeagueDetails({
          league: d,
        })
      )
      dispatch(getFields(resp.data.facility_id))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getAgeGroups = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/age-group`)
    .then(resp => {
      dispatch(
        leagueSlice.actions.getAgeGroups({
          age_groups: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const editQuery = query => (dispatch, getState) =>
  dispatch(leagueSlice.actions.editSearchQuery({ query }))

const toggleModal =
  (open, redirect_to_league, edit) => (dispatch, getState) => {
    let body = { open }
    if (redirect_to_league !== null) {
      body.redirect_to_league = redirect_to_league
    }
    if (edit !== null) {
      body.edit = edit
    }
    dispatch(leagueSlice.actions.toggleModal(body))
  }

const toggleTournamentModal = open => (dispatch, getState) => {
  let body = { open, tournament: true }
  dispatch(leagueSlice.actions.toggleModal(body))
}

const changeLeagueDetails = (field, value) => (dispatch, getState) => {
  dispatch(
    leagueSlice.actions.changeLeagueDetails({
      field,
      value,
    })
  )
}

const setLeagueDetails = league => (dispatch, getState) => {
  dispatch(leagueSlice.actions.setLeagueDetails({ league }))
}

const createLeague = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const league_details = getState().league.create_league
  const body = {
      name: league_details.name,
      description: league_details.description,
      facility_id: league_details.facility_id,
      product_id: league_details.product_id,
      new_product_id: league_details.new_product_id,
      age_group_id: league_details.age_group_id,
      start_date: league_details.start_date,
      end_date: league_details.end_date,
      registration_start_date: league_details.registration_start_date,
      registration_end_date: league_details.registration_end_date,
      early_bird_start_date: league_details.early_bird_start_date,
      require_membership: league_details.require_membership,
      early_bird_end_date: league_details.early_bird_end_date,
      gender: league_details.gender,
      enable_individual: league_details.enable_individual,
      enable_deposit: league_details.enable_deposit,
      enable_early_bird: league_details.enable_early_bird,
      display_schedule: league_details.display_schedule,
      available_online: league_details.available_online,
      max_teams: parseInt(league_details.max_teams),
      duration: parseInt(league_details.duration),
      color: league_details.color || '#3f51b5', // default league color
      is_container: !league_details.tournament,
    },
    url = league_details.tournament ? '/tournament' : '/league'
  return axios
    .post(ERP_BACKEND_URL + url, body)
    .then(resp => {
      if (league_details.redirect_to_league) {
        window.location = `${url}/${resp.data.ID}`
      }
      dispatch(getLeagues())
    })
    .catch(e => dispatch(dispatchError(e)))
}

const createTournamentDivision = is_tournament => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const league_details = getState().league.create_league,
    { name } = getState().league.new_division
  let league_parent_id = null
  if (!is_tournament) {
    league_parent_id = league_details.ID
  }
  const body = {
    name: `${league_details.name} - ${name}`,
    description: league_details.description,
    facility_id: league_details.facility_id,
    product_id: league_details.product_id,
    new_product_id: league_details.new_product_id,
    age_group_id: league_details.age_group_id,
    start_date: league_details.start_date,
    end_date: league_details.end_date,
    registration_start_date: league_details.registration_start_date,
    registration_end_date: league_details.registration_end_date,
    early_bird_start_date: league_details.early_bird_start_date,
    require_membership: league_details.require_membership,
    early_bird_end_date: league_details.early_bird_end_date,
    gender: league_details.gender,
    enable_deposit: league_details.enable_deposit,
    enable_early_bird: league_details.enable_early_bird,
    display_schedule: league_details.display_schedule,
    available_online: league_details.available_online,
    max_teams: parseInt(league_details.max_teams),
    duration: parseInt(league_details.duration),
    is_tournament: is_tournament,
    parent_league_id: league_parent_id,
    tournament_id: league_details.ID,
    color: league_details.color || '#3f51b5', // default league color
    properties: league_details.properties,
  }
  return axios
    .post(ERP_BACKEND_URL + '/league', body)
    .then(resp => {
      dispatch(
        leagueSlice.actions.updateNewDialog({ key: 'open', value: false })
      )
      dispatch(getLeagueDetails(league_details.ID, is_tournament))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const editLeague = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const league_details = getState().league.create_league
  const body = {
    name: league_details.name,
    description: league_details.description,
    facility_id: league_details.facility_id,
    product_id: league_details.product_id,
    new_product_id: league_details.new_product_id,
    gender: league_details.gender,
    age_group_id: league_details.age_group_id,
    start_date: league_details.start_date,
    end_date: league_details.end_date,
    registration_start_date: league_details.registration_start_date,
    registration_end_date: league_details.registration_end_date,
    early_bird_start_date: league_details.early_bird_start_date,
    require_membership: league_details.require_membership,
    early_bird_end_date: league_details.early_bird_end_date,
    enable_deposit: league_details.enable_deposit,
    display_schedule: league_details.display_schedule,
    available_online: league_details.available_online,
    max_teams: parseInt(league_details.max_teams),
    color: league_details.color || '#3f51b5', // default league color
  }
  return axios
    .put(ERP_BACKEND_URL + `/league/${league_details.ID}`, body)
    .then(resp => {
      dispatch(getLeagues())
    })
    .catch(e => dispatch(dispatchError(e)))
}

const updateLeagueDetails = (k, v) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().league.league
  return axios
    .put(ERP_BACKEND_URL + `/league/${ID}`, { [k]: v })
    .then(() => {
      dispatch(leagueSlice.actions.updateLeague({ key: v, value: v }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const changeLeagueTab = tab_name => (dispatch, getState) => {
  dispatch(
    leagueSlice.actions.changeLeagueTab({
      tab: tab_name,
    })
  )
}

const setCurrentLeagueDetails = () => (dispatch, getState) => {
  const league = getState().league.league
  dispatch(
    leagueSlice.actions.setLeagueDetails({
      league,
    })
  )
}

const toggleAddTeamModal = open => (dispatch, getState) => {
  dispatch(
    leagueSlice.actions.toggleAddTeamModal({
      open,
    })
  )
}

const addTeamSignup = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const league = getState().league,
    team_details = getState().teams.team_form
  let fee = league.create_league.fee_amt,
    body = {
      league_id: league.league.ID,
      fee,
    },
    p = null
  if (!fee) {
    return dispatch(dispatchError({ message: 'Please select a valid fee' }))
  }
  if (league.add_team_tab === 'new') {
    if (!team_details.name || team_details.name === '') {
      return dispatch(
        dispatchError({ message: 'Please enter a valid team name' })
      )
    }
    p = axios
      .post(ERP_BACKEND_URL + '/team', {
        name: team_details.name,
        color: team_details.color,
        facility_id: league.league.facility_id,
      })
      .then(resp => resp.data.ID)
  } else {
    if (!league.create_league.new_team) {
      return dispatch(dispatchError({ message: 'Please enter a valid team' }))
    }
    const team_id = league.create_league.new_team.ID
    p = Promise.resolve(team_id)
  }
  return p
    .then(team_id => {
      body.team_id = team_id
      return axios.post(ERP_BACKEND_URL + `/team/signup`, body).then(resp => {
        let discounts = []
        if (league.create_league.discount_amt) {
          discounts.push({
            amount: league.create_league.discount_amt,
            comment: 'early_bird',
          })
        }
        const invoice_body = {
          facility_id: league.league.facility_id,
          product_type_id: league.league.new_product.gl.product_type_id,
          date: parseInt(moment().format('X')),
          invoice_type_id: 2,
          team_id,
          invoice_items: [
            {
              product_id: league.league.product.ID,
              new_product_id: league.league.new_product.ID,
              price: fee,
              quantity: 1.0,
              team_signup_id: resp.data.ID,
            },
          ],
          discounts,
        }
        return axios.post(ERP_BACKEND_URL + `/invoice`, invoice_body)
      })
    })
    .then(resp => {
      dispatch(getLeagueDetails(league.league.ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const deleteTeamSignup = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .delete(ERP_BACKEND_URL + `/team/signup/` + id)
    .then(resp => {
      dispatch(getLeagueDetails(getState().league.league.ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const deletePlayerSignup = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .delete(ERP_BACKEND_URL + `/eplayer/signup/` + id)
    .then(resp => {
      dispatch(getLeagueDetails(getState().league.league.ID))
      dispatch(
        leagueSlice.actions.removeEPlayer({
          id,
        })
      )
    })
    .catch(e => dispatch(dispatchError(e)))
}

const changeScheduleDetails = (field, value) => (dispatch, getState) => {
  dispatch(
    leagueSlice.actions.changeScheduleDetails({
      field,
      value,
    })
  )
}

const addSchedulerSlot = () => (dispatch, getState) => {
  const slot_details = getState().league.scheduler
  dispatch(
    leagueSlice.actions.addSchedulerSlot({
      slot_details: {
        ...slot_details,
        token: uuidv4(),
      },
    })
  )
}

const removeSchedulerSlot = token => (dispatch, getState) => {
  dispatch(
    leagueSlice.actions.removeSchedulerSlot({
      token,
    })
  )
}

const getComputedSchedule = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const league_state = getState().league,
    duration = parseInt(league_state.scheduler.duration)
  let slots = []
  league_state.scheduler_slots.forEach(s => {
    const start_moment = moment(s.start_time),
      start_time_unix = parseInt(start_moment.format('X')),
      end_time_unix = parseInt(moment(s.end_time).format('X'))
    let i = 0
    while (
      start_time_unix + i * duration * 60 + duration * 60 <=
      end_time_unix
    ) {
      // check match end is not after slot end
      const slot_date = start_moment.clone().add(i * duration, 'm')
      slots.push({
        field: s.field.value.toString(),
        weekday: s.weekday === 7 ? 0 : s.weekday,
        start: slot_date.format('HH:mm'),
        start_unix: slot_date.unix(),
      })
      i += 1
    }
  })
  const body = {
    games: parseInt(league_state.scheduler.games),
    teams: [...league_state.league.teams]
      .sort((a, b) => a.order - b.order)
      .map(t => t.team.ID.toString()),
    start_date: moment(league_state.league.start_date, 'X').format('M/D/YYYY'),
    slots: slots.sort((a, b) => a.start_unix - b.start_unix),
    duration,
  }
  return axios
    .post(ERP_BACKEND_URL + `/league/schedule/new`, body)
    .then(resp => {
      dispatch(
        leagueSlice.actions.getScheduleMatches({
          teams: getState().league.league.teams,
          matches: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getComputedTournamentSchedule = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const league_state = getState().league,
    duration = parseInt(league_state.scheduler.duration),
    slots = league_state.scheduler_slots.map(s => ({
      field: s.field.value.toString(),
      start: moment(s.start_time).format('HH:mm'),
    }))
  const body = {
    teams: [...league_state.league.teams]
      .sort((a, b) => a.order - b.order)
      .map(t => t.team.ID.toString()),
    start_date: moment(league_state.league.start_date, 'X').format('M/D/YYYY'),
    slots: slots.sort((a, b) => a.start_unix - b.start_unix),
    duration,
  }
  return axios
    .post(ERP_BACKEND_URL + `/tournament/schedule`, body)
    .then(resp => {
      dispatch(
        leagueSlice.actions.getScheduleMatches({
          teams: getState().league.league.teams,
          matches: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getComputedELeagueSchedule = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const league_state = getState().league,
    duration = parseInt(league_state.scheduler.duration),
    games = parseInt(league_state.scheduler.games),
    e_players = league_state.league.e_players.map(p => p.player.console_id)

  // Compute n schedules for n slots
  let bodies = []
  league_state.scheduler_slots.forEach((s, k) => {
    const start_moment = moment(s.start_time),
      start_time_unix = parseInt(start_moment.format('X')),
      end_time_unix = parseInt(moment(s.end_time).format('X'))
    let i = 0,
      subslots = []
    while (
      start_time_unix + i * duration * 60 + duration * 60 <=
      end_time_unix
    ) {
      // Add j times the same slot so every team plays
      for (let j = 0; j < e_players.length; j++) {
        subslots.push({
          field: s.field.label,
          weekday: s.weekday === 7 ? 0 : s.weekday,
          start: start_moment
            .clone()
            .add(i * duration, 'm')
            .format('HH:mm'),
        })
      }
      i += 1
    }
    bodies.push({
      games,
      teams: shuffleArray([...e_players]), // shuffle team to they don't always play against each other
      start_date: moment(league_state.league.start_date, 'X').format(
        'M/D/YYYY'
      ),
      slots: subslots,
      duration,
    })
  })
  return Promise.all(
    bodies.map(body => {
      return axios
        .post(ERP_BACKEND_URL + `/league/schedule`, body)
        .then(resp => resp.data)
    })
  ).then(matches_arrays => {
    let matches = []
    for (let i = 0; i < matches_arrays.length; i++) {
      matches = matches.concat(matches_arrays[i])
    }
    dispatch(
      leagueSlice.actions.getScheduleMatches({
        matches,
      })
    )
    dispatch(changeLoading(false))
  })
}

const changeScheduledMatchDetails =
  (token, field, value) => (dispatch, getState) => {
    dispatch(
      leagueSlice.actions.changeScheduledMatchDetails({
        token,
        field,
        value,
      })
    )
  }

const _createMatches = body => {
  return axios.post(ERP_BACKEND_URL + `/match`, body)
}

const createMatches = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const league_state = getState().league,
    body = league_state.scheduler_matches
      .filter(m => m.match_type !== 'break')
      .map((m, i) => {
        const moment_date = moment(m.date + ' ' + m.time, 'M/D/YYYY HH:mm'),
          home_team = league_state.league.teams.find(
            t => t.team.ID.toString() === m.home
          )?.team,
          away_team = league_state.league.teams.find(
            t => t.team.ID.toString() === m.away
          )?.team,
          group =
            league_state.league.teams.length < 6
              ? 1
              : i > parseInt(league_state.league.teams.length / 2)
              ? 2
              : 1
        return {
          league_id: league_state.league.ID,
          home_team_id: home_team?.ID, // fix by taking team_id...
          away_team_id: away_team?.ID,
          date: momentDateTimeToUnix(moment_date),
          field_id: parseInt(m.field),
          duration: parseInt(league_state.scheduler.duration),
          comment: home_team?.name + ' vs. ' + away_team?.name,
          is_tournament: league_state.league.is_tournament,
          index: m.index,
          level: m.level,
          group,
          match_type: m.match_type,
        }
      })
  return Promise.all(body.map(_createMatches))
    .then(() => {
      dispatch(getMatches())
    })
    .catch(e => dispatch(dispatchError(e)))
}

const createEMatches = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  // to speed up getting id, loop only once
  const league_state = getState().league
  let player_map = {}
  league_state.league.e_players.forEach(({ player }) => {
    player_map[player.console_id] = player
  })
  const body = league_state.scheduler_matches.map(m => {
    const moment_date = moment(m.date + ' ' + m.time, 'M/D/YYYY HH:mm')
    return {
      league_id: league_state.league.ID,
      home_player_id: player_map[m.home].ID, // fix by taking team_id...
      away_player_id: player_map[m.away].ID,
      date: momentDateTimeToUnix(moment_date),
      duration: parseInt(league_state.scheduler.duration),
    }
  })
  return Promise.all(
    body.map(b => {
      return axios.post(ERP_BACKEND_URL + `/ematch`, b)
    })
  )
    .then(() => {
      dispatch(getMatches())
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getMatches = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const league_state = getState().league
  const is_e_league = league_state.league.is_e_league
  if (is_e_league) {
    return axios
      .get(
        ERP_BACKEND_URL +
          `/ematch/matches?league_id=${league_state.league.ID}&page=${league_state.league_page}`
      )
      .then(resp => {
        dispatch(
          leagueSlice.actions.getAllMatches({
            matches: resp.data.map(m => ({ ...m, changed: false })),
            standings: [],
          })
        )
        dispatch(changeLoading(false))
      })
  }
  return axios
    .post(ERP_BACKEND_URL + `/match/list`, {
      query: { league_id: league_state.league.ID },
    })
    .then(resp => {
      const matches = resp.data.matches.filter(
          m => m.match_type !== 'playoffs'
        ),
        playoffs = resp.data.matches
          .filter(m => m.match_type === 'playoffs')
          .map(m => {
            let is_bye = false
            if (m.home_team_id === 0 || m.away_team_id === 0) {
              if (m.home_score || m.away_score) {
                is_bye = true
              }
            }
            let round = `Round of ${m.level}`
            if (m.level === 8) {
              round = 'Quarter final'
            }
            if (m.level === 4) {
              round = 'Semi final'
            }
            if (m.level === 2) {
              round = 'Final'
            }
            return { ...m, is_bye, round }
          })
          .sort((a, b) => b.level - a.level)
      dispatch(
        leagueSlice.actions.getAllMatches({
          matches,
          playoffs,
          standings: resp.data.standings,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getStandings = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const is_e_league = getState().league?.league?.is_e_league
  let url = `/league/standings?league_id=${getState().league.league.ID}`
  if (is_e_league) {
    url = `/ematch/standings?league_id=${getState().league.league.ID}`
  }
  return axios.get(ERP_BACKEND_URL + url).then(resp => {
    dispatch(
      leagueSlice.actions.getStandings({
        standings: resp.data,
      })
    )
    dispatch(changeLoading(false))
  })
}

const updateMatchScore = (id, side, score) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  dispatch(
    leagueSlice.actions.updateScore({
      id,
      side,
      score,
    })
  )
  let is_e_league = getState().league?.league?.is_e_league
  let url = ERP_BACKEND_URL + `/match/`
  if (is_e_league) {
    url = ERP_BACKEND_URL + `/ematch/`
  }
  const body = {
    [side]: score,
  }
  return axios
    .put(url + id, body)
    .then(resp => {
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const updatePlayoffScore = (id, side, score) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  let url = ERP_BACKEND_URL + `/match/`
  const body = {
    [side]: score,
  }
  return axios
    .put(url + id, body)
    .then(resp => {
      dispatch(getPlayoffMatches())
    })
    .catch(e => dispatch(dispatchError(e)))
}

const updateMatchDetails = (id, key, value) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .put(ERP_BACKEND_URL + `/match/${id}`, { [key]: value })
    .then(resp => {
      dispatch(getMatches())
    })
    .catch(e => dispatch(dispatchError(e)))
}

const changeMatchPage = page => (dispatch, getState) => {
  dispatch(
    leagueSlice.actions.changeMatchPage({
      page,
    })
  )
  dispatch(getMatches())
}
const togglePlayerMatchModal = open => (dispatch, getState) => {
  dispatch(
    leagueSlice.actions.togglePlayerMatchModal({
      open,
    })
  )
}

const getPlayerMatchesModal =
  (player_id, league_id) => (dispatch, getState) => {
    if (!league_id) {
      league_id = getState().league.league.ID
    }
    dispatch(togglePlayerMatchModal(true))
    dispatch(changeLoading(true))
    return axios
      .get(
        ERP_BACKEND_URL +
          `/ematch/player?league_id=${league_id}&player_id=${player_id}`
      )
      .then(resp => {
        dispatch(
          leagueSlice.actions.getPlayerMatches({
            matches: resp.data,
          })
        )
        dispatch(changeLoading(false))
      })
      .catch(e => dispatch(dispatchError(e)))
  }

const getPlayoffMatches = () => (dispatch, getState) => {
  const league = getState().league.league
  dispatch(changeLoading(true))
  return axios
    .get(
      ERP_BACKEND_URL +
        `/${league.is_e_league ? 'ematch' : 'league'}/playoffs?league_id=${
          league.ID
        }`
    )
    .then(resp => {
      dispatch(
        leagueSlice.actions.getPlayoffMatches({
          matches: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const getSimpleDailyMatches = moment_date => (dispatch, getState) => {
  const league_id = getState().league.league.ID,
    end = momentDateToUnix(moment_date.add(1, 'd')),
    league_start_unix = getState().league.league.start_date
  dispatch(changeLoading(true))
  return axios
    .get(
      ERP_BACKEND_URL +
        `/ematch/day?league_id=${league_id}&start=${league_start_unix}&end=${end}`
    )
    .then(resp => {
      dispatch(
        leagueSlice.actions.getAllMatches({
          matches: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const sendDailySchedule = (moment_date, level) => (dispatch, getState) => {
  const league_id = getState().league.league.ID,
    start = momentDateToUnix(moment_date),
    end = momentDateToUnix(moment_date.add(1, 'd'))
  dispatch(changeLoading(true))
  return axios
    .post(
      ERP_BACKEND_URL +
        `/ematch/send?password=sofive2020&league_id=${league_id}&start=${start}&end=${end}&level=${level}`
    )
    .then(resp => {
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const setNoShows = unixTime => (dispatch, getState) => {
  const league_id = getState().league.league.ID
  dispatch(changeLoading(true))
  return axios
    .post(
      ERP_BACKEND_URL +
        `/ematch/noshow?league_id=${league_id}&start=${unixTime}`
    )
    .then(resp => {
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const togglePlayerModal = open => (dispatch, getState) => {
  dispatch(
    leagueSlice.actions.togglePlayerModal({
      open,
    })
  )
}

const getEPlayerDetails = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/eplayer/${id}`)
    .then(resp => {
      dispatch(
        leagueSlice.actions.getEPlayerDetails({
          eplayer: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const editEPlayerDetails = (field, value) => (dispatch, getState) => {
  dispatch(
    leagueSlice.actions.editEPlayerDetails({
      field,
      value,
    })
  )
}

const updateEPlayer = () => (dispatch, getState) => {
  const eplayer_state = getState().league.eplayer
  dispatch(changeLoading(true))
  return axios
    .put(ERP_BACKEND_URL + `/eplayer/${eplayer_state.ID}`, {
      console_id: eplayer_state.console_id,
      discord_username: eplayer_state.discord_username,
    })
    .then(resp => {
      dispatch(
        leagueSlice.actions.updateEPlayerDetails({
          eplayer: {
            ID: eplayer_state.ID,
            console_id: eplayer_state.console_id,
            discord_username: eplayer_state.discord_username,
          },
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const runNewCheck = match => (dispatch, getState) => {
  const unixStart = momentDateTimeToUnix(
      moment(match.date + ' ' + match.time, 'M/D/YYYY HH:mm')
    ),
    ev = {
      field_id: parseInt(match.field),
      start_date: unixStart,
      end_date: unixStart + getState().league.scheduler.duration * 60,
    }
  return axios
    .post(ERP_BACKEND_URL + `/event/recurrent`, {
      events: [ev],
    })
    .then(resp => {
      dispatch(
        leagueSlice.actions.changeScheduledMatchDetails({
          token: match.token,
          field: 'is_ok',
          value: resp.data[0].is_ok,
          to_check: false,
        })
      )
    })
    .catch(e => dispatch(dispatchError(e)))
}

const toggleNewTeam = create_team => (dispatch, getState) => {
  dispatch(leagueSlice.actions.toggleNewTeam({ create_team }))
}

const toggleAddGameModal = open => (dispatch, getState) => {
  dispatch(leagueSlice.actions.toggleAddGameModal({ open }))
}

const updateGameDetails = (key, value) => (dispatch, getState) => {
  dispatch(leagueSlice.actions.updateGameDetails({ key, value }))
}

const createManualMatch = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const league_state = getState().league,
    event_details = getState().calendar.event_details,
    home_team_id = league_state.new_game.home_team_id,
    away_team_id = league_state.new_game.away_team_id,
    home_team = league_state.league.teams.find(
      t => t.team.ID === home_team_id
    )?.team,
    away_team = league_state.league.teams.find(
      t => t.team.ID === away_team_id
    )?.team,
    game_type = league_state.new_game.game_type || 'regular',
    comment =
      game_type === 'regular'
        ? `${home_team?.name} vs. ${away_team?.name}`
        : game_type,
    body = {
      league_id: league_state.league.ID,
      home_team_id,
      away_team_id,
      date: event_details.start_time,
      field_id: event_details.field_id,
      duration: parseInt(league_state.scheduler.duration),
      comment,
      match_type: game_type,
    }
  return _createMatches(body)
    .then(() => {
      dispatch(getMatches())
    })
    .catch(e => dispatch(dispatchError(e)))
}

const deleteMatch = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .delete(ERP_BACKEND_URL + `/match/` + id.toString())
    .then(() => {
      dispatch(leagueSlice.actions.deleteMatch({ id }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const changeFilterDetails = (key, value) => (dispatch, getState) => {
  dispatch(leagueSlice.actions.changeFilterDetails({ key, value }))
}

const changePlayoffDetails = (key, value, i) => (dispatch, getState) => {
  dispatch(leagueSlice.actions.changePlayoffDetails({ key, value, i }))
}

const editPlayoff = (key, value, i) => (dispatch, getState) => {
  dispatch(leagueSlice.actions.editPlayoff({ key, value, i }))
}

const buildPlayoffs = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const league = getState().league.league,
    playoff_details = getState().league.new_playoffs,
    body = {
      league_id: league.ID,
      duration: parseInt(playoff_details.duration),
      round_settings: playoff_details.round_settings.map(r => {
        const start = momentDateToUnix(moment(r.start_date)),
          start_time = moment(r.start_time)
        return {
          level: r.level,
          field_id: r.field.value,
          start_time:
            start + start_time.hours() * 3600 + start_time.minutes() * 60,
        }
      }),
    }
  return axios
    .post(ERP_BACKEND_URL + `/league/playoffs`, body)
    .then(resp => {
      dispatch(leagueSlice.actions.buildPlayoffs({ playoffs: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const runPlayoffCheck = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const duration = getState().league.new_playoffs.duration
  return axios
    .post(ERP_BACKEND_URL + `/event/recurrent`, {
      events: getState().league.built_playoffs.map(e => {
        const start = momentDateToUnix(moment(e.date_parsed)),
          start_time = moment(e.time_parsed),
          start_time_unix =
            start + start_time.hours() * 3600 + start_time.minutes() * 60,
          end_time_unix = start_time_unix + duration * 60
        return {
          field_id: e.field_id,
          start_date: start_time_unix,
          end_date: end_time_unix,
        }
      }),
    })
    .then(resp => {
      dispatch(leagueSlice.actions.checkNewPlayoffs({ checks: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const _updateMatch = m => axios.put(ERP_BACKEND_URL + `/match/${m.ID}`, m)

const createMatchEvents = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const duration = parseInt(getState().league.new_playoffs.duration)
  let match_updates = [],
    score_updates = []
  const league = getState().league.league,
    playoffs = getState().league.built_playoffs
  let events = []
  playoffs.forEach(match => {
    // Dont create byes
    if (match.home_team_id !== -1 && match.away_team_id !== -1) {
      const start = momentDateToUnix(moment(match.date_parsed)),
        start_time = moment(match.time_parsed),
        start_time_unix =
          start + start_time.hours() * 3600 + start_time.minutes() * 60,
        end_time_unix = start_time_unix + duration * 60
      events.push({
        match_id: match.ID,
        product_type_id: league.new_product.gl.product_type_id,
        field_id: match.field_id,
        start_date: start_time_unix,
        end_date: end_time_unix,
        comment: 'League match',
      })
    }
    if (match.home_team_id !== 0 && match.away_team_id !== 0) {
      match_updates.push({
        ID: match.ID,
        home_team_id: Math.max(match.home_team_id, 0), // change bye to 0
        away_team_id: Math.max(match.away_team_id, 0),
      })
      if (match.home_team_id !== -1 || match.away_team_id !== -1) {
        score_updates.push({
          ID: match.ID,
          home_score: match.away_team_id === -1 ? '1' : '',
          away_score: match.home_team_id === -1 ? '1' : '',
        })
      }
    }
  })
  return axios
    .post(ERP_BACKEND_URL + `/events`, { events })
    .then(resp => {
      return Promise.all(match_updates.map(_updateMatch)).then(() => {
        return Promise.all(score_updates.map(_updateMatch)).then(() => {
          dispatch(getPlayoffMatches())
        })
      })
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const deleteLeague = (id, is_tournament) => (dispatch, getState) => {
  dispatch(changeLoading(true))
  let base_url = is_tournament ? `/tournament` : `/league`
  return axios
    .delete(ERP_BACKEND_URL + `${base_url}/${id}`)
    .then(() => {
      dispatch(changeLoading(false))
      dispatch(leagueSlice.actions.deleteLeague({ id }))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const updateLeague = is_tournament => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const {
    ID,
    name,
    description,
    max_teams,
    duration,
    enable_deposit,
    enable_individual,
    enable_early_bird,
    display_schedule,
    available_online,
    start_date,
    end_date,
    require_membership,
    registration_start_date,
    registration_end_date,
    early_bird_start_date,
    early_bird_end_date,
    product_id,
    new_product_id,
    gender,
    age_group_id,
    color,
    properties,
  } = getState().league.create_league
  const base_url = is_tournament ? `/tournament` : `/league`
  return axios
    .put(ERP_BACKEND_URL + `${base_url}/${ID}`, {
      name,
      description,
      max_teams: parseInt(max_teams),
      duration: parseInt(duration),
      enable_deposit,
      enable_individual,
      enable_early_bird,
      display_schedule,
      available_online,
      start_date,
      end_date,
      registration_start_date,
      registration_end_date,
      early_bird_start_date,
      early_bird_end_date,
      product_id,
      new_product_id,
      gender,
      require_membership,
      age_group_id,
      color: color || '#3f51b5',
      properties,
    })
    .then(() => {
      if (!is_tournament) dispatch(getLeagueDetails(ID))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const setGameDetails = match_details => (dispatch, getState) => {
  dispatch(calendarSlice.actions.getEvent({ event: match_details.event }))
  dispatch(
    leagueSlice.actions.updateGameDetails({
      key: 'home_team_id',
      value: match_details.home_team_id.toString(),
    })
  )
  dispatch(
    leagueSlice.actions.updateGameDetails({
      key: 'away_team_id',
      value: match_details.away_team_id.toString(),
    })
  )
  dispatch(toggleAddGameModal(true))
}

const updateMatchEvent = match => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const body = {
    start_date: match.event.start_date,
    end_date: match.event.start_date + match.duration * 60,
    field_id: match.event.field_id,
  }
  return axios
    .put(ERP_BACKEND_URL + '/event/' + match.event.ID, body)
    .then(resp => {
      dispatch(getMatches())
    })
    .catch(e => dispatch(dispatchError(e)))
}

const _deleteMatch = id => {
  return axios.delete(ERP_BACKEND_URL + `/match/` + id.toString())
}

const deleteGames = () => (dispatch, getState) => {
  const { league_matches } = getState().league
  return Promise.all(league_matches.map(m => _deleteMatch(m.ID)))
    .then(() => {
      dispatch(getMatches())
    })
    .catch(e => dispatch(dispatchError(e)))
}

const deletePlayoffMatches = () => (dispatch, getState) => {
  const league = getState().league.league
  dispatch(changeLoading(true))
  return axios
    .delete(ERP_BACKEND_URL + `/league/playoffs/${league.ID}`)
    .then(resp => {
      dispatch(getPlayoffMatches())
    })
    .catch(e => dispatch(dispatchError(e)))
}

const resetPlayoffs = () => (dispatch, getState) =>
  dispatch(leagueSlice.actions.getPlayoffMatches({ matches: [] }))

const broadcastMessage =
  (subject, html, recipients, facility_id) => (dispatch, getState) => {
    dispatch(changeLoading(true))
    return axios
      .post(ERP_BACKEND_URL + `/broadcast`, {
        subject,
        html,
        recipients,
        facility_id,
      })
      .then(resp => {
        dispatch(changeLoading(false))
        dispatch(
          leagueSlice.actions.updateBroadcastMessage({
            key: 'open',
            value: false,
          })
        )
      })
      .catch(e => dispatch(dispatchError(e)))
  }

export {
  getLeagues,
  getAgeGroups,
  editQuery,
  toggleModal,
  toggleTournamentModal,
  changeLeagueDetails,
  createLeague,
  getLeagueDetails,
  changeLeagueTab,
  setCurrentLeagueDetails,
  toggleAddTeamModal,
  addTeamSignup,
  deleteTeamSignup,
  changeScheduleDetails,
  addSchedulerSlot,
  removeSchedulerSlot,
  getComputedSchedule,
  changeScheduledMatchDetails,
  createMatches,
  getMatches,
  updateMatchScore,
  getComputedELeagueSchedule,
  createEMatches,
  getStandings,
  changeMatchPage,
  togglePlayerMatchModal,
  getPlayerMatchesModal,
  getPlayoffMatches,
  getSimpleDailyMatches,
  sendDailySchedule,
  setNoShows,
  togglePlayerModal,
  getEPlayerDetails,
  editEPlayerDetails,
  updateEPlayer,
  deletePlayerSignup,
  runNewCheck,
  toggleNewTeam,
  toggleAddGameModal,
  updateGameDetails,
  createManualMatch,
  deleteMatch,
  updatePlayoffScore,
  changePlayoffDetails,
  buildPlayoffs,
  runPlayoffCheck,
  editPlayoff,
  createMatchEvents,
  setLeagueDetails,
  editLeague,
  changeFilterDetails,
  getLeaguesByFacility,
  updateLeague,
  setGameDetails,
  updateMatchEvent,
  updateMatchDetails,
  deleteGames,
  deletePlayoffMatches,
  resetPlayoffs,
  broadcastMessage,
  createTournamentDivision,
  getComputedTournamentSchedule,
  updateLeagueDetails,
}

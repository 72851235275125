import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'

import { colors } from './../../consts'
import { actions } from 'classes/redux'
import { openHubspotList } from 'common/actions'

class ClassSummary extends React.Component {
  computeContainerSumary() {
    let invoiced = 0,
      paid = 0,
      divisions = this.props.class.divisions.filter(c => {
        if (this.props.query)
          return c.name.toLowerCase().indexOf(this.props.query) !== -1
        return true
      })
    divisions?.forEach(division => {
      division?.attendees?.forEach(attendee => {
        if (attendee.invoice && attendee.invoice.ID) {
          invoiced += attendee.invoice.invoiced - attendee.invoice.discounted
          paid += attendee.invoice.paid
        }
      })
    })

    let attendees_count = divisions?.map(d => d.attendees)?.flat().length
    return {
      ...this.props.class,
      attendees_count,
      balance: invoiced - paid,
      invoiced,
      paid,
    }
  }

  render() {
    let classInfo = this.props.class
    if (this.props.class.is_container) {
      classInfo = this.computeContainerSumary()
    }
    return (
      <div>
        <Paper elevation={0} style={{ padding: '4%' }}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={12}>
              <Typography variant="h5">Summary</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Total due:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" style={{ color: 'red' }}>
                <Chip
                  size="small"
                  label={<b>${Math.round(classInfo?.balance * 100) / 100}</b>}
                  style={{
                    color:
                      classInfo?.balance > 0
                        ? colors.red_strong
                        : colors.green_strong,
                    backgroundColor:
                      classInfo?.balance > 0
                        ? colors.red_mild
                        : colors.green_mild,
                    margin: 5,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Attendees:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                {classInfo?.attendees_count}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Total expected:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                ${classInfo?.invoiced}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Total paid:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">${classInfo?.paid}</Typography>
            </Grid>
          </Grid>
        </Paper>
        <Button
          className="save-btn"
          style={{ marginTop: 20 }}
          onClick={() => this.props.openBroadcast()}
        >
          Broadcast message
        </Button>
        <Grid item xs={12}>
          <Button
            className="save-btn"
            style={{ marginTop: 20 }}
            onClick={() => this.props.openHubspotList(classInfo.ID)}
          >
            Create hubspot list
          </Button>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  query:
    state.classes.filters.query &&
    state.classes.filters.query.trim().toLowerCase(),
  class: state.classes.classInfo,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  openBroadcast: () => dispatch(actions.updateBroadcastMessage({ open: true })),
  openHubspotList: class_id =>
    dispatch(openHubspotList({ class_ids: [class_id] })),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClassSummary)

import React from 'react'
import { connect } from 'react-redux'

import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import { jsPDF } from 'jspdf'

import { updateMatchScore } from 'league/actions'
import { loadHomeTeam, loadAwayTeam } from 'calendar/actions'

class LeagueSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      home_score: null,
      away_score: null,
    }
  }

  componentDidMount() {
    this.setState({
      home_score: this.props.match?.home_score,
      away_score: this.props.match?.away_score,
    })
    if (this.props.match) {
      this.props.loadAway()
      this.props.loadHome()
    }
  }
  getMembershipExpirationDate(memberships, customerID) {
    if (!memberships) return '  xM'
    let membership =
      memberships.find(
        membership => membership.sub_customer_id === customerID
      ) ||
      (memberships.length > 0 && memberships[0])
    if (!membership) return '-  NONE'
    return membership.valid_until > moment().unix()
      ? '' //+ moment.unix(membership.valid_until).format('M/D/YYYY')
      : '  xM' //+ moment.unix(membership.valid_until).format('M/D/YYYY')
  }

  getWaiver(customer) {
    if (customer.waiver_signed) return ''
    return '  xW'
  }

  printRoster() {
    if (!this.props.home || !this.props.away) return
    const momenDate = moment.unix(this.props.match.event.start_date).utc()
    // Default export is a4 paper, portrait, using millimeters for units
    const doc = new jsPDF()
    doc.setFontSize(12)
    //
    doc.text(this.props.match.league.name, 10, 10)
    doc.text(momenDate.format('dddd, MMMM Do YYYY'), 10, 20)
    doc.text(
      `#${this.props.match.event.field.number} ${
        this.props.match.event.field.name
      } -  ${momenDate.format('hh:mm A')}`,
      10,
      30
    )
    doc.setTextColor(80, 200, 120)
    //Change color for team name headers
    doc.text(this.props.home.name, 10, 50)
    doc.text(this.props.away.name, 100, 50)
    doc.setTextColor(0, 0, 0)

    doc.text(
      `balance: ${this.props.home.current_league.invoice.balance}$`,
      10,
      60
    )
    doc.text(
      `balance: ${this.props.away.current_league.invoice.balance}$`,
      100,
      60
    )

    const self = this
    doc.setFontSize(8)
    this.props.match.home_team.roster.forEach(function (value, i) {
      doc.text(
        value.customer.first_name +
          ' ' +
          value.customer.last_name +
          (self.props.match.home_team?.captain != null &&
          self.props.match.home_team?.captain.ID == value.customer.ID
            ? ' (Captain) '
            : ' ') +
          self.getMembershipExpirationDate(
            value.customer?.member?.memberships,
            value.customer.ID
          ) +
          self.getWaiver(value.customer),
        10,
        70 + i * 7
      )
    })

    this.props.match.away_team.roster.forEach(function (value, i) {
      doc.text(
        value.customer.first_name +
          ' ' +
          value.customer.last_name +
          (self.props.match.away_team?.captain != null &&
          self.props.match.away_team?.captain.ID == value.customer.ID
            ? ' (Captain) '
            : ' ') +
          self.getMembershipExpirationDate(
            value.customer?.member?.memberships,
            value.customer.ID
          ) +
          self.getWaiver(value.customer),
        100,
        70 + i * 7
      )
    })

    doc.setFontSize(13)
    doc.text(
      `[ xM: No valid membership   xW: No valid Waiver    -SUS-: Suspended]`,
      10,
      290
    )

    doc.output('dataurlnewwindow')
  }

  render() {
    const { match } = this.props
    return (
      <Paper elevation={0}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ textAlign: 'center', marginTop: 20 }}
          spacing={4}
        >
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Link href={`/league/${match.league_id}`} target="_blank">
              <Button className="save-btn">Goto league</Button>
            </Link>
            <Button
              className="save-btn"
              style={{ marginLeft: 20 }}
              onClick={() => this.printRoster()}
            >
              Print roster
            </Button>
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center' }}>
            <Link href={`/team/${match?.home_team.ID}`} target="_blank">
              {match?.home_team.name}
            </Link>
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center' }}>
            <TextField
              label="HScore"
              fullWidth
              dense
              value={this.state.home_score}
              onChange={e => this.setState({ home_score: e.target.value })}
              onBlur={() =>
                this.props.updateMatchScore(
                  match.ID,
                  'home_score',
                  this.state.home_score
                )
              }
            />
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center' }}>
            <TextField
              label="VScore"
              fullWidth
              dense
              value={this.state.away_score}
              onChange={e => this.setState({ away_score: e.target.value })}
              onBlur={() =>
                this.props.updateMatchScore(
                  match.ID,
                  'away_score',
                  this.state.away_score
                )
              }
            />
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center' }}>
            <Link href={`/team/${match?.away_team.ID}`} target="_blank">
              {match?.away_team.name}
            </Link>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  match: state.calendar.event_details?.match,
  home: state.calendar.team_home,
  away: state.calendar.team_away,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateMatchScore: (id, side, score) =>
    dispatch(updateMatchScore(id, side, score)),
  loadAway: () => dispatch(loadAwayTeam()),
  loadHome: () => dispatch(loadHomeTeam()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeagueSection)

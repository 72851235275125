import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import MailIcon from '@material-ui/icons/Mail'
import DeleteIcon from '@material-ui/icons/Delete'
import NameForm from 'common/components/forms/TextEdit'
import { openDeleteModal } from './../../common/actions'

import { colors } from 'consts'
import { unixToDateTime } from 'helper'
import { actions } from 'contract/redux'
import {
  getFees,
  generateContractDoc,
  voidContractDoc,
  sendContractDoc,
  updateName,
  deleteContract,
  deleteAllEvents,
} from './../actions'
import { openHubspotList } from 'common/actions'

const styles = theme => ({
  root: {},
  details: {
    display: 'flex',
  },
})

const ContractDetails = props => {
  const {
    classes,
    contract,
    hours,
    getFees,
    changeContractDetails,
    openFeeEditModal,
    generateContractDoc,
    voidContractDoc,
    sendContractDoc,
    openPtypeEditModal,
    is_pickup,
    updateName,
    invoice,
    deleteContract,
    deleteAllEvents,
    has_payments,
    openHubspotList,
  } = props

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={4}
    >
      <Grid item xs={12}>
        <Card className={classes.root} elevation={0}>
          <CardContent>
            <div className={classes.details}>
              <div>
                <Typography gutterBottom variant="h5">
                  <NameForm origName={contract.name} onSave={updateName} />
                  <Chip
                    size="small"
                    label={<b>{contract.facility.name}</b>}
                    style={{
                      color: colors.purple_strong,
                      backgroundColor: colors.purple_mild,
                      margin: 5,
                    }}
                  />
                </Typography>
                {!is_pickup && (
                  <Typography
                    className={classes.dateText}
                    color="textSecondary"
                    variant="body1"
                  >
                    {contract.comment}
                  </Typography>
                )}
              </div>
            </div>
            {!is_pickup && contract.customer ? (
              <div className={classes.details} style={{ marginTop: 30 }}>
                <Typography variant="body1">
                  <b>Customer</b>:{' '}
                  <Link
                    href={`/customer/${contract.customer.ID}`}
                    target="_blank"
                  >
                    {contract.customer.first_name} {contract.customer.last_name}
                  </Link>
                </Typography>
              </div>
            ) : null}
            {!is_pickup && (
              <div className={classes.details} style={{ marginTop: 10 }}>
                <Typography variant="body1">
                  <b>Document</b>:{' '}
                  {contract.doc !== '' ? (
                    <>
                      <Link href={contract.doc} target="_blank">
                        {`contract#${contract.ID}.docx`}
                      </Link>
                      {!contract.sent && (
                        <>
                          <IconButton
                            className={classes.root}
                            onClick={() => sendContractDoc()}
                          >
                            <MailIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            className={classes.root}
                            onClick={() => voidContractDoc()}
                          >
                            <DeleteIcon
                              fontSize="small"
                              style={{ color: colors.red_strong }}
                            />
                          </IconButton>
                        </>
                      )}
                    </>
                  ) : (
                    <Button
                      style={{
                        color: colors.blue_strong,
                        backgroundColor: colors.blue_mild,
                      }}
                      onClick={() => generateContractDoc()}
                    >
                      <b>Generate</b>
                    </Button>
                  )}
                </Typography>
              </div>
            )}
            {contract.sent && (
              <div className={classes.details}>
                <Typography variant="body1">
                  <b>Signed</b>:{' '}
                  <Chip
                    size="small"
                    label={<b>{contract.signed ? 'True' : 'False'}</b>}
                    style={{
                      color: contract.signed
                        ? colors.green_strong
                        : colors.red_strong,
                      backgroundColor: contract.signed
                        ? colors.green_mild
                        : colors.red_mild,
                      margin: 5,
                    }}
                  />
                </Typography>
              </div>
            )}
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                <b>Fee</b>:{' '}
                {contract.product_id ? (
                  <span>
                    {contract.product.name}, <b>${contract.fee}</b>
                  </span>
                ) : (
                  <span>
                    Manual fee (hourly), <b>${contract.fee}</b>
                  </span>
                )}
                <IconButton
                  className={classes.root}
                  onClick={() => {
                    getFees(contract.product_type_id, contract.facility_id)
                    let fee_id = null
                    if (contract.product_id) {
                      fee_id = `${contract.product_id};${contract.fee}`
                    } else {
                      fee_id = -1
                    }
                    changeContractDetails('fee_id', fee_id)
                    changeContractDetails('fee', contract.fee)
                    openFeeEditModal()
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Typography>
            </div>
            <div className={classes.details}>
              <Typography variant="body1">
                <b>Event type</b>: <span>{contract.product_type.name}</span>
                <IconButton
                  className={classes.root}
                  onClick={() => {
                    changeContractDetails(
                      'product_type_id',
                      contract.product_type_id
                    )
                    openPtypeEditModal()
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0}>
          <CardHeader subheader="Events" style={{ paddingBottom: 0 }} />
          <CardContent>
            {contract.events.length ? (
              <>
                <div className={classes.details}>
                  <Typography variant="body1">
                    <b>Start</b>:{' '}
                    {unixToDateTime(contract.events[0].start_date).format(
                      'M/D/YYYY h:mmA'
                    )}
                  </Typography>
                </div>
                <div className={classes.details} style={{ marginTop: 10 }}>
                  <Typography variant="body1">
                    <b>End</b>:{' '}
                    {unixToDateTime(
                      contract.events[contract.events.length - 1].end_date
                    ).format('M/D/YYYY h:mmA')}
                  </Typography>
                </div>
              </>
            ) : null}
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                <b>Events</b>: {contract.events.length} ({hours} hours)
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0}>
          <CardHeader subheader="Invoice" style={{ paddingBottom: 0 }} />
          <CardContent>
            {!is_pickup && invoice && (
              <div className={classes.details} style={{ marginTop: 10 }}>
                <Typography variant="body1">
                  <b>Rental total</b>: ${invoice.total_without_tax} ($
                  <u>{invoice.invoiced}</u>{' '}
                  <small style={{ color: 'black' }}>incl. tax</small>)
                </Typography>
              </div>
            )}
            {!is_pickup && invoice && (
              <div className={classes.details} style={{ marginTop: 10 }}>
                <Typography variant="body1">
                  <b>Paid</b>: ${invoice.total_paid_without_tax} ($
                  <u>{invoice.paid}</u>{' '}
                  <small style={{ color: 'black' }}>incl. tax</small>)
                </Typography>
              </div>
            )}
            {!is_pickup && invoice && invoice.discounted && invoice.discounted > 0 &&(
              <div className={classes.details} style={{ marginTop: 10 }}>
                <Typography variant="body1">
                  <b>Discount</b>:{' '}
                </Typography>
                <Chip
                  size="small"
                  label={<b>${invoice.discounted}</b>}
                  style={{
                    color: '#000000',
                    backgroundColor: colors.blue_mild,
                    marginLeft: 5,
                  }}
                />
              </div>
            )}
            {!is_pickup && invoice && (
              <div className={classes.details} style={{ marginTop: 10 }}>
                <Typography variant="body1">
                  <b>Balance</b>:{' '}
                </Typography>
                <Chip
                  size="small"
                  label={<b>${invoice.balance}</b>}
                  style={{
                    color:
                      invoice.balance > 0
                        ? colors.red_strong
                        : colors.green_strong,
                    backgroundColor:
                      invoice.balance > 0 ? colors.red_mild : colors.green_mild,
                    marginLeft: 5,
                  }}
                />
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0}>
          <CardHeader
            subheader={
              <div>
                Actions{' '}
                {has_payments ? (
                  <small>
                    (can&apos;t delete because of existing payments)
                  </small>
                ) : null}
              </div>
            }
            style={{ paddingBottom: 0 }}
          />
          <CardContent>
            {is_pickup && (
              <Button
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  marginBottom: 10,
                }}
                onClick={() => openHubspotList(contract.ID)}
                className="save-btn"
              >
                Create hubspot list
              </Button>
            )}
            <Button
              style={{
                color: !has_payments && colors.red_strong,
                cursor: 'pointer',
                width: '100%',
              }}
              onClick={() => deleteAllEvents(contract.ID)}
              disabled={has_payments}
            >
              Delete all events
            </Button>
            <Button
              style={{
                color: !has_payments && colors.red_strong,
                cursor: 'pointer',
                width: '100%',
                marginTop: 10,
              }}
              onClick={() => deleteContract(contract.ID)}
              disabled={has_payments}
            >
              Delete contract
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => ({
  contract: state.contract.contract,
  hours:
    Math.round(
      state.contract.contract.events.reduce(
        (a, b) => a + (parseInt(b.end_date) - parseInt(b.start_date)) / 3600,
        0
      ) * 10
    ) / 10,
  invoice: state.invoice.invoice,
  is_pickup: state.contract.contract.product_type_id === 5,
  has_payments:
    state.invoice.invoice &&
    state.invoice.invoice.payments.reduce(
      (a, b) => a + b.amount * (b.quantity ? b.quantity : 1),
      0
    ) !== 0,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  openFeeEditModal: () => dispatch(actions.toggleFeeModal({ open: true })),
  getFees: (product_type_id, facility_id) =>
    dispatch(getFees(product_type_id, facility_id)),
  changeContractDetails: (k, v) =>
    dispatch(actions.changeContractDetails({ field: k, value: v })),
  generateContractDoc: () => dispatch(generateContractDoc()),
  voidContractDoc: () => dispatch(voidContractDoc()),
  sendContractDoc: () => dispatch(sendContractDoc()),
  openPtypeEditModal: () => dispatch(actions.togglePtypeModal({ open: true })),
  updateName: name => dispatch(updateName(name)),
  deleteAllEvents: id =>
    dispatch(
      openDeleteModal('all the events the contract includes', () =>
        deleteAllEvents(id)
      )
    ),
  deleteContract: id =>
    dispatch(
      openDeleteModal('contract (and all the events it may include)', () =>
        deleteContract(id)
      )
    ),
  openHubspotList: contract_id =>
    dispatch(openHubspotList({ pickup_ids: [contract_id] })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ContractDetails))

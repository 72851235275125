export const applyFilter = (products, filters) => {
  if (!filters.query && !filters.facility_id && !filters.product_type_id)
    return products
  if (filters.facility_id)
    products = products.filter(
      ({ facility_id }) => filters.facility_id === facility_id
    )
  if (filters.product_type_id)
    products = products.filter(
      ({ gl }) => filters.product_type_id === gl.product_type_id
    )
  if (filters.query) {
    products = products.filter(p => {
      return p.name.toLowerCase().includes(filters.query.toLowerCase())
    })
  }
  return products
}

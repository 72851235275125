import { ERP_BACKEND_URL } from './../consts'
import axios from 'axios'
import { changeLoading, dispatchError } from './../common/actions'
import { actions } from './redux'

export const getResource = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { facility_id } = getState().resources
  return axios
    .get(ERP_BACKEND_URL + `/facility/fields?facility_id=${facility_id}`)
    .then(resp => {
      dispatch(
        actions.updateState({
          key: 'fields',
          value: resp.data.map(f => {
            let _field_type = '5v5 interior'
            if (f.field_type === '5v5' && f.exterior) {
              _field_type = '5v5 exterior'
            }
            if (f.field_type === '6v6') {
              if (f.exterior) {
                _field_type = '6v6 exterior'
              } else {
                _field_type = '6v6 interior'
              }
            }
            if (f.weekday_close_time === 23.99) {
              f.weekday_close_time = 24
            }
            if (f.weekend_close_time === 23.99) {
              f.weekend_close_time = 24
            }
            return { ...f, _field_type: f.field_type, field_type: _field_type }
          }),
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const editField =
  ({ id, body }) =>
  dispatch => {
    dispatch(changeLoading(true))
    return axios
      .put(ERP_BACKEND_URL + `/field/${id}`, body)
      .then(() => {
        dispatch(getResource())
      })
      .catch(e => dispatch(dispatchError(e)))
  }

export const saveFacility = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { facility } = getState().resources,
    body = { ...facility, tax_rate: parseFloat(facility.tax_rate) / 100 }
  let p = null
  if (facility.id) {
    p = axios.put(ERP_BACKEND_URL + `/facility/${facility.id}`, body)
  } else {
    p = axios.post(ERP_BACKEND_URL + `/facility`, body)
  }
  return p
    .then(() => {
      window.location.reload()
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const saveField = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { facility_id, field } = getState().resources,
    body = {
      ...field,
      number: parseInt(field.number),
      weekday_open_time: parseFloat(field.weekday_open_time),
      weekday_close_time: parseFloat(field.weekday_close_time),
      weekend_open_time: parseFloat(field.weekend_open_time),
      weekend_close_time: parseFloat(field.weekend_close_time),
      weekday_peak_time_start: parseFloat(field.weekday_peak_time_start),
      weekday_peak_time_end: parseFloat(field.weekday_peak_time_end),
      weekend_peak_time_start: parseFloat(field.weekend_peak_time_start),
      weekend_peak_time_end: parseFloat(field.weekend_peak_time_end),
      facility_id,
    }
  let p = null
  if (field.id) {
    p = axios.put(ERP_BACKEND_URL + `/field/${field.id}`, body)
  } else {
    p = axios.post(ERP_BACKEND_URL + `/field`, body)
  }
  return p
    .then(() => {
      window.location = `/resources?tab=field&facility_id=${facility_id}`
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const saveTax = type => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { tax } = getState().resources,
    body = {
      ...tax,
      value: parseFloat(tax.value),
    },
    endpoint = type === 'tax' ? '/tax' : '/fee'
  let p = null
  if (tax.id) {
    p = axios.put(ERP_BACKEND_URL + `${endpoint}/${tax.id}`, body)
  } else {
    p = axios.post(ERP_BACKEND_URL + endpoint, body)
  }
  return p
    .then(() => {
      window.location.reload()
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const saveIntegration = type => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { integration } = getState().resources
  let {name, type, open, id, facility_id, ...data} = integration
    const body = {
      name,
      type,
      facility_id,
      data,
    }
  
  let p = null
  if (integration.id) {
    p = axios.put(ERP_BACKEND_URL + `/integration/${integration.id}`, body)
  } else {
    p = axios.post(ERP_BACKEND_URL + '/integration', body)
  }
  return p
    .then(() => {
      window.location.reload()
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getAgeGroups = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + '/age-group')
    .then(resp => {
      dispatch(actions.updateState({ key: 'age_groups', value: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getIntegrations = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + '/integration')
    .then(resp => {
      dispatch(actions.updateState({ key: 'integrations', value: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const saveAgeGroup = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { name } = getState().resources.age_group
  return axios
    .post(ERP_BACKEND_URL + '/age-group', { name })
    .then(() => {
      window.location.reload()
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getProductTypes = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + '/product/types')
    .then(resp => {
      dispatch(actions.updateState({ key: 'product_types', value: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getSeasons = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + '/season')
    .then(resp => {
      dispatch(actions.updateState({ key: 'seasons', value: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const saveGL = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { code, id, product_type_id, season_id } = getState().resources.gl
  let p = null
  if (id) {
    p = axios.put(ERP_BACKEND_URL + `/gl/${id}`, {
      code,
      product_type_id,
      season_id,
    })
  } else {
    p = axios.post(ERP_BACKEND_URL + `/gl`, {
      code,
      product_type_id,
      season_id,
    })
  }
  return p
    .then(() => {
      window.location.reload()
    })
    .catch(e => dispatch(dispatchError(e)))
}
export const saveProductType = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { id, name, category, reporting, gl_code, ranking, color } = getState().resources.product_type
  let p = null
  if (id) {
    p = axios.put(ERP_BACKEND_URL + `/product/types/${id}`, {
      name, 
      category, 
      reporting, 
      gl_code, 
      ranking, 
      color,
    })
  } 
  return p
    .then(() => {
      window.location.reload()
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}
export const getFieldTypes = () => dispatch => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + '/field-type')
    .then(resp => {
      dispatch(actions.updateState({ key: 'field_types', value: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const saveFieldType = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { field_type } = getState().resources
  const request = field_type.id
    ? axios.put(ERP_BACKEND_URL + `/field-type/${field_type.id}`, field_type)
    : axios.post(ERP_BACKEND_URL + `/field-type`, field_type)
  return request
    .then(() => {
      window.location.reload()
    })
    .catch(e => dispatch(dispatchError(e)))
}

import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import { niceNumber, exportToCSV, unixToDate } from 'helper'
import { colors } from 'consts'
import { changeLoading } from 'common/actions'
import { deleteSuspension } from '../../actions'

const Suspensions = props => {
  const { suspensions, deleteSuspension } = props
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={4}
    >
      <Grid item xs={12}>
        <Paper elevation={0}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  From
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Until
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Fault
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Note
                </TableCell>
                <TableCell
                  style={{ textAlign: 'center' }}
                  className="table-header"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suspensions.map(suspension => (
                <TableRow key={suspension.ID}>
                  <TableCell style={{ textAlign: 'center' }}>
                    {suspension.start}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {suspension.end}
                  </TableCell>

                  <TableCell style={{ textAlign: 'center' }}>
                    {suspension.foul}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {suspension.notes}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <IconButton onClick={() => deleteSuspension(suspension.ID)}>
                      <DeleteIcon
                        style={{
                          color: colors.red_strong,
                        }}
                        fontSize="small"
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => {
  const suspensions =
    state.customer.customer.suspensions?.map(suspension => {
      return {
        ...suspension,
        start: unixToDate(suspension.from).format('MMMM D, YYYY'),
        end: unixToDate(suspension.until).format('MMMM D, YYYY'),
      }
    }) || []

  return {
    suspensions,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeLoading: loading => dispatch(dispatch(changeLoading(loading))),
  deleteSuspension: id => dispatch(deleteSuspension(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Suspensions)

import api from 'api'
import axios from 'axios'
import moment from 'moment'
import { actions } from './redux'
import { changeLoading, dispatchError } from 'common/actions'
import { unixToDateTime, momentDateTimeToUnix, momentDateToUnix } from 'helper'
import { calendarSlice } from './../calendar/redux'
import { createOrUpdateCustomerReturn } from 'customer/actions'
import { actions as customerActions } from 'customer/redux'
import { ERP_BACKEND_URL } from 'consts'

export const getClasses = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { facility_id, start_date, end_date } = getState().classes.filters
  let url = ERP_BACKEND_URL + `/class?`
  if (facility_id)
    url = `${url}&facility_ids=${facility_id.map(f => f.value).join(',')}`
  if (start_date) url = `${url}&start=${start_date}`
  if (end_date) url = `${url}&end=${end_date}`
  return axios
    .get(url)
    .then(resp => {
      dispatch(
        actions.getClasses({
          classes: resp.data
            .map(c => ({
              ...c,
              start_date_parsed: unixToDateTime(c.start_date).format(
                'MM/DD/YYYY'
              ),
              end_date_parsed: unixToDateTime(c.end_date).format('MM/DD/YYYY'),
            }))
            .sort((a, b) => a.start_date - b.start_date),
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getUpcomingClasses = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { facility_id } = getState().classes.classInfo
  let url = ERP_BACKEND_URL + `/class/active?facility_id=${facility_id}`
  return axios
    .get(url)
    .then(resp => {
      dispatch(actions.getClasses({ classes: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}
export const getUpcomingClassesByFacility =
  facilityID => (dispatch, getState) => {
    dispatch(changeLoading(true))
    let url = ERP_BACKEND_URL + `/class/active?facility_id=${facilityID}`
    return axios
      .get(url)
      .then(resp => {
        dispatch(actions.getClasses({ classes: resp.data }))
        dispatch(changeLoading(false))
      })
      .catch(e => dispatch(dispatchError(e)))
  }

export const changeClass = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  dispatch(actions.editChangeClass({ key: 'open', value: false }))
  const {
    classInfo: { ID },
    change_class: { new_class_id, attendee_id },
  } = getState().classes
  let url =
    ERP_BACKEND_URL +
    `/class/registration/${attendee_id}?class_id=${new_class_id}`
  return axios
    .put(url)
    .then(resp => {
      dispatch(_getClassDetails(ID))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

const _getClassDetails = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return api.classes
    .getByID(id)
    .then(classInfo => {
      dispatch(actions.getClass({ classInfo }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getClassDetails = id => (dispatch, getState) =>
  dispatch(_getClassDetails(id))

export const createClass = () => async (dispatch, getState) => {
  try {
    dispatch(changeLoading(true))
    const {
      form: {
        name,
        description,
        start_date,
        end_date,
        registration_start_date,
        registration_end_date,
        available_online,
        max_kids,
        fee,
        require_membership,
        accepts_free_trial,
        new_product_id,
        facility_id,
        is_camp,
        properties,
      },
    } = getState().classes

    const payload = {
      name,
      description,
      start_date,
      end_date,
      registration_start_date,
      registration_end_date,
      available_online,
      accepts_free_trial,
      require_membership,
      max_kids: parseInt(max_kids),
      new_product_id,
      facility_id,
      is_camp,
      properties,
      is_container: true,
    }
    const createdClass = await api.classes.create(payload)
    window.location = `/classes/${createdClass.ID}`
  } catch (e) {
    dispatch(dispatchError(e))
    dispatch(changeLoading(false))
  }
}

export const cloneClass = () => async (dispatch, getState) => {
  try {
    dispatch(changeLoading(true))

    const {
      form: {
        name,
        description,
        start_date,
        end_date,
        registration_start_date,
        registration_end_date,
        available_online,
        max_kids,
        require_membership,
        accepts_free_trial,
        new_product_id,
        facility_id,
        is_camp,
        properties,
        is_container,
        class_parent_id,
      },
    } = getState().classes

    const payload = {
      name,
      description,
      start_date,
      end_date,
      registration_start_date,
      registration_end_date,
      available_online,
      accepts_free_trial,
      require_membership,
      max_kids: parseInt(max_kids),
      new_product_id,
      facility_id,
      is_camp,
      properties,
      is_container,
      class_parent_id,
      CreatedAt: new Date(),
      UpdatedAt: new Date(),
    }
    const createdClass = await api.classes.create(payload)
    window.location = `/classes/${createdClass.ID}`
  } catch (e) {
    dispatch(dispatchError(e))
    dispatch(changeLoading(false))
  }
}

export const updateClassDetails = () => async (dispatch, getState) => {
  dispatch(changeLoading(true))
  const {
    classInfo: { ID },
    form: {
      name,
      description,
      start_date,
      end_date,
      registration_start_date,
      accepts_free_trial,
      require_membership,
      registration_end_date,
      available_online,
      max_kids,
      new_product_id,
      is_camp,
      properties,
    },
  } = getState().classes
  const payload = {
    name,
    description,
    start_date,
    end_date,
    registration_start_date,
    accepts_free_trial,
    require_membership,
    registration_end_date,
    available_online,
    max_kids: parseInt(max_kids),
    new_product_id,
    is_camp,
    properties,
  }
  return api.classes
    .update(ID, payload)
    .then(() => {
      dispatch(_getClassDetails(ID))
    })
    .catch(e => {
      dispatch(dispatchError(e))
      dispatch(changeLoading(false))
    })
}

export const deleteClass =
  (id, reload_class_container) => async (dispatch, getState) => {
    try {
      dispatch(changeLoading(true))
      await api.classes.delete(id)
      dispatch(getClasses())
      dispatch(changeLoading(false))
      dispatch(actions.openAlert({ message: 'Class deleted successfully' }))
      if (reload_class_container) {
        dispatch(_getClassDetails(getState().classes.classInfo.ID))
      } else {
        window.location = '/classes'
      }
    } catch (e) {
      dispatch(changeLoading(false))
      dispatch(dispatchError(e))
    }
  }

export const updateClass = payload => async (dispatch, getState) => {
  try {
    const {
      ID,
      name,
      available_online,
      accepts_free_trial,
      require_membership,
      properties,
    } = getState().classes.classInfo
    let data = {
      name,
      available_online,
      accepts_free_trial,
      require_membership,
      properties,
      ...payload,
    }
    await api.classes.update(ID, data)
    dispatch(getClassDetails(ID))
    if (payload.product_id) dispatch(actions.toggleFeeModal({ open: false }))
  } catch (e) {
    // dispatch(changeLoading(false))
    dispatch(actions.openAlert({ message: 'Update successful' }))
    dispatch(dispatchError(e))
  }
}

export const updateName = name => async (dispatch, getState) => {
  dispatch(updateClass({ name }))
}

export const updateFee = () => (dispatch, getState) => {
  const {
    form: { fee, new_product_id },
  } = getState().classes
  dispatch(
    updateClass({ product_id: Number(fee.split(';')[0]), new_product_id })
  )
  dispatch(actions.toggleFeeModal({ open: false }))
}
export const updateAttendee = () => (dispatch, getState) => {
  let attendee = getState().classes.attendee
  if (attendee) {
    axios
      .put(ERP_BACKEND_URL + `/attendee/${attendee.ID}`, attendee)
      .then(() => {
        window.location.reload()
      })
      .catch(e => dispatch(dispatchError(e)))
  }
  dispatch(actions.toggleUniformSizeModal({ open: false }))
  dispatch(getClassDetails(id))
}

export const editClassEvent = () => async (dispatch, getState) => {
  try {
    dispatch(changeLoading(true))
    const classInfo = getState().classes.classInfo,
      event_details = getState().calendar.event_details,
      moment_start = unixToDateTime(event_details.start_time),
      moment_end = unixToDateTime(event_details.end_time),
      classInfo_id = classInfo.ID,
      comment = classInfo.name,
      body = {
        field_id: event_details.field_id,
        product_type_id: event_details.product_type_id || 10,
        start_date: momentDateTimeToUnix(moment_start),
        end_date: momentDateTimeToUnix(moment_end),
        comment,
        field: getState().calendar.fields.find(
          f => f.ID === event_details.field_id
        ),
        product_type: getState().common.product_types.find(
          p => p.ID === event_details.product_type_id
        ),
      }
    await api.event.update(event_details.ID, body)
    dispatch(getClassDetails(classInfo_id))
    dispatch(changeLoading(false))
    dispatch(actions.toggleEventCreationModal({ open: false }))
  } catch (e) {
    dispatch(changeLoading(false))
    dispatch(dispatchError(e))
  }
}

export const deleteEvent = event => (dispatch, getState) => {
  return api.event.delete(event.ID).then(() => {
    dispatch(actions.deleteEvent({ id: event.ID }))
  })
}

export const openEventEditModal = event => (dispatch, getState) => {
  dispatch(calendarSlice.actions.getEvent({ event }))
  const { classInfo } = getState().classes
  dispatch(
    calendarSlice.actions.changeEventDetails({
      field: 'product_id',
      value: classInfo.product_id,
    })
  )
  dispatch(
    calendarSlice.actions.changeEventDetails({
      field: 'product_type',
      value: classInfo.product.name,
    })
  )
  dispatch(actions.toggleEventEditModal({ open: true }))
}

export const broadcastMessage =
  (subject, html, recipients, facility_id) => async (dispatch, getState) => {
    try {
      dispatch(changeLoading(true))
      await api.broadcast.send({
        subject,
        html,
        recipients,
        facility_id,
      })
      dispatch(changeLoading(false))
      dispatch(actions.openAlert({ message: 'Broadcast message sent' }))
      dispatch(actions.updateBroadcastMessage({ open: false }))
    } catch (e) {
      dispatch(changeLoading(false))
      dispatch(dispatchError(e))
    }
  }

export const getFees =
  (product_type_id, facility_id) => (dispatch, getState) => {
    dispatch(changeLoading(true))
    let current_facility_id = facility_id
    if (!current_facility_id) {
      current_facility_id = getState().user.facility_id
    }
    return api.product
      .getPricesByID(product_type_id || 10, current_facility_id)
      .then(prices => {
        dispatch(
          actions.getFees({
            fees: prices.map(fee => ({
              ...fee,
              id: `${fee.product_id};${fee.product.individual_price}`,
            })),
          })
        )
        dispatch(changeLoading(false))
      })
      .catch(e => dispatch(dispatchError(e)))
  }

export const addClassEvent = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { classInfo } = getState().classes,
    requests = [],
    events = [],
    event_details = getState().calendar.event_details,
    product_type_id = classInfo.newProduct?.gl?.product_type_id || 10,
    existing_event = classInfo.events[0]
  if (!event_details.repeat_events.length) {
    // Single event, otherwise it's already included in the repeat events
    events.push({
      class_id: classInfo.ID,
      field_id: event_details.field_id,
      product_type_id: product_type_id,
      start_date: event_details.start_time,
      end_date: event_details.end_time,
      comment: classInfo.name,
      quota: existing_event && existing_event.quota,
    })
  }
  if (event_details.repeat_events.filter(e => !e.is_ok).length > 0) {
    return Promise.reject('Please check that all events have free spots')
  }
  event_details.repeat_events.forEach(event_detail => {
    events.push({
      class_id: classInfo.ID,
      field_id: event_detail.field_id,
      product_type_id: product_type_id,
      start_date: event_detail.start_date,
      end_date: event_detail.end_date,
      comment: classInfo.name,
      quota: existing_event && existing_event.quota,
    })
  })

  if (events.length) {
    events.forEach(body => requests.push(api.event.create(body)))
  }

  return Promise.all(requests)
    .then(() => {
      dispatch(getClassDetails(classInfo.ID))
      dispatch(actions.toggleClassEventModal(false))
      dispatch(calendarSlice.actions.clearEvents())
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const addParticipant = (child, clearState) => (dispatch, getState) => {
  if (!child.first_name || !child.last_name)
    return dispatch(dispatchError({ message: "Fill in participant's details" }))
  dispatch(changeLoading(true))
  const { customer, creation } = getState().customer,
    facility_id = getState().user.facility_id,
    classInfo = getState().classes.classInfo

  let { first_name, last_name, email, phone } = creation,
    p = Promise.resolve(customer?.ID),
    old_customer = true,
    parent_id = null
  if (first_name && last_name && email) {
    let customer_body = {
      first_name,
      last_name,
      email,
      facility_id,
      customer_type_id: 1,
    }
    if (phone) customer_body.hubspot = { phone }
    old_customer = false
    p = createOrUpdateCustomerReturn(customer_body)
  }
  p.then(id => {
    if (!id || typeof id !== 'number') throw new Error('Error adding parent')
    parent_id = id
    email = old_customer ? customer.email : email
    return createOrUpdateCustomerReturn({
      ...child,
      email,
      parent_email: email,
      facility_id,
      customer_type_id: 1,
      is_kid: true,
    })
  })
    .then(customer_id => {
      if (!customer_id || typeof customer_id !== 'number')
        throw new Error('Error adding participant')
      return api.classes.addParticipant({
        parent_id,
        price: child.price
          ? parseInt(child.price)
          : classInfo?.product?.individual_price,
        attendee: {
          class_id: classInfo.ID,
          customer_id,
        },
      })
    })
    .then(() => {
      dispatch(getClassDetails(classInfo.ID))
      dispatch(actions.toggleAddParticipantModal(false))
      dispatch(changeLoading(false))
      clearState({ first_name: '', last_name: '', price: '' })
      dispatch(customerActions.clearNewCustomerDetails())
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const updateQuota = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const events = getState().classes.classInfo.events,
    classID = getState().classes.classInfo.ID,
    quota = parseInt(getState().classes.form.quota)
  return Promise.all(
    events.map(e =>
      axios.put(ERP_BACKEND_URL + '/event/' + e.ID, {
        quota,
      })
    )
  )
    .then(() => {
      dispatch(actions.toggleQuotaEdit({ open: false }))
      dispatch(_getClassDetails(classID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const deleteClassRegistration = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().classes.classInfo
  return axios
    .delete(ERP_BACKEND_URL + `/class/registration/${id}`)
    .then(() => {
      dispatch(_getClassDetails(ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}
export const deleteWaitlist = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().classes.classInfo
  return axios
    .delete(ERP_BACKEND_URL + `/waitlist/${id}`)
    .then(() => {
      dispatch(_getClassDetails(ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const convertWaitlistToAttendee = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().classes.classInfo
  return axios
    .put(ERP_BACKEND_URL + `/waitlist/${id}`)
    .then(() => {
      dispatch(_getClassDetails(ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const deleteTemporaryAssistent = id => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const { ID } = getState().classes.classInfo
  return axios
    .delete(ERP_BACKEND_URL + `/assistant/${id}`)
    .then(() => {
      dispatch(_getClassDetails(ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const createClassDivision = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  const class_details = getState().classes.classInfo,
    { name } = getState().classes.new_division

  const body = {
    name: `${name}`,
    description: class_details.description,
    start_date: class_details.start_date,
    end_date: class_details.end_date,
    registration_start_date: class_details.registration_start_date,
    registration_end_date: class_details.registration_end_date,
    available_online: class_details.available_online,
    max_kids: class_details.max_kids,
    fee: class_details.fee,
    require_membership: class_details.require_membership,
    accepts_free_trial: class_details.accepts_free_trial,
    new_product_id: class_details.new_product_id,
    product_id: class_details.product_id,
    is_camp: class_details.is_camp,
    properties: class_details.properties,
    is_container: false,
    class_parent_id: class_details.ID,
    facility_id: class_details.facility_id,
  }
  return axios
    .post(ERP_BACKEND_URL + '/class', body)
    .then(resp => {
      dispatch(actions.updateNewDialog({ key: 'open', value: false }))
      dispatch(actions.updateNewDialog({ key: 'name', value: '' }))
      dispatch(getClassDetails(class_details.ID))
    })
    .catch(e => dispatch(dispatchError(e)))
}

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import NewGLDialog from 'resources/components/NewGLDialog'
import NewProductColorDialog from 'resources/components/NewProductColorDialog'
import { Button } from '@material-ui/core'

import { getGLs } from 'product/actions'
import { actions } from 'resources/redux'

const sortIcon = <ArrowDownward />

const columns = (setGL, setProductColor) =>
  memoize(() => [
    {
      name: 'ID',
      selector: 'id',
      center: true,
      cell: gl => gl.ID,
    },
    {
      name: 'Code',
      selector: 'code',
      center: true,
      cell: gl => gl.code,
    },
    {
      name: 'Product type',
      selector: 'product_type',
      center: true,
      cell: gl => gl.product_type.name,
    },
    {
      name: 'Product color',
      selector: 'product_type',
      center: true,
      cell: gl => {
        if (gl.product_type.color != ''){
          return (
            <>
              <div  onClick={() => setProductColor(gl.product_type)} style={{backgroundColor: gl.product_type.color, cursor:'pointer', height:'50%', width: '50%', border:'solid black'}}/>
            </>
          )
        }
        return (
            <>
              <div  onClick={() => setProductColor(gl.product_type)} style={{backgroundColor: gl.product_type.color, color:'red', cursor:'pointer'}}>Please set a color</div>
            </>
        )
      },
    },
    {
      name: 'Season',
      selector: 'season',
      center: true,
      cell: gl => gl.season.name,
    },
    {
      name: 'Actions',
      selector: 'actions',
      center: true,
      cell: gl => (
        <>
          <IconButton onClick={() => setGL(gl)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </>
      ),
    },
  ])

const ResourceManager = ({ setGL, setProductColor }) => {
  const facilities = useSelector(state => state.product.gls),
    dispatch = useDispatch(),
    openDialog = () =>
      dispatch(actions.updateGL({ key: 'open', value: true, reset: true }))
  useEffect(() => {
    dispatch(getGLs())
  }, [])
  return (
    <div>
      <NewGLDialog />
      <NewProductColorDialog/>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12}>
          <Button onClick={openDialog} variant="outlined">
            Add new
          </Button>
          <Paper elevation={0}>
            <DataTable
              columns={columns(setGL, setProductColor)()}
              data={facilities}
              highlightOnHover
              defaultSortField="ID"
              defaultSortAsc={true}
              sortIcon={sortIcon}
              fixedHeader
              customStyles={{
                rows: {
                  style: {
                    textAlign: 'center',
                  },
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.resources,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setGL: gl => {
    dispatch(actions.updateGL({ key: 'id', value: gl.ID }))
    dispatch(actions.updateGL({ key: 'code', value: gl.code }))
    dispatch(
      actions.updateGL({ key: 'product_type_id', value: gl.product_type_id })
    )
    dispatch(actions.updateGL({ key: 'season_id', value: gl.season_id }))
    dispatch(actions.updateGL({ key: 'open', value: true }))
  },
  setProductColor: product_type =>{
    dispatch(actions.updateProductType({ key: 'id', value: product_type.ID }))
    dispatch(actions.updateProductType({ key: 'name', value: product_type.name }))
    dispatch(actions.updateProductType({ key: 'category', value: product_type.category }))
    dispatch(actions.updateProductType({ key: 'reporting', value: product_type.reporting }))
    dispatch(actions.updateProductType({ key: 'gl_code', value: product_type.gl_code }))
    dispatch(actions.updateProductType({ key: 'ranking', value: product_type.ranking }))
    dispatch(actions.updateProductType({ key: 'color', color: product_type.color }))
    dispatch(actions.updateProductType({ key: 'open', value: true }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceManager)

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Select from 'react-select'

import FastTextField from 'common/components/FastTextField'

import { saveIntegration } from 'resources/actions'
import { actions } from 'resources/redux'
const integration_types = [
  {
    label: 'hubspot',
    value: 1,
  },
  {
    label: 'acuity',
    value: 2,
  },
  {
    label: 'square',
    value: 3,
  },
]
const integration_fields = {
  hubspot: ['api_key'],
  acuity: ['api_key', 'user_id', 'destination_id'],
}
const all_facilities = {
  label: 'ALL',
  value:999999,
}
const NewIntegrationDialog = () => {
  const { open, name, facility_id, type, user_id, api_key, api_secret } = useSelector(
      state => state.resources.integration
    ),
    dispatch = useDispatch(),
    updateIntegration = (k, v) =>
      dispatch(actions.updateIntegration({ key: k, value: v })),
    handleClose = () => updateIntegration('open', false),
    _saveIntegration = () => dispatch(saveIntegration())
  let shown_fields = integration_fields[type]
  let facilities = [all_facilities, ...useSelector(
    state => state.common.filter_facilities
  )]
  console.log(facilities)
  //facilities.push(all_facilities)
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Create Integration
        <IconButton
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={7}>
            <FastTextField
              label="Name *"
              variant="outlined"
              value={name}
              onChange={e => updateIntegration('name', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={7}>
            <Select
              options={integration_types}
              placeholder="Integration type"
              value={type && integration_types.find(p => p.label === type)}
              onChange={v => updateIntegration('type', v.label)}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item xs={7}>
            <Select
              options={facilities}
              placeholder="Facility"
              value={facility_id !== 'undefined' && facilities.find(p => p.value === facility_id)}
              onChange={v => updateIntegration('facility_id', v.value)}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Grid>
          {shown_fields && shown_fields.indexOf('user_id') > -1 && (
            <Grid item xs={7}>
              <FastTextField
                label="user_id"
                variant="outlined"
                value={user_id}
                onChange={e => updateIntegration('user_id', e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}
          {shown_fields && shown_fields.indexOf('api_key') > -1 && (
            <Grid item xs={7}>
              <FastTextField
                label="api_key"
                variant="outlined"
                value={api_key}
                onChange={e => updateIntegration('api_key', e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}
          {shown_fields && shown_fields.indexOf('api_secret') > -1 && (
            <Grid item xs={7}>
              <FastTextField
                label="api_secret"
                variant="outlined"
                value={api_secret}
                onChange={e => updateIntegration('api_secret', e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}
           {shown_fields && shown_fields.indexOf('destination_id') > -1 && (
            <Grid item xs={7}>
              <FastTextField
                label="destination_id"
                variant="outlined"
                value={api_secret}
                onChange={e => updateIntegration('destination_id', e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => _saveIntegration()} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewIntegrationDialog

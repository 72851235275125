import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from 'common/components/FastTextField'
import Select from 'react-select'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import {
  getContracts,
  toggleCreationModal,
  searchContracts,
} from 'contract/actions'
import { actions } from 'contract/redux'

import { actions as customerActions } from 'customer/redux'
import { unixToDateTime, momentDateTimeSecondsToUnix } from 'helper'

const product_types = [
  { label: 'Adult Rental - Block-Booking', value: 2 },
  { label: 'Youth Rental - Contract', value: 7 },
  { label: 'Events', value: 11 },
]

const Filters = ({
  updateSearch,
  getContracts,
  openCreationModal,
  searchContracts,
  is_admin,
  facilities,
  facility_ids,
  product_type_id,
  last_event_date,
}) => {
  const [showSearch, setShowSearch] = useState(false)
  return (
    <Grid container spacing={2} alignItems="center" justify="flex-start">
      <Grid item xs={2} style={{ textAlign: 'center', paddingTop: 15 }}>
        <Select
          options={facilities}
          placeholder="Facility"
          isClearable={true}
          isDisabled={!is_admin}
          isMulti
          value={
            facility_ids.length &&
            facilities.filter(p => facility_ids.indexOf(p.value) !== -1)
          }
          onChange={v =>
            updateSearch('facility_ids', v ? v.map(f => f.value) : [])
          }
        />
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'center', paddingTop: 15 }}>
        <Select
          options={product_types}
          placeholder="Product"
          isClearable={true}
          value={
            product_type_id &&
            product_types.find(p => p.value === product_type_id)
          }
          onChange={v => updateSearch('product_type_id', v?.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="standard"
            format="MM/dd/yyyy"
            id="date-picker-inline"
            label="Last event date after"
            value={last_event_date && unixToDateTime(last_event_date).toDate()}
            onChange={d => {
              if (d) {
                updateSearch(
                  'last_event_date',
                  momentDateTimeSecondsToUnix(moment(d).hours(23).minutes(59))
                )
              } else {
                updateSearch('last_event_date', null)
              }
            }}
            autoOk
            clearable
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={2}>
        <Button
          className="save-btn"
          style={{ width: '100%' }}
          onClick={searchContracts}
        >
          Search
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Button
          className="save-btn"
          style={{ width: '100%' }}
          onClick={() => setShowSearch(!showSearch)}
        >
          Search by name
        </Button>
      </Grid>
      <Grid item xs={1}>
        <Button
          variant="outlined"
          color="secondary"
          style={{ width: '100%' }}
          onClick={() => openCreationModal()}
        >
          Create
        </Button>
      </Grid>
      {showSearch && (
        <>
          <Grid item xs={7} style={{ textAlign: 'center' }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Search..."
              onChange={e => {
                updateSearch('query', e.target.value)
                if (e.target.value.length === 0) {
                  getContracts()
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              className="save-btn"
              style={{ width: '100%' }}
              onClick={() => searchContracts(true)}
            >
              Search by customer
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              className="save-btn"
              style={{ width: '100%' }}
              onClick={() => searchContracts(false)}
            >
              Search by contract
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state.contract.search,
  facilities: state.common.filter_facilities,
  is_admin: state.user.is_admin,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSearch: (k, v) => dispatch(actions.updateSearch({ key: k, value: v })),
  editSearchQuery: search_query =>
    dispatch(actions.editSearchQuery({ search_query })),
  openCreationModal: () => {
    dispatch(customerActions.clearNewCustomerDetails())
    dispatch(toggleCreationModal({ open: true }))
  },
  getContracts: () => dispatch(getContracts()),
  searchContracts: () => dispatch(searchContracts()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Filters)

import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Chip from '@material-ui/core/Chip'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'

import { getTrials } from './../actions'
import { colors } from 'consts'
import { niceNumber, exportToCSV } from 'helper'
import { actions } from './../redux'

const sortIcon = <ArrowDownward />

const columns = memoize(() => [
  {
    name: 'Facility',
    selector: 'facility',
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: 'Class Name',
    selector: 'class',
    sortable: true,
    center: true,
    cell: row =>
    row.hide_league ? null : (
      <Link href={`/classes/${row.class_id}`} target="_blank">
        {row.class}
      </Link>
    ),
  },
  {
    name: 'Child name',
    selector: 'customer',
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: 'Trial date',
    selector: 'trial_date',
    sortable: true,
    center: true,
  },
  {
    name: 'Incription date',
    selector: 'enrolled_at',
    sortable: true,
    center: true,
  },
  {
    name: 'Event ID',
    selector: 'class',
    sortable: true,
    center: true,
    cell: row =>
    row.hide_league ? null : (
      <Link href={`/calendar/event/${row.event_id}`} target="_blank">
        {row.event_id}
      </Link>
    ),
  },
])

const customStyles = {
  rows: {
    style: {
      minHeight: '100px', // override the row height
    },
  },
}

class TrialsReport extends React.Component {
  componentDidMount() {
    this.props.getTrials()
    this.props.changeReportType('trials')
  }

  render() {
    const { trials, totals } = this.props
    console.log(trials)
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          Total: <b>{trials.length}</b>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: '1%' }}>
          <DataTable
              title={
                <div>
                  <span>Free Trials</span>
                  {this.props.is_admin && (
                    <Button
                      className="save-btn"
                      style={{ marginLeft: 10 }}
                      onClick={() => exportToCSV(trials, 'Teams')}
                    >
                      Export
                    </Button>
                  )}
                </div>
              }
              columns={columns()}
              data={trials}
              highlightOnHover
              defaultSortField="date"
              defaultSortAsc={false}
              sortIcon={sortIcon}
              pagination
              striped
              fixedHeader
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              customStyles={customStyles}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.reporting.filter,
  trials: state.reporting.trials,
  totals: state.reporting.teams_total,
  is_admin: state.user.user_type === 'admin',
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeReportType: report => dispatch(actions.changeReportType(report)),
  getTrials: p => dispatch(getTrials(p)),
  updateFilter: (k, v) =>
    dispatch(actions.updateFilter({ field: k, value: v })),
})

export default connect(mapStateToProps, mapDispatchToProps)(TrialsReport)

import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'
import StartDateIcon from '@material-ui/icons/Today'
import LocationIcon from '@material-ui/icons/LocationOn'
import DetailsIcon from '@material-ui/icons/AccountBox'
import LeagueMenu from './../components/LeagueMenu'

import TournamentPage from './../components/pages/Tournament'
import BroadcastMessage from './../components/BroadcastMessage'
import SettingsPage from './../components/pages/Settings'

import { getLeagueDetails, getAgeGroups } from './../actions'
import { getNewProducts, getProducts } from './../../product/actions'
import { getFacilities } from './../../common/actions'

class Details extends React.Component {
  componentDidMount() {
    this.props.getTournamentDetails(this.props.match.params.id)
    this.props.getNewProducts()
    this.props.getProducts()
    this.props.getFacilities()
    this.props.getAgeGroups()
  }

  render() {
    return (
      <div>
        <BroadcastMessage />
        {this.props.league && (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            style={{ paddingTop: '1%' }}
            spacing={4}
          >
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant="h3">{this.props.league.name}</Typography>
              <Typography
                variant="subtitle1"
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                <LocationIcon color="action" style={{ marginRight: '10px' }} />{' '}
                {this.props.league.facility.name}
              </Typography>
              <br />
              <Typography
                variant="subtitle1"
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                <DetailsIcon color="action" style={{ marginRight: '10px' }} />{' '}
                {this.props.league.age_group.name} - {this.props.league.gender}
              </Typography>
              <br />
              <Typography
                variant="subtitle1"
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                <StartDateIcon color="action" style={{ marginRight: '10px' }} />{' '}
                {this.props.league.start_date_parsed} -{' '}
                {this.props.league.end_date_parsed}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LeagueMenu is_tournament />
            </Grid>
            <Grid item xs={12}>
              {this.props.league_tab === 'teams' && <TournamentPage />}
              {this.props.league_tab === 'settings' && (
                <SettingsPage is_tournament />
              )}
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  league: state.league.league,
  league_tab: state.league.league_tab,
  e_league: state.league.league && state.league.league.is_e_league,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getTournamentDetails: id => dispatch(getLeagueDetails(id, true)),
  getNewProducts: () => dispatch(getNewProducts()),
  getProducts: () => dispatch(getProducts()),
  getFacilities: () => dispatch(getFacilities()),
  getAgeGroups: () => dispatch(getAgeGroups()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Details)

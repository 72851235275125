import { ERP_BACKEND_URL, ERP_BACKEND_NO_USER_URL } from './../consts'
import axios from 'axios'
import moment from 'moment'
import { changeLoading, dispatchError } from './../common/actions'
import { actions } from './redux'
import { unixToDateTime, momentDateTimeToUnix } from 'helper'

export const getSalesReport =
  (wow, specified_filters) => (dispatch, getState) => {
    let filters = getState().reporting.filter
    if (specified_filters) {
      filters = specified_filters
    }
    if (!filters.start_date) {
      dispatch(dispatchError('Missing start date'))
    }
    if (!filters.end_date) {
      dispatch(dispatchError('Missing end date'))
    }
    const start_date_unix = filters.start_date
    const end_date_unix = filters.end_date
    let query = {
      start_date: start_date_unix,
      end_date: end_date_unix,
      // include_credit: true,
    }
    if (filters.facility_id.length) {
      query.facility_ids = filters.facility_id // Note: array
    }
    if (filters.product_type_id.length) {
      query.product_type_ids = filters.product_type_id // Note: array
    }
    if (filters.payment_type.length) {
      query.payment_types = filters.payment_type
    }
    if (filters.exclude_tax) {
      query.exclude_tax = true
    }
    dispatch(changeLoading(true))
    return axios
      .post(ERP_BACKEND_URL + `/report/sales/new`, {
        query,
      })
      .then(resp => {
        if (wow) {
          dispatch(
            actions.getSalesReportWow({
              data: resp.data,
            })
          )
        } else {
          dispatch(
            actions.getSalesReport({
              data: resp.data,
            })
          )
          const wow_end = filters.start_date,
            wow_start = wow_end - (filters.end_date - filters.start_date)
          return dispatch(
            getSalesReport(true, {
              ...filters,
              start_date: wow_start,
              end_date: wow_end,
            })
          )
        }
        dispatch(changeLoading(false))
      })
      .catch(e => dispatch(dispatchError(e)))
  }

export const getInvoiceReport =
  (wow, specified_filters) => (dispatch, getState) => {
    let filters = getState().reporting.filter
    if (specified_filters) {
      filters = specified_filters
    }
    if (!filters.start_date) {
      dispatch(dispatchError('Missing start date'))
    }
    if (!filters.end_date) {
      dispatch(dispatchError('Missing end date'))
    }
    let query = {
      event_start_date: filters.start_date,
      event_end_date: filters.end_date + 3600 * 23 + 59 * 60 + 59,
    }
    if (filters.facility_id.length) {
      query.facility_ids = filters.facility_id
    }
    if (filters.product_type_id.length) {
      query.product_type_ids = filters.product_type_id
    }
    if (filters.payment_type.length) {
      query.payment_types = filters.payment_type
    }
    dispatch(changeLoading(true))
    return axios
      .post(ERP_BACKEND_URL + `/report/invoices`, {
        query,
      })
      .then(resp => {
        if (wow) {
          dispatch(
            actions.getInvoiceReportWow({
              data: resp.data,
            })
          )
        } else {
          dispatch(
            actions.getInvoiceReport({
              data: resp.data,
            })
          )
          const wow_end = filters.start_date,
            wow_start = wow_end - (filters.end_date - filters.start_date)
          return dispatch(
            getInvoiceReport(true, {
              ...filters,
              start_date: wow_start,
              end_date: wow_end,
            })
          )
        }
        dispatch(changeLoading(false))
      })
      .catch(e => dispatch(dispatchError(e)))
  }

export const getOutstandingInvoiceReport = () => (dispatch, getState) => {
  let filters = getState().reporting.filter
  if (!filters.start_date) {
    dispatch(dispatchError('Missing start date'))
  }
  if (!filters.end_date) {
    dispatch(dispatchError('Missing end date'))
  }
  let query = {
    invoice_start_date: filters.start_date,
    invoice_end_date: filters.end_date,
  }
  if (filters.facility_id.length) {
    query.facility_ids = filters.facility_id
  }
  if (filters.product_type_id.length) {
    query.product_type_ids = filters.product_type_id
  }
  if (filters.payment_type.length) {
    query.payment_types = filters.payment_type
  }
  dispatch(changeLoading(true))
  return axios
    .post(ERP_BACKEND_URL + `/report/invoice/outstanding`, {
      query,
    })
    .then(resp => {
      dispatch(
        actions.getInvoiceReport({
          data: resp.data,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getEventsReport =
  (wow, specified_filters) => (dispatch, getState) => {
    let filters = getState().reporting.filter
    if (specified_filters) {
      filters = specified_filters
    }
    if (!filters.start_date) {
      dispatch(dispatchError('Missing start date'))
    }
    if (!filters.end_date) {
      dispatch(dispatchError('Missing end date'))
    }
    let query = {
      event_start_date: filters.start_date,
      event_end_date: filters.end_date,
    }
    if (filters.facility_id.length) {
      query.facility_id = filters.facility_id[0]
    }
    if (filters.product_type_id.length) {
      query.product_type_id = filters.product_type_id[0]
    }
    if (filters.payment_type.length) {
      query.payment_type = filters.payment_type[0]
    }
    dispatch(changeLoading(true))
    return axios
      .post(ERP_BACKEND_URL + `/report/events`, {
        query,
      })
      .then(resp => {
        if (wow) {
          dispatch(
            actions.getEventsReportWow({
              ...filters,
              data: resp.data,
            })
          )
        } else {
          dispatch(
            actions.getEventsReport({
              ...filters,
              data: resp.data,
            })
          )
          const wow_end = filters.start_date,
            wow_start = wow_end - (filters.end_date - filters.start_date)
          return dispatch(
            getEventsReport(true, {
              ...filters,
              start_date: wow_start,
              end_date: wow_end,
            })
          )
        }
        dispatch(changeLoading(false))
      })
      .catch(e => dispatch(dispatchError(e)))
  }

export const getPaymentsReport = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + `/log/payments`)
    .then(resp => {
      dispatch(actions.getPaymentsReport({ payments: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getHealthReport = () => (dispatch, getState) => {
  let filters = getState().reporting.filter
  if (!filters.start_date) {
    return dispatch(dispatchError('Missing start date'))
  }
  if (!filters.end_date) {
    return dispatch(dispatchError('Missing end date'))
  }
  let query = {
    start_date: filters.start_date,
    end_date: filters.end_date,
  }
  if (!filters.facility_id || filters.facility_id === 6) {
    return dispatch(dispatchError('Missing facility'))
  }
  query.facility_id = filters.facility_id
  dispatch(changeLoading(true))
  return axios
    .post(ERP_BACKEND_URL + `/report/health`, { query })
    .then(resp => {
      dispatch(actions.getHealthReport({ health_checks: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getBarData = () => (dispatch, getState) => {
  let filters = getState().reporting.filter
  if (!filters.start_date) {
    dispatch(dispatchError('Missing start date'))
  }
  if (!filters.end_date) {
    dispatch(dispatchError('Missing end date'))
  }
  const sdate_iso = filters.start_date.format('YYYY-MM-DDTHH:mm:ss') + '-05:00',
    edate_iso = filters.end_date.format('YYYY-MM-DDTHH:mm:ss') + '-05:00'
  return axios
    .post(ERP_BACKEND_URL + `/report/bar?start=${sdate_iso}&end=${edate_iso}`)
    .then(resp => {
      console.log(resp.data)
    })
}

export const getTeamsReport = () => (dispatch, getState) => {
  let filters = getState().reporting.filter
  let url = `/league?active=${filters.active}&hide_paid=1`
  if (filters.facility_id.length) {
    url += `&facility_ids=${filters.facility_id.join(',')}`
  }
  if (filters.product_type_id.length) {
    url += `&product_type_id=${filters.product_type_id[0]}`
  }
  if (filters.league_id.length) {
    url += `&league_ids=${filters.league_id.join(',')}`
  }
  if (filters.start_date) {
    url += `&start=${filters.start_date}`
  }
  if (filters.end_date) {
    url += `&end=${filters.end_date}`
  }
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_URL + url)
    .then(resp => {
      let team_report = [],
        totals = {
          invoiced: 0,
          paid: 0,
          discounted: 0,
          balance: 0,
        },
        leagues = {}
      resp.data.forEach(league => {
        leagues[league.ID] = league.name
        if (league.is_container) {
          league.divisions.forEach(division => {
            console.log(division)
            if (division.teams.length) {
              const invoiced = division.teams.reduce(
                  (a, b) => a + b.invoice?.invoiced,
                  0
                ),
                paid = division.teams.reduce((a, b) => a + b.invoice?.paid, 0),
                discounted = division.teams.reduce(
                  (a, b) => a + b.invoice?.discounted,
                  0
                ),
                balance = division.teams.reduce((a, b) => a + b.invoice?.balance, 0)
              team_report.push({
                season_id: league.ID,
                season: league.name,
                league_id: division.ID,
                league: division.name,
                facility: division.facility.name,
                end_date: division.end_date,
                end: unixToDateTime(division.end_date).format('MM/DD/YYYY'),
                team: '',
                team_id: 0,
                invoiced,
                paid,
                discounted,
                balance,
              })
              
              totals.invoiced += invoiced
              totals.paid += paid
              totals.discounted += discounted
              totals.balance += balance
              division.teams.forEach(team => {
                team_report.push({
                  season_id: league.ID,
                  season: league.name,
                  league_id: division.ID,
                  hide_league: true,
                  league: division.name,
                  facility: '',
                  end_date: division.end_date,
                  end: '',
                  team: team.team.name,
                  team_id: team.team.ID,
                  invoiced: team.invoice?.invoiced - team.invoice?.taxes,
                  paid: team.invoice?.paid,
                  discounted: team.invoice?.discounted,
                  balance: team.invoice?.balance,
                })
              })
            }
          })
        } else{
          if (league.teams.length) {
            leagues[league.ID] = league.name
            const invoiced = league.teams.reduce(
                (a, b) => a + b.invoice?.invoiced,
                0
              ),
              paid = league.teams.reduce((a, b) => a + b.invoice?.paid, 0),
              discounted = league.teams.reduce(
                (a, b) => a + b.invoice?.discounted,
                0
              ),
              balance = league.teams.reduce((a, b) => a + b.invoice?.balance, 0)
            team_report.push({
              season_id: league.ID,
              season: league.name,
              league_id: league.ID,
              league: league.name,
              facility: league.facility.name,
              end_date: league.end_date,
              end: unixToDateTime(league.end_date).format('MM/DD/YYYY'),
              team: '',
              team_id: 0,
              invoiced,
              paid,
              discounted,
              balance,
            })
            
            totals.invoiced += invoiced
            totals.paid += paid
            totals.discounted += discounted
            totals.balance += balance
            league.teams.forEach(team => {
              team_report.push({
                season_id: league.ID,
                season: league.name,
                league_id: league.ID,
                hide_league: true,
                league: league.name,
                facility: '',
                end_date: league.end_date,
                end: '',
                team: team.team.name,
                team_id: team.team.ID,
                invoiced: team.invoice?.invoiced - team.invoice?.taxes,
                paid: team.invoice?.paid,
                discounted: team.invoice?.discounted,
                balance: team.invoice?.balance,
              })
            })
          }
        }
      })
      team_report.sort((a, b) => {
        if (a.season_id > b.season_id) return 1
        if (a.season_id < b.season_id) return -1
        if (a.end_date > b.end_date) return 1
        if (a.end_date < b.end_date) return -1
        if (a.league > b.league) return 1
        if (a.league < b.league) return -1
        if (a.team > b.team) return 1
        if (a.team < b.team) return -1
        return 0
      })
      dispatch(
        actions.getTeamsReport({
          data: team_report,
          totals,
          leagues,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getTrials = () => (dispatch, getState) => {
  let filters = getState().reporting.filter
  let url = `/report/trials?`
  let query = {
    start_date: filters.start_date,
    end_date: filters.end_date + 3600 * 23 + 59 * 60 + 59,
  }
  if (filters.facility_id.length) {
    query.facility_ids = filters.facility_id
  }
  if (filters.product_type_id.length) {
    query.product_type_ids = filters.product_type_id
  }
  dispatch(changeLoading(true))
  return axios
    .post(ERP_BACKEND_URL + url, {
      query,
    })
    .then(resp => {
      let trials = []
      resp.data.forEach(trial => {
        trials.push({
          class: trial.class.name,
          facility: trial.class.facility.name,
          class_id: trial.class.ID,
          customer: `${trial.customer.first_name} ${trial.customer.last_name}`,
          enrolled_at: moment(trial.CreatedAt).format('MM-DD-YY'),
          trial_date: moment.unix(trial.event.start_date).format('MM-DD-YY'),
          event_id: trial.event.ID,
        })
      })
      dispatch(
        actions.getTrialsReport({
          data: trials,
        })
      )
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getReconciliations = () => (dispatch, getState) => {
  const filters = getState().reporting.filter
  if (!filters.start_date) {
    return dispatch(dispatchError('Missing start date'))
  }
  if (!filters.end_date) {
    return dispatch(dispatchError('Missing end date'))
  }
  dispatch(changeLoading(true))
  const start_date_unix = filters.start_date,
    end_date_unix = filters.end_date,
    mom_start = unixToDateTime(start_date_unix),
    mom_end = unixToDateTime(end_date_unix),
    start = start_date_unix,
    end = end_date_unix,
    start_square = mom_start.format('YYYY-MM-DDTHH:mm:ss') + '-05:00',
    end_square = mom_end.format('YYYY-MM-DDTHH:mm:ss') + '-05:00'
  return axios
    .get(
      ERP_BACKEND_URL +
        `/payment/reconciliation?start=${start}&end=${end}&start_square=${start_square}&end_square=${end_square}`
    )
    .then(resp => {
      const { invoices, square_payments } = resp.data
      let payment_data = [],
        total_map = {
          square_usd: 0,
          square_count: 0,
          hydra_usd: 0,
          hydra_count: 0,
        }
      let square_id_map = {}
      square_payments.forEach(p => {
        const amount = parseFloat(p.amount_money.amount / 100)
        total_map.square_usd += amount
        total_map.square_count += 1
        square_id_map[p.order_id] = true
      })
      let hydra_id_map = {}
      invoices.forEach(invoice => {
        invoice.payments.forEach(p => {
          if (p.type === 'card') {
            let details = {}
            try {
              details = JSON.parse(p.details)
            } catch (e) {
              console.log(p, p.details)
              console.error(e)
            }
            const mom = moment(p.CreatedAt).subtract(5, 'h'),
              unix_date = momentDateTimeToUnix(mom),
              square_id = details.square_id
                ? details.square_id
                : details.square_transaction_id
            let event_id = 0
            if (invoice.invoice_items[0].event_id) {
              event_id = invoice.invoice_items[0].event_id
            }
            if (invoice.class_id) {
              event_id = invoice.class_id
            }
            if (invoice.contract_id) {
              event_id = invoice.contract_id
            }
            if (invoice.team_id) {
              event_id = invoice.team_id
            }
            if (unix_date >= start_date_unix && unix_date <= end_date_unix) {
              hydra_id_map[square_id] = true
              if (!(square_id in square_id_map)) {
                payment_data.push({
                  unix: mom.unix(),
                  created_at: mom.format('MM/DD/YYYY HH:mm'),
                  amount: p.amount,
                  event_id,
                  event_type: invoice.product_type.name,
                  location: invoice.facility.name,
                  square_id,
                  source: 'hydra',
                  invoice_id: invoice.ID,
                })
              }
              total_map.hydra_usd += p.amount
              total_map.hydra_count += 1
            }
          }
        })
      })
      square_payments.forEach(p => {
        if (!(p.order_id in hydra_id_map)) {
          const amount = parseFloat(p.amount_money.amount / 100)
          const mom = moment(p.created_at)
          payment_data.push({
            unix: mom.unix(),
            created_at: mom.subtract(5, 'h').format('MM/DD/YYYY HH:mm'),
            amount,
            event_id: p.event_id,
            event_type: p.event_type,
            location: p.location,
            receipt: p.receipt_url,
            source: 'square',
            square_id: p.order_id,
          })
        }
      })
      let details = [
        {
          description: 'Square',
          quantity: total_map.square_count,
          amount: total_map.square_usd,
        },
        {
          description: 'Hydra',
          quantity: total_map.hydra_count,
          amount: total_map.hydra_usd,
        },
      ]
      dispatch(
        actions.getReconciliations({
          details,
          payment_data: payment_data.sort((a, b) => a.unix - b.unix),
        })
      )
      dispatch(changeLoading(false))
    })
}

export const getDailyReport = () => (dispatch, getState) => {
  let filters = getState().reporting.filter
  if (!filters.start_daily) {
    return dispatch(dispatchError('Missing start date'))
  }
  if (!filters.end_daily) {
    return dispatch(dispatchError('Missing end date'))
  }
  if (!filters.facility_id.length) {
    return dispatch(dispatchError('Missing facility'))
  }
  dispatch(changeLoading(true))
  return axios
    .get(
      ERP_BACKEND_NO_USER_URL +
        `/daily/report?facility_ids=${filters.facility_id.join(',')}&start=${
          filters.start_daily
        }&end=${filters.end_daily}`
    )
    .then(resp => {
      dispatch(actions.updateState({ key: 'report', value: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

export const getCamerasReport = () => (dispatch, getState) => {
  dispatch(changeLoading(true))
  return axios
    .get(ERP_BACKEND_NO_USER_URL + `/missing/videos`)
    .then(resp => {
      dispatch(actions.updateState({ key: 'cameras', value: resp.data }))
      dispatch(changeLoading(false))
    })
    .catch(e => dispatch(dispatchError(e)))
}

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Select from 'react-select'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'

import { getFees, updateClassDetails } from 'classes/actions'
import { actions } from 'classes/redux'
import { jsDateToUnix, unixToJsDate } from 'helper'
var classOptions = [{
  label:"Lil' Kickers Class",
  value:3,
},
{
  label:"Youth Class",
  value:2,
},
{
  label:"Youth Camp",
  value:1,
}]
const defaultPropertiesMap = [
  { key: 'Season Name', value: '' },
  { key: 'Division Name', value: '' },
  { key: 'Type', value: '' },
  { key: 'Category', value: '' },
  { key: 'Level', value: '' },
  { key: 'Gender', value: '' },
  { key: 'Age Category', value: '' },
  { key: 'Year', value: '' },
  { key: 'Season', value: '' },
  { key: 'Start Date', value: '' },
  { key: 'End Date', value: '' },
]

const ClassDetails = ({
  fees,
  getFees,
  updateForm,
  is_admin,
  products,
  form,
  facilities,
  edit,
  updateClass,
}) => {
  const handleAddPair = () => {
    updateForm('properties',[...form.properties, { key: '', value: '' }]);
  };

  const handleRemovePair = () => {
    if (form.properties.length == 1) {
      updateForm('properties',[]);
    } else {
      updateForm('properties',form.properties.slice(0, -1));
    }
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    let properties = JSON.parse(JSON.stringify(form.properties));
    properties[index][name] = value
    updateForm('properties', properties)
  };
  if (!edit && !form.properties.length){
    updateForm('properties', defaultPropertiesMap)
  }
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      style={{ textAlign: 'center', paddingTop: 20 }}
      spacing={4}
    >
      <Grid item xs={6}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ textAlign: 'center' }}
          spacing={4}
        >
          {is_admin && !edit && (
            <Grid item xs={12}>
              <Select
                options={facilities}
                placeholder="Select facility *"
                value={
                  form.facility_id &&
                  facilities.find(f => f.value === form.facility_id)
                }
                onChange={e => updateForm('facility_id', e.value)}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                isDisabled={!is_admin}
              />
            </Grid>
          )}
          {is_admin && !edit && (
          <Grid item xs={12} style={{ paddingTop: 0, textAlign: 'left' }}>
            <Select
                options={classOptions}
                placeholder="Select class type"
                value={
                  form.class_type &&
                  classOptions.find(
                    f => f.value == form.class_type
                  )
                }
                onChange={e =>{
                  updateForm('is_camp', e.value == 1)
                  updateForm('class_type', e.value)
                }
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
          </Grid>
          )}
          {is_admin && !edit && (
            <Grid item xs={12}>
              <Select
                options={products}
                placeholder="Select product *"
                value={
                  form.new_product_id &&
                  products.find(
                    f => f.value === form.new_product_id
                  )
                }
                onChange={e =>
                  updateForm('new_product_id', e.value)
                }
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            )}
          {!edit ? (
            <Grid item xs={12}>
              <TextField
                label="Name *"
                fullWidth
                variant="outlined"
                value={form.name}
                onChange={e => updateForm('name', e.target.value)}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextField
              label="Online description *"
              fullWidth
              multiline
              rows="3"
              variant="outlined"
              value={form.description}
              onChange={e => updateForm('description', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Max number of kids *"
              fullWidth
              variant="outlined"
              value={form.max_kids}
              onChange={e => updateForm('max_kids', e.target.value)}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            Start date
            <DatePickerComponent
              id="league_start_date"
              placeholder="Start date *"
              value={unixToJsDate(form.start_date)}
              onChange={e => updateForm('start_date', jsDateToUnix(e.value))}
            />
          </Grid>
          <Grid item xs={6}>
            End date
            <DatePickerComponent
              id="league_end_date"
              placeholder="End date *"
              value={unixToJsDate(form.end_date)}
              onChange={e => updateForm('end_date', jsDateToUnix(e.value))}
            />
          </Grid>
          <Grid item xs={6}>
            Registration start
            <DatePickerComponent
              id="reg_start_date"
              placeholder="Registration start date *"
              value={unixToJsDate(form.registration_start_date)}
              onChange={e =>
                updateForm('registration_start_date', jsDateToUnix(e.value))
              }
            />
          </Grid>
          <Grid item xs={6}>
            Registration end
            <DatePickerComponent
              id="reg_end_date"
              placeholder="Registration end date *"
              value={unixToJsDate(form.registration_end_date)}
              onChange={e =>
                updateForm('registration_end_date', jsDateToUnix(e.value))
              }
            />
          </Grid>
          <Grid item xs={4} style={{ paddingTop: 0, textAlign: 'left' }}>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.available_online}
                      onChange={e =>
                        updateForm('available_online', e.target.checked)
                      }
                      name="available_online"
                    />
                  }
                  label="Online registration"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4} style={{ paddingTop: 0, textAlign: 'left' }}>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.accepts_free_trial}
                      onChange={e =>{
                        updateForm('accepts_free_trial', e.target.checked)
                      }
                      }
                      name="accepts_free_trial"
                    />
                  }
                  label="Free trial"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4} style={{ paddingTop: 0, textAlign: 'left' }}>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.require_membership}
                      onChange={e =>{
                        updateForm('require_membership', e.target.checked)
                      }
                      }
                      name="require_membership"
                    />
                  }
                  label="Membership required"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ textAlign: 'center' }}
          spacing={4}
        >
          <Grid>
            <Grid>
              <strong style={{fontSize:"15px"}}> Properties</strong>
            </Grid>
          {form.properties?.map((pair, index) => (
          <Grid key={index}>
            
             <TextField
              type="text"
              name="key"
              placeholder="property"
              value={pair.key}
              style={{padding:"3px"}}
              onChange={(e) => handleChange(index, e)}
            />

            <TextField
              type="text"
              name="value"
              placeholder="Value"
              value={pair.value}
              style={{padding:"3px"}}
              onChange={(e) => handleChange(index, e)}
            />
            
          </Grid>
        ))}
        <Button size="small" style={{margin:"10px"}} variant="contained" color="secondary" onClick={handleAddPair}>
          Add Property
        </Button>
        <Button size="small" style={{margin:"10px"}} variant="contained" color="red" onClick={handleRemovePair}>
          Remove Property
        </Button>
          </Grid>
        </Grid>
      </Grid>
      {edit ? (
        <Grid item xs={12}>
          <Button className="save-btn" onClick={updateClass}>
            Save
          </Button>
        </Grid>
      ) : null}
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => ({
  fees: state.classes.fees.map(fee => ({
    label: `${fee.product.name} - ${fee.text.split('(')[0]} (${
      fee.product.individual_price
    })`,
    value: fee.id,
  })),
  form: state.classes.form,
  is_admin: state.user.is_admin,
  products:state.product.newProducts.filter(p => {
      if (!state.classes.form.class_type){
        return false
      }
      if (state.classes.form.class_type > 2){
        return p.gl.code.slice(0, 3) === '141'
      }
      return p.gl.code.slice(0, 3) === '140'
  }).map(f => ({
    label: f.name,
    value: f.ID,
  })),
  facilities: state.common.filter_facilities,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getFees: facility_id => dispatch(getFees(10, facility_id)),
  updateForm: (key, value) => dispatch(actions.updateForm({ key, value })),
  updateClass: () => dispatch(updateClassDetails()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClassDetails)

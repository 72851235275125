import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { broadcastMessage } from 'league/actions'
import { leagueSlice } from 'league/redux'
import { openConfirmModal } from 'common/actions'

const styles = () => ({
  paper: {
    height: '100%',
  },
})

const GreenSwitch = withStyles({
  switchBase: {
    color: '#03783a',
    '&$checked': {
      color: '#03783a',
    },
  },
  checked: {},
  track: {
    backgroundColor: '#8fbfa4',
  },
})(Switch)

class BroadcastMessage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editorState: EditorState.createEmpty(),
      sendToPlayers: true,
      subject: null,
    }
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
    })
  }

  sendMessage = () => {
    const html = stateToHTML(this.state.editorState.getCurrentContent())
    let recipients = []
    if (this.state.sendToPlayers) {
      recipients = this.props.team?.roster.map(p => p.customer.email)
    } else {
      recipients = [this.props.team.captain.email]
    }
    this.props.broadcastMessage(
      !this.state.sendToPlayers,
      this.state.subject,
      html,
      recipients,
      this.props.facility_id
    )
  }

  render() {
    const { open, handleClose, classes } = this.props
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle id="form-dialog-title">
          Broadcast message to team
          <IconButton
            style={{
              cursor: 'pointer',
              float: 'right',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={4}
          >
            <Grid item xs={12}>
              Send to players{' '}
              <FormControlLabel
                style={{ marginLeft: 20 }}
                control={
                  <GreenSwitch
                    checked={!this.state.sendToPlayers}
                    onChange={e =>
                      this.setState({ sendToPlayers: !e.target.checked })
                    }
                    name="who_to_send"
                  />
                }
              />{' '}
              Send to captain
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Subject *"
                fullWidth
                variant="outlined"
                value={this.state.subject}
                onChange={e => this.setState({ subject: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} style={{ height: '50vh' }}>
              <Editor
                editorState={this.state.editorState}
                onEditorStateChange={this.onEditorStateChange}
                wrapperStyle={{
                  height: '100%',
                }}
                editorStyle={{
                  border: '1px solid #F1F1F1',
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.sendMessage} className="save-btn">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  open: state.league.broadcast_message.open,
  team: state.teams.team,
  facility_id: state.league.league?.facility_id,
})

const mapDispatchToProps = dispatch => ({
  handleClose: () =>
    dispatch(
      leagueSlice.actions.updateBroadcastMessage({ key: 'open', value: false })
    ),
  broadcastMessage: (
    send_to_captain,
    subject,
    html,
    recipients,
    facility_id
  ) => {
    dispatch(
      openConfirmModal(
        'Broadcast',
        `send this message to ${
          send_to_captain ? 'the captain' : 'all players'
        }`,
        () => broadcastMessage(subject, html, recipients, facility_id)
      )
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BroadcastMessage))

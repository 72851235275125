import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import { toggleWaiver, updateCustomerNote, updateCustomer } from './../actions'
import { colors } from './../../consts'
import { actions } from './../redux'

import { unixToDate } from 'helper'
import moment from 'moment'

const styles = theme => ({
  root: {},
  details: {
    display: 'flex',
  },
  red_switch: {
    color: colors.blue_mild,
    '&$checked': {
      color: colors.blue_strong,
    },
    '&$checked + $track': {
      backgroundColor: colors.blue_strong,
    },
  },
})

function getSuspendedText(customer) {
  const now = moment().unix()
  if (customer.suspensions) {
  for (const suspension of customer.suspensions) {
    if (suspension.from <= now && now <= suspension.until) {
      return (
        <p>
          <b style={{ color: 'red' }}>Suspended until: </b>{' '}
          {moment.unix(suspension.until).format('MM/DD/YYYY')}{' '}
        </p>
      )
    }
  }
}

  return <b style={{ color: 'green' }}>No suspensions</b>
}

const AccountProfile = props => {
  const {
    classes,
    customer,
    invoice_last_date,
    total_invoiced,
    total_paid,
    balance,
    openAddCreditModal,
    openEditEmailModal,
    is_admin,
    updateCustomerNote,
    updateCustomer,
    openMergeModal,
    openProfilePhotoModal,
    openSuspensionModal,
  } = props

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={4}
    >
      <Grid item xs={12}>
        <Card className={classes.root} elevation={0}>
          <CardContent>
            <div className={classes.details}>
              <div>
                <Typography gutterBottom variant="h4">
                  {customer.first_name} {customer.last_name}
                </Typography>
                <Chip
                  size="small"
                  label={<b>{customer.facility.name}</b>}
                  style={{
                    color: colors.purple_strong,
                    backgroundColor: colors.purple_mild,
                    margin: 5,
                  }}
                />
                <Typography
                  className={classes.dateText}
                  color="textSecondary"
                  variant="body1"
                >
                  Last invoice date: {invoice_last_date}
                </Typography>
              </div>
            </div>
            <div className={classes.details} style={{ marginTop: 30 }}>
              <Typography variant="body1">
                <b>Email</b>: {customer.email}{' '}
                <Button
                  size="small"
                  style={{ textDecoration: 'underline' }}
                  onClick={() => openEditEmailModal(customer.email)}
                >
                  Edit
                </Button>
              </Typography>
            </div>
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                {getSuspendedText(customer)}{' '}
              </Typography>
            </div>
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                <b>Phone</b>: {customer.hubspot.phone}
              </Typography>
            </div>
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                <b>Waiver</b>{' '}
                <Switch
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  checked={
                    customer.hubspot.waiver_signed &&
                    customer.hubspot.waiver_signed === '1'
                  }
                  onChange={e => props.toggleWaiver(e.target.checked)}
                />
              </Typography>
            </div>
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                <b
                  style={{
                    color: customer.vaccinated
                      ? colors.green_strong
                      : colors.red_strong,
                  }}
                >
                  Proof of vaccination provided
                </b>{' '}
                <Switch
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  checked={customer.vaccinated}
                  onChange={e => updateCustomer('vaccinated', e.target.checked)}
                />
                {customer.vaccinated ? 'Yes' : 'No'}
              </Typography>
            </div>
            <div className={classes.details} style={{ marginTop: 20 }}>
              <Typography variant="body1">
                <b>Total invoiced</b>: ${total_invoiced}
              </Typography>
            </div>
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                <b>Paid</b>: ${total_paid}
              </Typography>
            </div>
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                <b>Balance</b>:{' '}
                <Chip
                  size="small"
                  label={<b>${balance}</b>}
                  style={{
                    color:
                      balance > 0 ? colors.red_strong : colors.green_strong,
                    backgroundColor:
                      balance > 0 ? colors.red_mild : colors.green_mild,
                    margin: 5,
                  }}
                />
              </Typography>
            </div>
            <div className={classes.details} style={{ marginTop: 10 }}>
              <Typography variant="body1">
                <b>Credit balance</b>: ${customer.credits}
                {is_admin && (
                  <Button
                    size="small"
                    style={{ textDecoration: 'underline' }}
                    onClick={() => openAddCreditModal()}
                  >
                    Add
                  </Button>
                )}
              </Typography>
            </div>
            <div className={classes.details} style={{ marginTop: 10 }}>
              <TextField
                id="customer-note"
                label="Note"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                onBlur={e => updateCustomerNote(e.target.value)}
                defaultValue={customer.note}
              />
            </div>
            <img src={customer.photo} style={{ marginTop: 10 }} />
            <p style={{ color: 'red' }}>Danger zone</p>
            <Button variant="outlined" onClick={openMergeModal}>
              Merge
            </Button>
            <Button
              variant="outlined"
              onClick={openProfilePhotoModal}
              style={{ marginLeft: 10 }}
            >
              Add profile photo
            </Button>
            <Button
              variant="outlined"
              onClick={openSuspensionModal}
              style={{ marginLeft: 10, color: 'red' }}
            >
              Suspend player
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state, ownProps) => {
  const invoices = [...state.customer.invoices].sort((a, b) => b.date - a.date)
  let invoice_last_date = null
  if (invoices.length) {
    invoice_last_date = unixToDate(invoices[0].date).format('MM/DD/YYYY')
  }
  return {
    customer: state.customer.customer,
    invoice_last_date,
    total_invoiced: state.customer.invoices.reduce((a, b) => a + b.invoiced, 0),
    total_paid: state.customer.invoices.reduce((a, b) => a + b.paid, 0),
    balance: state.customer.invoices.reduce((a, b) => a + b.balance, 0),
    is_admin: state.user.user_type === 'admin',
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleWaiver: on => dispatch(toggleWaiver(on)),
  openAddCreditModal: () =>
    dispatch(actions.toggleAddCreditModal({ open: true })),
  openEditEmailModal: email =>
    dispatch(actions.toggleEditEmailModal({ open: true, email })),
  updateCustomerNote: note => dispatch(updateCustomerNote(note)),
  updateCustomer: (key, value) => dispatch(updateCustomer(key, value)),
  openMergeModal: () =>
    dispatch(actions.updateMerge({ key: 'open', value: true })),
  openProfilePhotoModal: () =>
    dispatch(actions.updateProfilePhoto({ key: 'open', value: true })),
  openSuspensionModal: () =>
    dispatch(actions.updateSuspension({ key: 'open', value: true })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AccountProfile))

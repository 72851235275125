import React from 'react'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CalendarIcon from '@material-ui/icons/DateRange'
import PeopleIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings'

import { actions } from 'classes/redux'

const Details = ({ is_container, changeTab, tab, waitlist }) => {
  let tabs = []
  console.log(waitlist)
  if (is_container) {
    tabs.push({ label: 'Divisions', value: 'divisions' })
  } else {
    tabs.push(
      { label: 'Events', value: 'events', icon: <CalendarIcon /> },
      { label: 'Participants', value: 'participants', icon: <PeopleIcon /> },
      {
        label: `${
          waitlist.length > 0 ? `🟢 Waitlist ${waitlist.length}` : 'Waitlist'
        }`,
        value: 'waitlist',
        icon: <PeopleIcon />,
      }
    )
  }
  tabs.push({ label: 'Settings', value: 'settings', icon: <SettingsIcon /> })
  return (
    <Paper square style={{ marginBottom: '2em' }} elevation={0}>
      <Tabs
        value={tab}
        onChange={(a, b) => changeTab(b)}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        centered
      >
        {tabs.map(tab => (
          // eslint-disable-next-line react/jsx-key
          <Tab icon={tab.icon} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
    </Paper>
  )
}

const mapStateToProps = (state, ownProps) => ({
  tab: state.classes.tab,
  waitlist: state.classes.classInfo?.waitlists || [],
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeTab: tab => dispatch(actions.changeTab({ tab })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Details)

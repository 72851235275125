import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Filters from './../components/Filters'
import LeaguesTable from './../components/LeaguesTable'
import LeagueDialog from './../components/LeagueDialog'

import { getLeagues, getAgeGroups } from './../actions'
import { getNewProducts, getProducts } from './../../product/actions'
import { getFacilities } from './../../common/actions'

class Manage extends React.Component {
  componentDidMount() {
    this.props.getLeagues()
    this.props.getNewProducts()
    this.props.getProducts()
    this.props.getFacilities()
    this.props.getAgeGroups()
  }

  render() {
    return (
      <Paper elevation={0} style={{ padding: 10 }}>
        <LeagueDialog />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ paddingTop: '2%' }}
          spacing={4}
        >
          <Grid item xs={12}>
            <Filters />
          </Grid>
          <Grid item xs={12}>
            <LeaguesTable />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getLeagues: () => dispatch(getLeagues()),
  getNewProducts: () => dispatch(getNewProducts()),
  getProducts: () => dispatch(getProducts()),
  getFacilities: () => dispatch(getFacilities()),
  getAgeGroups: () => dispatch(getAgeGroups()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Manage)

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  products: [],
  newProducts: [],
  gls: [],
  query: '',
  filter: {
    query: '',
    facility_id: null,
    product_type_id: null,
  },
  new_product: {
    name: '',
    facility_id: null,
    gl_id: null,
    gl_code: null,
    deposit_price: null,
    early_bird_price: null,
    individual_price: null,
    full_price: null,
    apply_tax: true,
    excluded_facilities: [],
  },
  new_product_modal_open: false,
  product_modal_edit: false,
  tab: 'products',
  product_details: {},
  new_product_details: {
    open: false,
    name: null,
    facility_id: null,
    duration: null,
    gl_id: null,
    gl_code: null,
    base_price: null,
    peak_off_peak: false,
    peak_price: null,
    off_peak_price: null,
    early_bird_price: null,
    team_price: null,
    individual_price: null,
    deposit_price: null,
    tax_ids: [],
    fee_ids: [],
    field_type_ids: [],
    dialog_open: false,
  },
  fee_tax_modal: {
    type: 'tax',
    open: false,
    name: null,
    value: null,
  },
  fees: [],
  taxes: [],
}

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
      updateState: (state, { payload }) => {
        state[payload.key] = payload.value
      },
      getAllProducts: (state, { payload }) => {
        state.products = payload.products
      },
      getAllNewProducts: (state, { payload }) => {
        state.newProducts = payload.products
      },
      getAllGLs: (state, { payload }) => {
        state.gls = payload.gls
      },
      editSearchQuery: (state, { payload }) => {
        state.query = payload.query
      },
      changeProductDetails: (state, { payload }) => {
        state.new_product = {
          ...state.new_product,
          [payload.field]: payload.value,
        }
      },
      toggleModal: (state, { payload }) => {
        state.new_product_modal_open = payload.open
      },
      setProductDetails: (state, { payload }) => {
        state.new_product = payload.details
        state.product_modal_edit = false
      },
      resetProductDetails: (state, { payload }) => {
        state.new_product = initialState.new_product
        state.product_modal_edit = false
      },
      openProductEditModal: (state, { payload }) => {
        state.product_modal_edit = true
      },
      editProductDetails: (state, { payload }) => {
        state.product = state.product.concat(payload.product)
      },
      updateTab: (state, { payload }) => {
        state.tab = payload
      },
      changeFilterDetails: (state, { payload }) => {
        state.filter = {
          ...state.filter,
          [payload.key]: payload.value,
        }
      },
      updateProduct: (state, { payload }) => {
        state.new_product_details = {
          ...state.new_product_details,
          [payload.key]: payload.value,
        }
      },
      resetProduct: (state, { payload }) => {
        if (payload.product) {
          state.new_product_details = {
            ...initialState.new_product_details,
            ...payload.product,
          }
        } else {
          state.new_product_details = initialState.new_product_details
        }
      },
      setProduct: (state, { payload }) => {
        state.new_product_details = payload.product
      },
      updateFeeTax: (state, { payload }) => {
        state.fee_tax_modal = {
          ...state.fee_tax_modal,
          [payload.key]: payload.value,
        }
      },
    },
  }),
  actions = productSlice.actions

export { productSlice, actions }

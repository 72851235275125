import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'

import InvoiceDetails from './../components/InvoiceDetails'
import NewPaymentDialog from './../components/NewPaymentDialog'
import DiscountDialog from './../components/DiscountDialog'
import InvoiceLogs from './../components/InvoiceChangelog'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'

import {
  getInvoice,
  deleteInvoice,
  sendInvoiceToCustomer,
  updateInvoice,
} from './../actions'
import { openDeleteModal, openConfirmModal } from 'common/actions'
import { actions } from './../redux'
import { unixToDate, momentDateToUnix } from 'helper'
import { colors } from 'consts'

class EventDialog extends React.Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getInvoice(id)
  }

  render() {
    return (
      <div>
        <NewPaymentDialog />
        <DiscountDialog />
        {this.props.invoice && (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            style={{ textAlign: 'center', padding: 15 }}
            spacing={4}
          >
            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <Typography variant="h2" style={{ color: 'red' }}>
                {this.props.invoice.reversed ? '[REVERSED]' : ''}
                {this.props.invoice.reversal ? '[REVERSAL]' : ''}
              </Typography>
              <Typography variant="h5">
                Invoice #{this.props.invoice.ID}
              </Typography>
              <Link
                target="_blank"
                href={`https://webapp.sofive.com/invoice.html?invoice_id=${btoa(
                  '1:' + this.props.invoice.ID
                )}`}
              >
                Open customer invoice
              </Link>
              <br />
              <Link
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.sendInvoiceToCustomer()}
              >
                Email invoice to customer
              </Link>
            </Grid>
            {this.props.invoice.contract_id ? (
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography variant="h6">
                  Relative to{' '}
                  <Link
                    href={`/contract/${this.props.invoice.contract_id}`}
                    target="_blank"
                  >
                    Contract #{this.props.invoice.contract_id}
                  </Link>
                </Typography>
              </Grid>
            ) : null}
            {this.props.invoice.comment ? (
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography variant="body1">
                  <b>Comment:</b> {this.props.invoice.comment}
                </Typography>
              </Grid>
            ) : null}
            <Grid item xs={6} style={{ textAlign: 'left' }}>
              <b>Sofive INC.</b>
              <br />
              {this.props.invoice.facility.address
                .split(',')
                .map((el, index) => (
                  <span key={index}>
                    {el}
                    <br />
                  </span>
                ))}
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <b>
                {this.props.invoice.customer.first_name}{' '}
                {this.props.invoice.customer.last_name}
              </b>
              <br />
              {this.props.invoice.customer.email}
              <br />
              {this.props.invoice.facility.name}
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'left' }}>
              <b>Date:</b>
              <DatePickerComponent
                id="end_date"
                placeholder="Date"
                value={unixToDate(this.props.invoice.date).toDate()}
                step={15}
                onChange={e =>
                  this.props.updateInvoice(momentDateToUnix(moment(e.value)))
                }
                disabled={!this.props.is_admin}
              />
            </Grid>
            <Grid item xs={12}>
              <InvoiceDetails
                invoice={this.props.invoice}
                show_event
                is_contract={this.props.is_contract}
              />
            </Grid>
            {!this.props.invoice.reversed &&
              !this.props.invoice.reversal &&
              !this.props.has_payments &&
              this.props.is_admin && (
                <Grid item xs={4}>
                  <Button
                    onClick={() => {
                      this.props.deleteInvoice(this.props.invoice.ID)
                    }}
                    startIcon={<DeleteIcon />}
                    style={{
                      color: colors.red_strong,
                      backgroundColor: colors.red_mild,
                    }}
                  >
                    <b>Invoice</b>
                  </Button>
                </Grid>
              )}
            {!this.props.invoice.reversed && !this.props.invoice.reversal && (
              <Grid item xs={4}>
                <Button
                  className="save-btn"
                  onClick={() => this.props.openPaymentModal()}
                >
                  <b>Add payment</b>
                </Button>
              </Grid>
            )}
            {!this.props.invoice.reversed && !this.props.invoice.reversal && (
              <Grid item xs={4}>
                <Button
                  className="save-btn"
                  disabled={!this.props.is_admin}
                  onClick={() => this.props.openDiscountModal()}
                >
                  Add discount
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        <InvoiceLogs />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  invoice: state.invoice.invoice,
  is_contract: state.invoice.invoice && state.invoice.invoice.contract_id,
  is_admin: state.user.user_type === 'admin',
  has_payments:
    state.invoice.invoice &&
    state.invoice.invoice.payments.reduce((a, b) => a + b.amount, 0) !== 0,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getInvoice: id => dispatch(getInvoice(id)),
  openPaymentModal: () => dispatch(actions.togglePaymentModal({ open: true })),
  deleteInvoice: id =>
    dispatch(openDeleteModal('invoice', () => deleteInvoice(id))),
  openDiscountModal: () =>
    dispatch(actions.toggleDiscountModal({ open: true })),
  sendInvoiceToCustomer: () => {
    dispatch(
      openConfirmModal('Invoice', `send the customer their invoice`, () =>
        sendInvoiceToCustomer()
      )
    )
  },
  updateInvoice: d => dispatch(updateInvoice(d)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EventDialog)
